import React, { useRef, useEffect, useState } from 'react'
import { gql } from '@apollo/client'
import produce from 'immer'
import {
  Calendar,
  Trash2,
  Plus,
  CheckCircle,
  Circle,
  ArrowRight,
  X,
  Trash,
  Heart,
  Zap,
} from 'react-feather'
import Rating from './Rating'
import { formatSeconds } from '../Utilities'
import TextareaAutosize from 'react-textarea-autosize'
import DayPicker from 'react-day-picker'
import 'react-day-picker/lib/style.css'
import { parseISO, format } from 'date-fns'
import { TimeInput, NumberInput, Select } from '../Components/Form'
import { WorkoutRecordingSampleDetails } from './WorkoutRecordingDetails'
import { PrimaryButton, RedButton } from './Buttons'
import { HoverPopover } from './Popover'

const MONTHS = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December',
]
const WEEKDAYS_LONG = [
  'Söndag',
  'Måndag',
  'Tisdag',
  'Onsdag',
  'Torsdag',
  'Fredag',
  'Lördag',
]
const WEEKDAYS_SHORT = ['sö', 'må', 'ti', 'on', 'to', 'fr', 'lö']
const FIRST_DAY_OF_WEEK = 1

WorkoutForm.fragments = {
  workout: gql`
    fragment WorkoutForm_workout on Workout {
      id
      date
      planned
      shape
      comment
      rest_day
      analysis
    }
  `,
}

export function WorkoutForm({
  areaType,
  workout,
  hashtags,
  onChange,
  sports,
  intensities,
  biathlon_types,
  strength_types,
  explosivity_types,
  disabled,
  onComplete,
  onDelete,
  deleteWorkoutLoading,
  onUpdateCoachComment,
  updateCoachCommentLoading,
  updateCoachCommentData,
  loading,
}) {
  let commentRef = useRef()
  let focusHashtagRef = useRef()
  let [showDatePicker, setShowDatePicker] = useState(false)

  function change(updater) {
    onChange(produce(workout, updater))
  }

  // If the user added a new hashtag using the special
  // "new hashtag" button, we'll focus the text of
  // that hashtag to make it easily changeable.
  useEffect(() => {
    if (focusHashtagRef.current) {
      if (workout.comment) {
        commentRef.current.focus()
        commentRef.current.setSelectionRange(
          workout.comment.lastIndexOf(focusHashtagRef.current) + 1,
          workout.comment.lastIndexOf(focusHashtagRef.current) +
            focusHashtagRef.current.length,
        )
      }
      focusHashtagRef.current = null
    }
  }, [workout.comment])

  function addHashtagToComment(hashtag) {
    change(w => {
      w.comment = w.comment ? `${w.comment.trim()} ${hashtag}` : hashtag
    })
  }

  function addHashtag(hashtag, focus = false) {
    if (focus) {
      focusHashtagRef.current = hashtag
    }

    addHashtagToComment(hashtag)
  }

  return (
    <>
      {/* Workout recordings */}
      {workout.workout_recordings.length
        ? workout.workout_recordings.map(recording => {
            let detached = workout.detached_workout_recordings.includes(
              recording.id,
            )
            let toggle = (
              <button
                disabled={disabled}
                className="text-sm bg-gray-200 hover:bg-gray-300 active:bg-gray-400 rounded py-1.5 px-3 flex items-center"
                onClick={() =>
                  // Toggle the existence of the recording id in the
                  // detached workout recordings.
                  change(w => {
                    if (detached) {
                      w.detached_workout_recordings.splice(
                        w.detached_workout_recordings.indexOf(recording.id),
                        1,
                      )
                    } else {
                      w.detached_workout_recordings.push(recording.id)
                    }
                  })
                }
              >
                <X size={16} className="mr-1" />
                <span>{detached ? 'Ångra' : 'Koppla bort'}</span>
              </button>
            )

            let date = parseISO(recording.date)

            return (
              <div key={recording.id} className="mb-4">
                {detached ? (
                  <div className="flex justify-end">{toggle}</div>
                ) : (
                  <div>
                    <div className="flex mb-4">
                      <div className="flex-1 flex items-center leading-tight text-gray-800 rounded bg-gray-200 p-2 mr-4">
                        <div className="flex flex-1 items-center justify-start">
                          <Heart size={16} className="mr-2" />
                          <span>{recording.average_heart_rate ?? '-'}</span>
                        </div>
                        <div className="flex flex-1 items-center justify-end">
                          <Zap size={16} className="mr-2" />
                          <span>{recording.calories ?? '-'}</span>
                        </div>
                        {recording?.source === 'polar' && date ? (
                          <div className="ml-2 text-sm">
                            <a
                              target="_blank"
                              rel="noopener noreferrer"
                              href={`https://flow.polar.com/training/day/${format(
                                date,
                                'dd',
                              )}.${format(date, 'MM')}.${format(date, 'yyyy')}`}
                            >
                              Visa i Polar Flow
                            </a>
                          </div>
                        ) : null}
                      </div>
                      {toggle}
                    </div>
                    <WorkoutRecordingSampleDetails id={recording.id} />
                  </div>
                )}
              </div>
            )
          })
        : null}
      <div className="flex flex-col md:flex-row">
        {/* Activities */}
        <div className="flex-1 order-1 md:order-none md:mr-8 space-y-8 text-sm">
          <Stamina
            disabled={disabled}
            workout={workout}
            sports={sports}
            intensities={intensities}
            change={change}
          />
          <Biathlon
            disabled={disabled}
            workout={workout}
            biathlon_types={biathlon_types}
            change={change}
          />
          <Strength
            disabled={disabled}
            workout={workout}
            strength_types={strength_types}
            change={change}
          />
          <Explosivity
            disabled={disabled}
            workout={workout}
            explosivity_types={explosivity_types}
            change={change}
          />
        </div>

        {/* Date, comment and other stuff */}
        <div className="text-sm" style={{ flexBasis: 300 }}>
          {onComplete ? (
            <button
              className="mb-4 hidden md:flex items-center w-full text-white bg-green-600 rounded px-3 py-2 hover:bg-green-700 active:bg-green-800 disabled:bg-green-500"
              onClick={onComplete}
            >
              <span>Utför träningspass</span>
              <ArrowRight size={18} className="ml-auto" />
            </button>
          ) : null}
          <button
            disabled={disabled}
            onClick={() => setShowDatePicker(!showDatePicker)}
            className="mb-4 flex items-center w-full text-white bg-theme-600 rounded px-3 py-2 hover:bg-theme-700 active:bg-theme-800 disabled:bg-theme-500"
          >
            <Calendar size={18} className="mr-2" />
            <span>Datum</span>
            <span className="ml-auto">{workout.date}</span>
          </button>
          {showDatePicker ? (
            <div className="flex flex-col items-center">
              <DayPicker
                onDayClick={day =>
                  change(w => {
                    w.date = format(day, 'yyyy-MM-dd')
                  })
                }
                selectedDays={parseISO(workout.date)}
                initialMonth={parseISO(workout.date)}
                locale="sv"
                months={MONTHS}
                weekdaysLong={WEEKDAYS_LONG}
                weekdaysShort={WEEKDAYS_SHORT}
                firstDayOfWeek={FIRST_DAY_OF_WEEK}
                renderDay={day => (
                  <span className="block leading-none flex items-center justify-center w-5 h-5">
                    {format(day, 'd')}
                  </span>
                )}
              />
            </div>
          ) : null}
          <button
            disabled={disabled}
            onClick={() =>
              change(w => {
                w.rest_day = !workout.rest_day
              })
            }
            className="mb-4 flex items-center w-full text-gray-800 bg-gray-200 rounded px-3 py-2 hover:bg-gray-300 active:bg-gray-400"
          >
            <span>Vila</span>
            {workout.rest_day ? (
              <CheckCircle className="text-gray-700 ml-auto " size={18} />
            ) : (
              <Circle className="text-gray-700 ml-auto " size={18} />
            )}
          </button>
          {!workout.planned ? (
            <div className="flex justify-between mb-4">
              <span>Form</span>
              <Rating
                disabled={disabled}
                stars={5}
                value={workout.shape}
                onChange={value =>
                  change(w => {
                    w.shape = workout.shape === value ? null : value
                  })
                }
              />
            </div>
          ) : null}
          <TextareaAutosize
            disabled={disabled}
            className="form-textarea block w-full bg-gray-100 text-sm mb-2"
            placeholder="Kommentar"
            minRows={5}
            value={workout.comment ?? ''}
            inputRef={commentRef}
            onChange={e =>
              change(w => {
                w.comment = e.target.value
              })
            }
          />

          <div className="mb-2 flex items-center justify-end">
            <HoverPopover
              placement="bottom"
              modifiers={[
                {
                  name: 'preventOverflow',
                  options: { boundary: 'viewport' },
                },
                {
                  name: 'offset',
                  options: {
                    offset: [0, 5],
                  },
                },
              ]}
              trigger={
                <label className="flex items-center">
                  <input
                    type="checkbox"
                    className="form-checkbox border border-gray-500 disabled:opacity-75"
                    checked={workout.analysis}
                    disabled={disabled}
                    onChange={e => {
                      change(w => {
                        w.analysis = !workout.analysis
                      })
                    }}
                  />
                  <span className="ml-2 mr-2">Analys</span>
                </label>
              }
            >
              <p className="bg-black rounded shadow-popover py-1 px-2 text-white text-sm">
                Markera att kommentaren är en träningsanalys.
              </p>
            </HoverPopover>
          </div>

          {workout.writeable ? (
            <p className="text-sm text-gray-900">
              Lägg till hashtag:{' '}
              {hashtags.map(hashtag => (
                <Hashtag
                  key={hashtag}
                  hashtag={hashtag}
                  onClick={tag => addHashtag(tag)}
                />
              ))}
              <Hashtag
                hashtag="#läggtillny"
                onClick={tag => addHashtag(tag, true)}
              />
            </p>
          ) : null}
          {/* The coach comment is only visible for planned user workouts. Coaches can post comments,
          and athletes can read them. */}
          {areaType === 'user' && workout.planned === false ? (
            workout.coach_comment_is_writeable ? (
              <div className="mb-4">
                <label className="block mt-4" htmlFor="coach_comment">
                  Tränarkommentar
                </label>
                <TextareaAutosize
                  id="coach_comment"
                  className="form-textarea block w-full bg-gray-100 text-sm mt-2 mb-2"
                  placeholder="Tränarkommentar"
                  minRows={5}
                  value={workout.coach_comment ?? ''}
                  onChange={e =>
                    change(w => {
                      w.coach_comment = e.target.value
                    })
                  }
                />
                <div className="flex items-center">
                  <PrimaryButton
                    disabled={loading}
                    loading={updateCoachCommentLoading}
                    onClick={onUpdateCoachComment}
                  >
                    Uppdatera
                  </PrimaryButton>
                  {updateCoachCommentData ? (
                    <p className="ml-2 text-sm text-gray-700">Uppdaterad.</p>
                  ) : null}
                </div>
              </div>
            ) : workout.coach_comment ? (
              <div className="mb-4">
                <hr className="my-4" />
                <label
                  className="block text-xs font-medium"
                  htmlFor="coach_comment"
                >
                  Tränarkommentar
                </label>
                <p className="whitespace-pre-wrap">{workout.coach_comment}</p>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
      {onDelete ? (
        <RedButton
          disabled={disabled}
          loading={deleteWorkoutLoading}
          className="mt-6 mb-2 flex md:hidden items-center w-full"
          onClick={onDelete}
        >
          <Trash size={18} className="mr-2" />
          <span>Ta bort träningspass</span>
        </RedButton>
      ) : null}
    </>
  )
}

function Hashtag({ onClick, hashtag }) {
  return (
    <button
      onClick={() => onClick(hashtag)}
      className="text-blue-600 hover:text-blue-500 mr-1"
    >
      {hashtag}
    </button>
  )
}

function RemoveButton({ onClick, disabled }) {
  return (
    <button
      disabled={disabled}
      className="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 text-red-600 rounded px-3 py-2 flex items-center justify-center w-full md:w-auto"
      onClick={onClick}
    >
      <Trash2 size={18} />
    </button>
  )
}

function Stamina({ workout, sports, intensities, change, disabled }) {
  let duration = workout.stamina_activities.reduce(
    (prev, curr) => prev + (curr.duration ?? 0),
    0,
  )

  let distance = workout.stamina_activities.reduce(
    (prev, curr) => prev + (curr.distance ?? 0),
    0,
  )

  return (
    <div>
      <div className="mb-2 space-y-2">
        <div className="flex space-x-2 items-center">
          <div style={{ flex: 6 }}>
            <p className="pl-1 text-lg">Kondition</p>
          </div>
          <div style={{ flex: 5, height: 1 }} />
          <div style={{ flex: 4 }}>
            <p className="hidden md:block pl-1">Tid (h:m)</p>
          </div>
          <div style={{ flex: 2 }}>
            <p className="hidden md:block pl-1">Sträcka</p>
          </div>
          <div style={{ flex: 2 }}>
            <p
              style={{
                // Must... align... perfectly!
                paddingLeft: 3,
              }}
              className="hidden md:block w-8"
            >
              Höjdmeter
            </p>
          </div>
          {/* Invisible element just for alignment */}
          <div className="hidden md:block invisible">
            <RemoveButton />
          </div>
        </div>
        {workout.stamina_activities.map((activity, i) => (
          <div key={i}>
            <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
              <div style={{ flex: 6 }}>
                <Select
                  disabled={disabled}
                  label="Välj sport"
                  items={sports}
                  value={activity.sport?.id}
                  getLabel={x => x.name}
                  getValue={x => x.id}
                  onChange={id =>
                    change(w => {
                      w.stamina_activities[i].sport = sports.find(
                        x => x.id === id,
                      )
                    })
                  }
                />
              </div>
              <div style={{ flex: 5 }}>
                <Select
                  disabled={disabled}
                  label="Välj intensitet"
                  items={intensities}
                  value={activity.intensity?.id}
                  getLabel={x => x.name}
                  getValue={x => x.id}
                  onChange={id =>
                    change(w => {
                      w.stamina_activities[i].intensity = intensities.find(
                        x => x.id === id,
                      )
                    })
                  }
                />
              </div>
              <div style={{ flex: 4 }}>
                <p className="md:hidden pl-1 mb-1">Tid (h:m)</p>
                <TimeInput
                  disabled={disabled}
                  value={activity.duration}
                  onChange={value => {
                    change(w => {
                      w.stamina_activities[i].duration = value
                    })
                  }}
                />
                <MobileDurationWarning duration={activity.duration} />
              </div>
              <div style={{ flex: 2 }}>
                <p className="md:hidden pl-1 mb-1">Sträcka</p>
                <NumberInput
                  disabled={disabled}
                  placeholder="0 km"
                  value={activity.distance}
                  onChange={value => {
                    change(w => {
                      w.stamina_activities[i].distance = value
                    })
                  }}
                />
              </div>
              <div style={{ flex: 2 }}>
                <p className="md:hidden pl-1 mb-1">Höjdmeter</p>
                <NumberInput
                  disabled={disabled}
                  placeholder="0 m"
                  value={activity.ascent}
                  onChange={value => {
                    change(w => {
                      w.stamina_activities[i].ascent = value
                    })
                  }}
                />
              </div>
              <RemoveButton
                disabled={disabled}
                onClick={() => {
                  change(w => {
                    w.stamina_activities.splice(i, 1)
                  })
                }}
              />
            </div>
            <DesktopDurationWarning duration={activity.duration} />
          </div>
        ))}
      </div>

      <div className="flex items-center">
        <button
          disabled={disabled}
          className="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 rounded py-1.5 px-3 flex items-center"
          onClick={() => {
            change(w => {
              // We'll copy the sport and intensity from the last activity
              // if there is one.
              let lastActivity =
                workout.stamina_activities[
                  workout.stamina_activities.length - 1
                ]

              w.stamina_activities.push({
                sport: lastActivity?.sport,
                intensity: lastActivity?.intensity,
              })
            })
          }}
        >
          <Plus size={16} className="mr-1" />
          <span>Konditionsaktivitet</span>
        </button>
        <span className="ml-auto text-lg text-gray-800">
          {formatSeconds(duration)}
        </span>
        <div className="border-r-2 mx-3 py-3"></div>
        <span className="mr-2 text-lg text-gray-800">{distance} km</span>
      </div>
    </div>
  )
}

function Biathlon({ workout, biathlon_types, change, disabled }) {
  let duration = workout.biathlon_activities.reduce(
    (prev, curr) => prev + (curr.duration ?? 0),
    0,
  )

  // let shots = workout.biathlon_activities
  //   // .filter(x => !x.biathlon_type)
  //   .reduce((prev, curr) => {
  //     let shots = curr.prone_max_score + curr.standing_max_score

  //     if (curr.biathlon_type?.category === 'points') {
  //       shots /= 10
  //     }

  //     return prev + shots
  //   }, 0)

  return (
    <div>
      <div className="mb-2 space-y-2">
        <div className="flex space-x-2 items-center">
          <div style={{ flex: 3 }}>
            <p className="pl-1 text-lg">Skytte</p>
          </div>
          <div style={{ flex: 1 }}>
            <p className="hidden md:block pl-1">Ligg</p>
          </div>
          {!workout.planned ? (
            <div style={{ flex: 1 }}>
              <p className="hidden md:block pl-1">Ligg</p>
            </div>
          ) : null}
          <div style={{ flex: 1 }}>
            <p className="hidden md:block pl-1">Stå</p>
          </div>
          {!workout.planned ? (
            <div style={{ flex: 1 }}>
              <p className="hidden md:block pl-1">Stå</p>
            </div>
          ) : null}
          <div style={{ flex: 2 }}>
            <p className="hidden md:block pl-1">Tid (h:m)</p>
          </div>
          {/* Invisible element just for alignment */}
          <div className="hidden md:block invisible">
            <RemoveButton />
          </div>
        </div>
        {workout.biathlon_activities.map((activity, i) => (
          <div key={i}>
            <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
              <div style={{ flex: 3 }}>
                <Select
                  disabled={disabled}
                  label="Välj skyttetyp"
                  items={biathlon_types}
                  groupBy="category_name"
                  value={activity.biathlon_type?.id}
                  getLabel={x => x.name}
                  getValue={x => x.id}
                  onChange={id =>
                    change(w => {
                      w.biathlon_activities[i].biathlon_type =
                        biathlon_types.find(x => x.id === id)
                    })
                  }
                />
              </div>
              <div style={{ flex: 1 }}>
                <p className="md:hidden pl-1 mb-1">Ligg</p>
                <NumberInput
                  disabled={disabled}
                  placeholder="Skott"
                  value={
                    activity.biathlon_type?.category === 'points' &&
                    activity.prone_max_score
                      ? activity.prone_max_score / 10
                      : activity.prone_max_score
                  }
                  onChange={value => {
                    change(w => {
                      w.biathlon_activities[i].prone_max_score =
                        activity.biathlon_type?.category === 'points' && value
                          ? value * 10
                          : value
                    })
                  }}
                />
              </div>
              {!workout.planned ? (
                <div style={{ flex: 1 }}>
                  <p className="md:hidden pl-1 mb-1">Ligg</p>
                  <NumberInput
                    disabled={disabled}
                    placeholder={
                      activity.biathlon_type?.category === 'points'
                        ? 'Poäng'
                        : 'Träff'
                    }
                    value={activity.prone_score}
                    onChange={value => {
                      change(w => {
                        w.biathlon_activities[i].prone_score = value
                      })
                    }}
                  />
                </div>
              ) : null}
              <div style={{ flex: 1 }}>
                <p className="md:hidden pl-1 mb-1">Stå</p>
                <NumberInput
                  disabled={disabled}
                  placeholder="Skott"
                  value={
                    activity.biathlon_type?.category === 'points' &&
                    activity.standing_max_score
                      ? activity.standing_max_score / 10
                      : activity.standing_max_score
                  }
                  onChange={value => {
                    change(w => {
                      w.biathlon_activities[i].standing_max_score =
                        activity.biathlon_type?.category === 'points' && value
                          ? value * 10
                          : value
                    })
                  }}
                />
              </div>
              {!workout.planned ? (
                <div style={{ flex: 1 }}>
                  <p className="md:hidden pl-1 mb-1">Stå</p>
                  <NumberInput
                    disabled={disabled}
                    placeholder={
                      activity.biathlon_type?.category === 'points'
                        ? 'Poäng'
                        : 'Träff'
                    }
                    value={activity.standing_score}
                    onChange={value => {
                      change(w => {
                        w.biathlon_activities[i].standing_score = value
                      })
                    }}
                  />
                </div>
              ) : null}
              <div style={{ flex: 2 }}>
                <p className="md:hidden pl-1 mb-1">Tid (h:m)</p>
                <TimeInput
                  disabled={disabled}
                  value={activity.duration}
                  onChange={value => {
                    change(w => {
                      w.biathlon_activities[i].duration = value
                    })
                  }}
                />
                <MobileDurationWarning duration={activity.duration} />
              </div>
              <RemoveButton
                disabled={disabled}
                onClick={() => {
                  change(w => {
                    w.biathlon_activities.splice(i, 1)
                  })
                }}
              />
            </div>
            <DesktopDurationWarning duration={activity.duration} />
          </div>
        ))}
      </div>

      <div className="flex items-center">
        <button
          disabled={disabled}
          className="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 rounded py-1.5 px-3 flex items-center"
          onClick={() => {
            change(w => {
              w.biathlon_activities.push({})
            })
          }}
        >
          <Plus size={16} className="mr-1" />
          <span>Skytteaktivitet</span>
        </button>
        <span className="ml-auto text-lg text-gray-800">
          {formatSeconds(duration)}
        </span>
      </div>
    </div>
  )
}

function Strength({ workout, strength_types, change, disabled }) {
  let duration = workout.strength_activities.reduce(
    (prev, curr) => prev + (curr.duration ?? 0),
    0,
  )

  return (
    <div>
      <div className="mb-2 space-y-2">
        <div className="flex space-x-2 items-center">
          <div style={{ flex: 3 }}>
            <p className="pl-1 text-lg">Styrka</p>
          </div>
          <div style={{ flex: 4, height: 1 }} />
          <div style={{ flex: 2 }}>
            <p className="hidden md:block pl-1">Tid (h:m)</p>
          </div>
          {/* Invisible element just for alignment */}
          <div className="hidden md:block invisible">
            <RemoveButton />
          </div>
        </div>
        {workout.strength_activities.map((activity, i) => (
          <div key={i}>
            <div className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0">
              <div style={{ flex: 3 }}>
                <Select
                  disabled={disabled}
                  label="Välj styrketyp"
                  items={strength_types}
                  value={activity.strength_type?.id}
                  getLabel={x => x.name}
                  getValue={x => x.id}
                  onChange={id =>
                    change(w => {
                      w.strength_activities[i].strength_type =
                        strength_types.find(x => x.id === id)
                    })
                  }
                />
              </div>
              <div style={{ flex: 4 }} />
              <div style={{ flex: 2 }}>
                <p className="md:hidden pl-1 mb-1">Tid (h:m)</p>
                <TimeInput
                  disabled={disabled}
                  value={activity.duration}
                  onChange={value => {
                    change(w => {
                      w.strength_activities[i].duration = value
                    })
                  }}
                />
                <MobileDurationWarning duration={activity.duration} />
              </div>
              <RemoveButton
                disabled={disabled}
                onClick={() => {
                  change(w => {
                    w.strength_activities.splice(i, 1)
                  })
                }}
              />
            </div>
            <DesktopDurationWarning duration={activity.duration} />
          </div>
        ))}
      </div>

      <div className="flex items-center">
        <button
          disabled={disabled}
          className="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 rounded py-1.5 px-3 flex items-center"
          onClick={() => {
            change(w => {
              w.strength_activities.push({})
            })
          }}
        >
          <Plus size={16} className="mr-1" />
          <span>Styrkeaktivitet</span>
        </button>
        <span className="ml-auto text-lg text-gray-800">
          {formatSeconds(duration)}
        </span>
      </div>
    </div>
  )
}

function Explosivity({ workout, explosivity_types, change, disabled }) {
  return (
    <div>
      <div className="mb-2 space-y-2">
        <div className="flex space-x-2 items-center">
          <div style={{ flex: 3 }}>
            <p className="pl-1 text-lg">Explosivitet</p>
          </div>
          <div style={{ flex: 4, height: 1 }} />
          <div style={{ flex: 2 }}>
            <p className="hidden md:block pl-1">Antal</p>
          </div>
          {/* Invisible element just for alignment */}
          <div className="hidden md:block invisible">
            <RemoveButton />
          </div>
        </div>
        {workout.explosivity_activities.map((activity, i) => (
          <div
            className="flex flex-col md:flex-row md:space-x-2 space-y-2 md:space-y-0"
            key={i}
          >
            <div style={{ flex: 3 }}>
              <Select
                disabled={disabled}
                label="Välj typ"
                items={explosivity_types}
                value={activity.explosivity_type?.id}
                getLabel={x => x.name}
                getValue={x => x.id}
                onChange={id =>
                  change(w => {
                    w.explosivity_activities[i].explosivity_type =
                      explosivity_types.find(x => x.id === id)
                  })
                }
              />
            </div>
            <div style={{ flex: 4 }} />
            <div style={{ flex: 2 }}>
              <p className="md:hidden pl-1 mb-1">Antal</p>
              <NumberInput
                disabled={disabled}
                placeholder="0"
                value={activity.count}
                onChange={value => {
                  change(w => {
                    w.explosivity_activities[i].count = value
                  })
                }}
              />
            </div>
            <RemoveButton
              disabled={disabled}
              onClick={() => {
                change(w => {
                  w.explosivity_activities.splice(i, 1)
                })
              }}
            />
          </div>
        ))}
      </div>

      <div className="flex items-center">
        <button
          disabled={disabled}
          className="bg-gray-200 hover:bg-gray-300 active:bg-gray-400 rounded py-1.5 px-3 flex items-center"
          onClick={() => {
            change(w => {
              w.explosivity_activities.push({})
            })
          }}
        >
          <Plus size={16} className="mr-1" />
          <span>Explosivitet</span>
        </button>
      </div>
    </div>
  )
}

function MobileDurationWarning({ duration }) {
  return duration >= 3600 * 10 ? (
    <p className="md:hidden text-xs text-red-600 mt-1">
      Träningstiden är väldigt lång. Dubbelkolla så att du inte har bokfört
      timmar istället för minuter.
    </p>
  ) : null
}

function DesktopDurationWarning({ duration }) {
  return duration >= 3600 * 10 ? (
    <p className="hidden md:block text-xs text-red-600 mt-1">
      Träningstiden är väldigt lång. Dubbelkolla så att du inte har bokfört
      timmar istället för minuter.
    </p>
  ) : null
}

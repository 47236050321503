import { gql } from '@apollo/client'
import { Workout } from '../Workout'
import { Summary } from './Summary'
import { WorkoutPage } from '../../Pages/WorkoutPage'
import { DailyNotePage } from '../../Pages/DailyNotePage'
import { WorkoutRecording } from '../WorkoutRecording'
import { WorkoutRecordingPage } from '../../Pages/WorkoutRecordingPage'

export const PERIOD_WORKOUT = gql`
  fragment Period_workout on Workout {
    id
    date
    planned
    position
    writeable
    ...Workout_workout
    ...Summary_workouts

    # Prefetch the workout page for instant navigation
    ...WorkoutPage_workout
  }
  ${Workout.fragments.workout}
  ${Summary.fragments.workouts}
  ${WorkoutPage.fragments.workout}
`

export const PERIOD_WORKOUT_RECORDING = gql`
  fragment Period_workout_recording on WorkoutRecording {
    id
    date
    writeable
    ...WorkoutRecording_workout_recording
    ...WorkoutRecordingPage_workout_recording
    # ...Summary_workouts

    # Prefetch the workout page for instant navigation
    # ...WorkoutPage_workout
  }
  ${WorkoutRecording.fragments.workout_recording}
  ${WorkoutRecordingPage.fragments.workout_recording}
  # ${Summary.fragments.workouts}
  # ${WorkoutPage.fragments.workout}
`

export const PERIOD_WORKOUTS = gql`
  query Workouts(
    $id: ID!
    $user: Boolean!
    $program: Boolean!
    $start: String!
    $end: String!
  ) {
    user(id: $id) @include(if: $user) {
      id
      workouts(start: $start, end: $end) {
        ...Period_workout
      }
      workout_recordings(start: $start, end: $end) {
        ...Period_workout_recording
      }
      daily_notes(start: $start, end: $end) {
        ...DailyNotePage_daily_note
      }
    }
    program(id: $id) @include(if: $program) {
      id
      name
      workouts(start: $start, end: $end) {
        ...Period_workout
      }
    }
  }
  ${PERIOD_WORKOUT}
  ${PERIOD_WORKOUT_RECORDING}
  ${DailyNotePage.fragments.daily_note}
`

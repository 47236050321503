import {
  getISOWeek,
  getISOWeeksInYear,
  subYears,
  getISOWeekYear,
  setISOWeek,
  setISOWeekYear,
  startOfISOWeek,
  endOfISOWeek,
  addWeeks,
  subWeeks,
} from 'date-fns'

export default function multiWeekPeriod(numberOfWeeks, startWeek) {
  function period(date) {
    let weeks = getISOWeek(date) - startWeek
    if (weeks < 0) {
      weeks += getISOWeeksInYear(subYears(date, 1))
    }
    return Math.floor(weeks / numberOfWeeks + 1)
  }

  function season(date) {
    let season = getISOWeekYear(date)

    return getISOWeek(date) < startWeek ? season - 1 : season
  }

  function startOfPeriod(date) {
    return startOfISOWeek(
      setISOWeek(
        setISOWeekYear(date, season(date)),
        startWeek + (period(date) - 1) * numberOfWeeks,
      ),
    )
  }

  function endOfPeriod(date) {
    let periodStart = startOfPeriod(date)
    let periodStartWeek = getISOWeek(periodStart)
    let periodEndWeek = periodStartWeek + numberOfWeeks - 1

    // If the period starts in one season and ends in another - for example
    // period 14 in a 4-week period system - we shorten the period
    // to make it end when the season ends.
    if (periodStartWeek < startWeek && periodEndWeek >= startWeek) {
      periodEndWeek = startWeek - 1
    }

    return endOfISOWeek(setISOWeek(periodStart, periodEndWeek))
  }

  function startOfSeason(date) {
    return startOfISOWeek(
      setISOWeek(setISOWeekYear(date, season(date)), startWeek),
    )
  }

  function endOfSeason(date) {
    return endOfISOWeek(
      setISOWeek(setISOWeekYear(date, season(date) + 1), startWeek - 1),
    )
  }

  function addPeriods(date, count) {
    // Since there can be a 14th period with one week, we'll make sure to reset the
    // date to the first of the current period in order not to miss a period.
    date = startOfPeriod(date)
    return addWeeks(date, numberOfWeeks * count)
  }

  function subPeriods(date, count) {
    // Since there can be a 14th period with one week, we'll make sure to reset the
    // date to the last of the current period in order not to miss a period.
    date = endOfPeriod(date)
    return subWeeks(date, numberOfWeeks * count)
  }

  function addSeasons(date, count) {
    return setISOWeek(
      setISOWeekYear(date, season(date) + count),
      startWeek + (period(date) - 1) * numberOfWeeks,
    )
  }

  function subSeasons(date, count) {
    return addSeasons(date, -count)
  }

  return {
    period,
    season,
    startOfPeriod,
    endOfPeriod,
    startOfSeason,
    endOfSeason,
    addPeriods,
    subPeriods,
    addSeasons,
    subSeasons,
  }
}

export const biathlonPeriod = multiWeekPeriod(4, 17)

import React, { useLayoutEffect } from 'react'
import { X } from 'react-feather'
import { Area, AreaHeader } from '../Components/Area'
import { Link } from '@reach/router'

export function ConnectedToGarminPage({ navigate, location }) {
  useLayoutEffect(() => {
    window.scrollTo(window.scrollX, 0)
  }, [])

  return (
    <>
      <div className="md:hidden fixed bg-white top-0 left-0 right-0 z-20 px-4 h-12 flex items-center justify-center border-b">
        <div className="flex-1"></div>
        <h1 className="font-semibold">Ansluten till Garmin</h1>
        <div className="flex-1"></div>
      </div>
      <div className="mt-12 md:mt-4 md:mb-4 flex justify-center md:px-3 flex-col md:flex-row md:flex-wrap">
        <Area>
          <AreaHeader>
            <p>Ansluten till Garmin</p>
            <button className="ml-auto invisible hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full flex items-center">
              <X />
            </button>
          </AreaHeader>
          <div className="p-4">
            <div className="my-8 flex flex-col items-center justify-center">
              <p className="mb-4">
                Du har anslutits till Garmin! Dina pulsfiler blir tillgängliga i
                Maxpulse när du synkroniserar din klocka.
              </p>
              <Link className="text-theme-600" to="/settings">
                Tillbaka
              </Link>
            </div>
          </div>
        </Area>
      </div>
    </>
  )
}

import React from 'react'
import { Chart } from '../../Chart'
import { useTheme } from '../../../Hooks'
import { useStatisticsCategories } from '../../../Api/useStatisticsCategories'
import useSWR from 'swr'
import Spinner from '../../Spinner'
import { BiathlonTable } from './BiathlonTable'
import { fullSeason } from '../../../Periods/PeriodUtilities'
import { format } from 'date-fns'
import { stringify } from 'query-string'
import { PrimaryButton } from '../../Buttons'
import { downloadTable } from '../../../Utilities'

function useStatisticsPeriods(date, area, { start, end, split }) {
  let user_id = area.type === 'user' && area.id !== 'me' ? area.id : undefined
  let program_id = area.type === 'program' ? area.id : undefined

  let variables = stringify({
    user_id,
    program_id,
    split,
    season: format(date, 'yyyy-MM-dd'),
    start_type: start.type,
    start_value: start.value,
    end_type: end.type,
    end_value: end.value,
  })

  let { data: periods } = useSWR(`/statistics/biathlon?${variables}`)

  return { periods }
}

export function Season({
  field,
  start,
  end,
  showPlanned,
  date,
  area,
  split,
  areaTitle,
  periodTitle,
  mainMode,
}) {
  let title = fullSeason(date)
  let subTitle = split === 'weeks' ? 'vecka för vecka' : 'period för period'
  let rowTitle = split === 'weeks' ? 'Vecka' : 'Period'
  let splitLabel = split === 'weeks' ? 'vecka' : 'period'

  let { biathlon_types, intensities } = useStatisticsCategories()

  // TODO sports.id or intensities.id could be null, this means the activity
  // should be considered "unspecified".
  let { periods } = useStatisticsPeriods(date, area, { start, end, split })

  let ready = biathlon_types && intensities && periods

  const { color } = useTheme()

  let tableId = `table-${area.key}`

  function exportTable() {
    downloadTable(tableId, `${areaTitle} - Skytte - ${periodTitle}`)
  }

  if (!ready) {
    return (
      <div className="flex items-center justify-center my-12">
        <Spinner dark />
      </div>
    )
  }

  return (
    <>
      <div className="mb-8">
        <div className="mx-4 mb-4 border-b pb-2 flex items-center">
          <div>
            <h3 className="text-lg">Sammanfattning</h3>
            <p className="text-gray-800 text-sm">
              Säsongen {title}, {subTitle}.
            </p>
          </div>
          <div className="ml-auto">
            <PrimaryButton className="ml-2" onClick={exportTable}>
              Exportera
            </PrimaryButton>
          </div>
        </div>

        <div className="overflow-x-auto">
          {/* float-left and min-w-full fixes missing padding on the right side when overflowing. */}
          <div className="px-4 float-left min-w-full">
            <BiathlonTable
              field={field}
              tableId={tableId}
              title={rowTitle}
              rows={periods}
              biathlon_types={biathlon_types}
              intensities={intensities}
              // onRowClick={onShowSeason}
              showPlanned={showPlanned}
              mainMode={mainMode}
            />
          </div>
        </div>
      </div>

      <div className="mb-8">
        <div className="mx-4 mb-4 border-b pb-2">
          <h3 className="text-lg">Antal skott per {splitLabel}</h3>
          {/* <p className="text-gray-800 text-sm">
                Klicka på en stapel för att se mer detaljerad statistik.
              </p> */}
        </div>

        <Chart
          key={split} // Refresh when split changes.
          title={'Antal skott per ' + splitLabel}
          className="mx-4"
          type="bar"
          data={{
            labels: periods.map(period => period.name),
            datasets: [
              {
                label: 'Antal skott',
                backgroundColor: color('600'),
                data: periods.map(
                  period =>
                    period.biathlon[mainMode].prone_shots +
                    period.biathlon[mainMode].standing_shots,
                ),
              },
            ],
          }}
          options={{
            tooltips: {
              callbacks: {
                label: function (item) {
                  return item.yLabel
                },
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    callback: function (value, index, values) {
                      return value
                    },
                    stepSize: 3600 * 10,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </div>

      {/* Some breathing room. */}
      <div className="pb-12"></div>
    </>
  )
}

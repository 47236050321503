import React, { useLayoutEffect } from 'react'
import classNames from 'classnames'
import { Modes } from '../../Utilities'
import { isCurrentPeriod } from '../../Periods/PeriodUtilities'
import { biathlonPeriod } from '../../Periods/MultiWeekPeriod'
import { format } from 'date-fns'
const {
  period,
  endOfSeason,
  addPeriods,
  subPeriods,
  startOfPeriod,
  endOfPeriod,
} = biathlonPeriod

export function Season({ date, area, onNavigate }) {
  let periods = []

  // We'll subtract periods from the current date instead
  // of doing startOfSeason so that the date portion
  // of the date is preserved when navigating.
  let start = subPeriods(date, period(date) - 1)
  let end = endOfSeason(date)

  // Create a list of periods.
  for (let current = start; current <= end; current = addPeriods(current, 1)) {
    periods.push({ date: current })
  }

  // Use gray background on mobile.
  useLayoutEffect(() => {
    document.body.classList.add('bg-gray-100')

    return () => document.body.classList.remove('bg-gray-100')
  }, [])

  return (
    <div className="flex-1 bg-gray-100">
      <div
        className="grid gap-4 p-4"
        style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr)' }}
      >
        {periods.map(({ date }) => (
          <button
            className={classNames(
              'flex flex-row md:flex-col items-center rounded shadow bg-white p-4 md:p-8 hover:bg-gray-200 active:bg-gray-300',
              {
                'border border-theme-500': isCurrentPeriod(date),
              },
            )}
            onClick={() => onNavigate(date, Modes.Period)}
            key={date.valueOf()}
          >
            <span className="md:mb-2 text-xl">Period {period(date)}</span>
            <span className="ml-auto md:ml-0 text-sm text-gray-600">
              {format(startOfPeriod(date), 'd/M')} -{' '}
              {format(endOfPeriod(date), 'd/M')}
            </span>
          </button>
        ))}
      </div>
    </div>
  )
}

import 'react-app-polyfill/stable'

import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Redirect } from '@reach/router'
import * as serviceWorker from './serviceWorker'
import './assets/generated/index.css'
import App from './App'
import { CalendarPage } from './Pages/CalendarPage'
import { StatisticsPage } from './Pages/StatisticsPage'
import { SearchPage } from './Pages/SearchPage'
import { WorkoutPage } from './Pages/WorkoutPage'
import { EditWorkoutPage } from './Pages/EditWorkoutPage'
import { DailyNotePage } from './Pages/DailyNotePage'
import LandingPage from './Pages/LandingPage'
import AreaSwitcherPage from './Pages/AreaSwitcherPage'
import { ProgramPage } from './Pages/ProgramPage'

import { ApolloProvider } from '@apollo/client'
import { WorkoutRecordingPage } from './Pages/WorkoutRecordingPage'
import { AcceptInvitePage } from './Pages/AcceptInvitePage'
import { GroupsPage } from './Pages/GroupsPage'
import { GroupPage } from './Pages/GroupPage'
import { InviteUsersPage } from './Pages/InviteUsersPage'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import MorePage from './Pages/MorePage'
import { SettingsPage } from './Pages/SettingsPage'
import { ConnectToPolarPage } from './Pages/ConnectToPolarPage'
import { ConnectedToGarminPage } from './Pages/ConnectedToGarminPage'
import { SendPasswordResetPage } from './Pages/SendPasswordResetPage'
import { ResetPasswordPage } from './Pages/ResetPasswordPage'
import { ErrorPage } from './Pages/ErrorPage'
import { SWRConfig } from 'swr'
import { ErrorBoundary as DefaultErrorBoundary } from './Components/ErrorBoundary'
import { fetcher } from './Api/fetcher'
import { client } from './Api/apollo'
import HelpPage from './Pages/HelpPage'
// import { routes } from './routes'

let ErrorBoundary = DefaultErrorBoundary

// Bugsnag configuration.
if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact()],
  })
  ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
}

const app = (
  <React.StrictMode>
    <ErrorBoundary FallbackComponent={ErrorPage}>
      <SWRConfig value={{ fetcher }}>
        <ApolloProvider client={client}>
          <React.Suspense fallback={<p>Loading...</p>}>
            <Router primary={false} component={React.Fragment}>
              <Redirect from="/" to="/calendar" noThrow />
              <LandingPage path="/landing" />
              {/* The user can toggle between the AcceptInvitePage routes,
            so we'll give them identical keys to preserve their state. */}
              <AcceptInvitePage
                path="/invites/:id/:token/new"
                newUser={true}
                key="invite"
              />
              <AcceptInvitePage
                path="/invites/:id/:token/existing"
                newUser={false}
                key="invite"
              />
              <SendPasswordResetPage path="/password/reset" />
              <ResetPasswordPage path="/password/reset/:token" />
              <App path="/">
                <CalendarPage path="/calendar">
                  <HelpPage path="help" />
                  <AreaSwitcherPage path="area-switcher" />
                  <ProgramPage path="program/new" mode="new" />
                  <ProgramPage path="program/:id" mode="edit" />
                  <WorkoutPage path=":id" />
                  <EditWorkoutPage path="new" mode="new" />
                  <EditWorkoutPage path=":id/edit" mode="edit" />
                  <EditWorkoutPage path=":id/completed" mode="completed" />
                  <DailyNotePage path="note/new/:date" mode="new" />
                  <DailyNotePage path="note/:id" mode="edit" />
                  <WorkoutRecordingPage path="recording/:id" />
                </CalendarPage>
                <StatisticsPage path="/statistics">
                  <AreaSwitcherPage path="area-switcher" />
                  <ProgramPage path="program/new" mode="new" />
                  <ProgramPage path="program/:id" mode="edit" />
                </StatisticsPage>
                <SearchPage path="/search">
                  <WorkoutPage path=":id" />
                  <EditWorkoutPage path=":id/edit" mode="edit" />
                  <EditWorkoutPage path=":id/completed" mode="completed" />
                  <ProgramPage path="program/new" mode="new" />
                  <ProgramPage path="program/:id" mode="edit" />
                </SearchPage>
                <GroupsPage path="/groups" />
                <GroupPage path="/groups/:id">
                  <InviteUsersPage path="invite" />
                </GroupPage>
                <SettingsPage path="/settings" />
                <ConnectToPolarPage path="/connect/polar" />
                <ConnectedToGarminPage path="/connected/garmin" />
                <MorePage path="/more" />
                <HelpPage path="/more/help" fromMore />
              </App>
            </Router>
          </React.Suspense>
        </ApolloProvider>
      </SWRConfig>
    </ErrorBoundary>
  </React.StrictMode>
)

ReactDOM.render(app, document.getElementById('root'))
// ReactDOM.unstable_createRoot(document.getElementById('root')).render(app)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
// serviceWorker.register({
//   onUpdate: registration => {
//     const waitingServiceWorker = registration.waiting

//     if (waitingServiceWorker) {
//       waitingServiceWorker.addEventListener('statechange', event => {
//         // This would force reload after a new service worker is activated,
//         // but might interrupt the user. So right now it requires two refreshes
//         // to update the app.
//         // if (event.target.state === 'activated') {
//         //   window.location.reload()
//         // }
//       })
//       // Activate the service worker right away (after reload/relaunch). Could
//       // cause issues with lazy loaded code but we're not doint that right now.
//       waitingServiceWorker.postMessage({ type: 'SKIP_WAITING' })
//     }
//   },
// })

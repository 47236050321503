import { gql, useQuery } from '@apollo/client'
import useSWR from 'swr'

export function _useMe({ skip = false } = {}) {
  let { data } = useSWR(skip ? null : '/me')

  return {
    loading: !data,
    me: data?.me,
  }
}

const ME_QUERY = gql`
  query AppQuery {
    me {
      id
      email
      first_name
      last_name
    }
  }
`

export function useMe({ skip = false } = {}) {
  let { data } = useQuery(ME_QUERY, { skip })

  return {
    loading: !data,
    me: data?.me,
  }
}

// let { data } = useQuery(gql`
//   query TopNavQuery {
//     me {
//       id
//       first_name
//       last_name
//     }
//   }
// `)

import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { HeartRateChart } from './HeartRateChart'
import { HeartRateZoneSummary } from './HeartRateZoneSummary'

const WORKOUT_RECORDING_DETAILS = gql`
  query WorkoutRecording($id: ID!) {
    workout_recording(id: $id) {
      id
      date
      device
      writeable
      duration
      distance
      sport {
        id
        name
      }
      samples {
        heart_rate
        distance
      }
      ...HeartRateZoneSummary_recording
    }
  }
  ${HeartRateZoneSummary.fragments.recording}
`

export function WorkoutRecordingSampleDetails({ id }) {
  let { data: { workout_recording } = {} } = useQuery(
    WORKOUT_RECORDING_DETAILS,
    {
      variables: {
        id,
      },
    },
  )

  return (
    <div className="md:flex">
      {workout_recording ? (
        <>
          <div className="flex-1 md:mr-4 mb-4" style={{ flexBasis: 600 }}>
            <HeartRateChart height={220} recording={workout_recording} />
          </div>
          <div className="mb-4" style={{ flexBasis: 280 }}>
            <HeartRateZoneSummary recording={workout_recording} />
          </div>
        </>
      ) : (
        <div className="mb-4" style={{ height: 220 }} />
      )}
    </div>
  )
}

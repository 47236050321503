import { Plus, Edit, ArrowRight, User } from 'react-feather'
import React from 'react'
import { format, addDays, isToday, differenceInCalendarDays } from 'date-fns'
import { sv } from 'date-fns/locale'
import classNames from 'classnames'
import { DraggableWorkout } from '../Workout'
import { Summary } from './Summary'
import { biathlonPeriod } from '../../Periods/MultiWeekPeriod'

import { gql, useMutation } from '@apollo/client'
import { PERIOD_WORKOUT } from './PeriodQueries'
import { removeWorkoutRecordingFromCache } from '../../Utilities/GraphQLUtilities'
import { formatSeconds, isTouch } from '../../Utilities'
import { HoverPopover } from '../Popover'
import EqualHeight from '../EqualHeight'
// import { SelectArea } from '../Selectable'
import { WorkoutDropArea } from '../WorkoutDropArea'
import { DraggableWorkoutRecording } from '../WorkoutRecording'
import { DropArea } from '../DragDrop'
import { DraggableWeek, WeekDropArea, useCopyWeek } from '../WeekDragDrop'
import { fullSeason } from '../../Periods/PeriodUtilities'
import { MobileButton, SingleClick } from '../Buttons'
import { useNavigateQuery } from '../../Hooks'

const { startOfPeriod, endOfPeriod, period } = biathlonPeriod

export function Period({
  date,
  area,
  writeable,
  userPlannedWriteable,
  title,
  createMode,
  setCreateMode,
  showSummary,
  workouts,
  workout_recordings,
  daily_notes,
  intensities,
}) {
  let copyWeek = useCopyWeek()
  let navigateQuery = useNavigateQuery()
  let start = startOfPeriod(date)
  let end = endOfPeriod(date)

  // Here we key the workouts by date so that they can be easily
  // retrieved at the correct day.
  let workoutsByDate = workouts.reduce((prev, curr) => {
    prev[curr.date] = prev[curr.date] || []
    prev[curr.date].push(curr)
    return prev
  }, {})

  const getWorkoutsByDate = date =>
    workoutsByDate[format(date, 'yyyy-MM-dd')] || []

  let workoutRecordingsByDate = workout_recordings.reduce((prev, curr) => {
    prev[curr.date] = prev[curr.date] || []
    prev[curr.date].push(curr)
    return prev
  }, {})

  const getWorkoutRecordingsByDate = date =>
    workoutRecordingsByDate[format(date, 'yyyy-MM-dd')] || []

  let dailyNotesByDate = daily_notes.reduce((prev, curr) => {
    prev[curr.date] = curr
    return prev
  }, {})

  const getDailyNoteByDate = date =>
    dailyNotesByDate[format(date, 'yyyy-MM-dd')]

  // We'll generate weeks here, using a Map so that the
  // insertion order is preserved.
  let weekMap = new Map()

  // Create a list of weeks, filled with corresponding days and dates.
  for (let current = start; current <= end; current = addDays(current, 1)) {
    let week = format(current, 'I')
    if (!weekMap.has(week)) {
      weekMap.set(week, { date: current, days: [] })
    }
    weekMap.get(week).days.push(current)
  }

  // We'll get an array of our generated weeks here, so that we can
  // map over it to generate the calendar.
  let weeks = [...weekMap.values()]

  function toNewDailyNote(date) {
    navigateQuery(`/calendar/note/new/${format(date, 'yyyy-MM-dd')}`, true)
  }

  function toDailyNote(id) {
    navigateQuery(`/calendar/note/${id}`, true)
  }

  function toNewWorkout(date, planned) {
    navigateQuery('/calendar/new', {
      planned,
      on: format(date, 'yyyy-MM-dd'),
      // Neither user nor program means the current user.
      program: area.type === 'program' ? area.id : undefined,
      user: area.type === 'user' && area.id !== 'me' ? area.id : undefined,
    })
  }

  function toCompleteWorkout(id, date) {
    navigateQuery(`/calendar/${id}/completed`, {
      on: format(date, 'yyyy-MM-dd'),
    })
  }

  function toWorkout(id) {
    if (isTouch()) {
      navigateQuery(`/calendar/${id}`, true)
    } else {
      navigateQuery(`/calendar/${id}/edit`, true)
    }
  }

  function toRecording(id) {
    navigateQuery(`/calendar/recording/${id}`, true)
  }

  function renderDate(day) {
    return (
      <div className="flex items-center leading-none font-light my-2 md:my-0">
        <div className="tabular-nums text-xl md:text-2xl antialiased mx-2 text-center">
          {format(day, 'dd', { locale: sv })}
        </div>
        <div className="flex flex-col w-8">
          <span className="text-xs">{format(day, 'iii', { locale: sv })}</span>
          <span className="text-2xs md:text-xs mt-.5">
            {format(day, 'LLL', { locale: sv })}
          </span>
        </div>
      </div>
    )
  }

  function renderColumn(day, planned) {
    let note = !planned && getDailyNoteByDate(day)

    let workouts = getWorkoutsByDate(day)
      .filter(x => x.planned === planned)
      .sort((a, b) => a.position - b.position)

    let recordings = !planned && getWorkoutRecordingsByDate(day)

    // The column can be writeable if it's the planned column of a user that
    // the current user coaches.
    let columnIsWriteable = writeable || (planned && userPlannedWriteable)

    let column = (
      <div className="flex flex-wrap items-start">
        {workouts.map(workout => (
          <WorkoutRecordingDropArea workout={workout} key={workout.id}>
            <DraggableWorkout
              area={area}
              workout={workout}
              onClick={() => toWorkout(workout.id)}
            />
          </WorkoutRecordingDropArea>
        ))}
        {recordings
          ? recordings.map(recording => (
              <div
                key={recording.id}
                className="flex-grow md:flex-grow-0 m-1 min-w-0"
              >
                <DraggableWorkoutRecording
                  workout_recording={recording}
                  onClick={() => toRecording(recording.id)}
                />
              </div>
            ))
          : null}
        {area.type === 'user' && area.id === 'me' && !planned && !note ? (
          <div className="hidden md:flex my-1 ml-auto overflow-hidden md:invisible md:group-hover:visible rounded shadow-workout self-end">
            <button
              onClick={() => toNewWorkout(day, planned)}
              className="h-8 flex items-center py-1 px-2 text-gray-700 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 touching:opacity-50"
            >
              <Plus />
            </button>
            <button
              onClick={() => toNewDailyNote(day)}
              className="h-8 flex items-center py-1 px-2 text-gray-700 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 touching:opacity-50"
            >
              <Edit size={16} />
            </button>
          </div>
        ) : (
          <div className="ml-auto flex">
            {columnIsWriteable ? (
              <button
                onClick={() => toNewWorkout(day, planned)}
                className={classNames(
                  'hidden md:flex justify-center items-center md:invisible md:group-hover:visible h-8 py-1 px-2 my-1 text-gray-700 rounded bg-gray-100 hover:bg-gray-200 active:bg-gray-300 shadow-workout self-end touching:opacity-50',
                  { 'mr-1': note },
                )}
              >
                <Plus />
              </button>
            ) : null}
            {note ? (
              <div className="flex-grow md:flex-grow-0 my-1 ml-1">
                <HoverPopover
                  placement="bottom-end"
                  modifiers={[
                    {
                      name: 'flip',
                      options: {
                        fallbackPlacements: [
                          'bottom-end',
                          'top-end',
                          'right-end',
                          'left-end',
                        ],
                      },
                    },
                    {
                      name: 'preventOverflow',
                      options: { boundary: 'viewport' },
                    },
                    {
                      name: 'offset',
                      options: {
                        offset: [0, 10],
                      },
                    },
                  ]}
                  trigger={
                    <button
                      onClick={() => toDailyNote(note.id)}
                      className={classNames(
                        'w-full min-h-8 rounded leading-none flex items-center px-2 text-white text-sm shadow-workout focus:outline-none',
                        {
                          'bg-theme-500 touching:bg-theme-600': !note.ill,
                          'bg-red-500 touching:bg-red-600': note.ill,
                        },
                      )}
                    >
                      <Edit size={16} />
                    </button>
                  }
                >
                  <div
                    style={{ width: 250 }}
                    className="bg-white rounded shadow-popover"
                  >
                    <DailyNoteSummary daily_note={note} />
                  </div>
                </HoverPopover>
              </div>
            ) : null}
          </div>
        )}
        {createMode && columnIsWriteable ? (
          <div className="absolute top-0 left-0 w-full h-full flex">
            <div style={{ flex: 2 }} className="relative flex">
              <button
                onClick={() => {
                  setCreateMode(false)
                  toNewWorkout(day, planned)
                }}
                className="flex-1 m-.5 opacity-50 rounded bg-theme-300 active:bg-theme-400 border border-theme-500 border-dashed"
              />
              <div className="text-theme-800 absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
                <Plus size={16} />
              </div>
            </div>
            {area.type === 'user' && area.id === 'me' && !planned && !note ? (
              <div style={{ flex: 1 }} className="relative flex">
                <button
                  onClick={() => {
                    setCreateMode(false)
                    toNewDailyNote(day)
                  }}
                  className="flex-1 m-.5 opacity-50 rounded bg-theme-300 active:bg-theme-400 border border-theme-500 border-dashed"
                />
                <div className="text-theme-800 absolute top-0 left-0 w-full h-full flex items-center justify-center pointer-events-none">
                  <Edit size={16} />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    )

    return columnIsWriteable ? (
      <WorkoutDropArea
        date={day}
        planned={planned}
        area={area}
        workouts={workouts}
        onCompleteWorkout={toCompleteWorkout}
        className="group py-1 px-2 flex-1 min-w-0"
      >
        <EqualHeight name={`row-${differenceInCalendarDays(day, start)}`}>
          {column}
        </EqualHeight>
      </WorkoutDropArea>
    ) : (
      <div className="group py-1 px-2 flex-1 min-w-0">
        <EqualHeight name={`row-${differenceInCalendarDays(day, start)}`}>
          {column}
        </EqualHeight>
      </div>
    )
  }

  function renderWeekHeader(date, index, workouts) {
    return (
      <div className="flex items-end text-xl leading-none md:h-8 text-gray-800">
        <span className="my-1 md:my-0">
          Vecka {format(date, 'I')}
          <span className="ml-2 text-sm">{index + 1}</span>
        </span>

        <span className="flex-1 mx-2 text-right text-sm my-1 md:my-0">
          Planerat
        </span>
        {area.type === 'program' ? (
          <>
            <DraggableWeek
              start={date}
              programTitle={title}
              workouts={workouts}
            />
            <MobileButton
              onClick={() => {
                if (
                  window.confirm(
                    `Kopiera vecka ${format(
                      date,
                      'II',
                    )} från ${title} till "Min träning"?`,
                  )
                ) {
                  copyWeek(workouts, date, date, { type: 'user', id: 'me' })
                }
              }}
              title={'Kopiera vecka till "Min träning"'}
              className="flex md:hidden ml-2 p-1 relative"
              style={{ top: 1 }}
            >
              <ArrowRight size={18} />
              <User size={18} />
            </MobileButton>
            <SingleClick
              onClick={() => {
                copyWeek(workouts, date, date, { type: 'user', id: 'me' })
              }}
              render={({ props, clicked }) => (
                <button
                  title={'Kopiera vecka till "Min träning"'}
                  {...props}
                  className={classNames(
                    'hidden md:flex ml-2 p-1 rounded relative top-1.5',
                    {
                      'hover:bg-gray-200 active:bg-gray-300': !clicked,
                      'cursor-default text-gray-500': clicked,
                    },
                  )}
                >
                  <ArrowRight size={18} />
                  <User size={18} />
                </button>
              )}
            />
          </>
        ) : null}
        {area.type === 'user' ? (
          <span className="flex-1 mx-2 text-right text-sm my-1 md:my-0">
            Utfört
          </span>
        ) : null}
      </div>
    )
  }

  if (showSummary) {
    return (
      <div className="md:p-2">
        <h2 className="px-2 mb-2 mt-4 md:mt-2 text-lg">
          Summering period {period(date)} {fullSeason(date)}
        </h2>
        <EqualHeight name={'summary-' + period(date)}>
          <Summary
            intensities={intensities}
            workouts={workouts}
            showDone={area.type === 'user'}
          />
        </EqualHeight>
      </div>
    )
  }

  return (
    // <SelectArea>
    <div className="md:px-4 _pt-4">
      {weeks.map((week, weekIndex) => {
        let weekWorkouts = week.days.flatMap(day => getWorkoutsByDate(day))

        return (
          <div key={week.date.valueOf()}>
            <div className="md:hidden sticky z-10 top-nav-height bg-white-blur px-4 py-2 border-b">
              {renderWeekHeader(week.date, weekIndex, weekWorkouts)}
            </div>
            <div className="flex flex-wrap md:py-4 -ml-8">
              {/* In order for the text in a workout to wrap, all flex children from here on
              down to the Workout component needs the class min-w-0 so that they don't expand
              outside the screen when a workout has too much text, allowing the text to wrap instead. */}
              <div style={{ flexBasis: 800 }} className="flex-1 ml-8 min-w-0">
                <div className="hidden md:block top-nav-height pb-2 border-b">
                  {renderWeekHeader(week.date, weekIndex, weekWorkouts)}
                </div>
                <WeekDropArea
                  start={week.date}
                  area={area}
                  writeable={writeable}
                  userPlannedWriteable={userPlannedWriteable}
                >
                  {week.days.map(day => (
                    <div
                      key={day.valueOf()}
                      className={classNames(
                        'flex border-b overflow-hidden',
                        isToday(day) ? 'bg-theme-100' : 'even:bg-gray-100',
                      )}
                      // The min height is equal to a workout or add button of 40 px
                      // plus one pixel border.
                      style={{ minHeight: 49 }}
                    >
                      <div className="flex-shrink-0 flex items-center justify-center md:border-none border-dashed">
                        {renderDate(day)}
                      </div>
                      <div className="md:block border-r md:border-r-2 my-2 border-dotted"></div>
                      <div className="flex-1 flex min-w-0">
                        {renderColumn(day, true)}
                        {area.type === 'user' ? (
                          <>
                            <div className="md:block border-r md:border-r-2 my-2 border-dotted"></div>
                            {renderColumn(day, false)}
                          </>
                        ) : null}
                      </div>
                    </div>
                  ))}
                </WeekDropArea>
              </div>
              <div style={{ flexBasis: 220 }} className="flex-1 ml-8 mt-1 mb-4">
                <EqualHeight name={format(week.date, 'II')}>
                  <Summary
                    intensities={intensities}
                    workouts={weekWorkouts}
                    showDone={area.type === 'user'}
                  />
                </EqualHeight>
              </div>
            </div>
          </div>
        )
      })}
    </div>
    // </SelectArea>
  )
}

const ATTACH_WORKOUT_RECORDING = gql`
  mutation AttachWorkoutRecording(
    $workout_id: ID!
    $workout_recording_id: ID!
  ) {
    attachWorkoutRecording(
      workout_id: $workout_id
      workout_recording_id: $workout_recording_id
    ) {
      workout {
        ...Period_workout
      }
    }
  }
  ${PERIOD_WORKOUT}
`

function WorkoutRecordingDropArea({ children, workout }) {
  let [attachWorkoutRecording] = useMutation(ATTACH_WORKOUT_RECORDING)

  function handleDrop({ type, workout_recording }, { x, y }) {
    // Return if we can't write to the workout or recording.
    if (!workout.writeable || !workout_recording.writeable) return

    attachWorkoutRecording({
      variables: {
        workout_id: workout.id,
        workout_recording_id: workout_recording.id,
      },
      optimisticResponse: {
        attachWorkoutRecording: {
          workout: {
            // TODO Is this really optimistic?
            workout_recordings:
              workout.workout_recordings.concat(workout_recording),
            __typename: 'Workout',
          },
          __typename: 'AttachWorkoutRecordingOutput',
        },
      },
      update(cache, { data: { attachWorkoutRecording } }) {
        removeWorkoutRecordingFromCache(cache, workout_recording)
      },
    })
  }

  return (
    <DropArea
      accept={['workout_recording']}
      onDrop={handleDrop}
      render={({ ref, isOver, data }) => {
        return (
          <div
            ref={ref}
            className="relative flex-grow md:flex-grow-0 m-1 min-w-0"
          >
            {children}
            {isOver ? (
              <div className="absolute inset-0 bg-gray-300 opacity-50 -m-1.5 border-2 border-dashed border-gray-500" />
            ) : null}
          </div>
        )
      }}
    />
  )
}

function DailyNoteSummary({ daily_note }) {
  return (
    <div className="text-sm py-3 px-1">
      <p className="text-lg font-semibold mb-2 mx-2">{daily_note.date}</p>
      <div className="grid grid-cols-2 gap-2">
        {daily_note.ill ? (
          <p className="py-1 px-2 text-base">
            <span className="border-b-4 border-red-500">Sjuk</span>
          </p>
        ) : null}
        {daily_note.travel ? (
          <p className="py-1 px-2 text-base">
            <span className="border-b-4 border-theme-500">Resdag</span>
          </p>
        ) : null}
        {daily_note.injured ? (
          <p className="py-1 px-2 text-base">
            <span className="border-b-4 border-theme-500">Skadad</span>
          </p>
        ) : null}
        {daily_note.alternative_training ? (
          <p
            style={{ gridColumnEnd: 'span 2' }}
            className="py-1 px-2 text-base"
          >
            <span className="border-b-4 border-theme-500">
              Alternativ träning
            </span>
          </p>
        ) : null}
        {daily_note.menstruation ? (
          <p className="py-1 px-2 text-base">
            <span className="border-b-4 border-theme-500">Menstruation</span>
          </p>
        ) : null}
        {daily_note.weight ? (
          <div className="px-2 py-1 leading-none">
            <p className="text-gray-700 mb-1">Vikt</p>
            <p className="text-lg">{daily_note.weight} kg</p>
          </div>
        ) : null}
        {daily_note.sleep ? (
          <div className="px-2 py-1 leading-none">
            <p className="text-gray-700 mb-1">Sömn</p>
            <p className="text-lg">{formatSeconds(daily_note.sleep)}</p>
          </div>
        ) : null}
        {daily_note.heart_rate ? (
          <div className="px-2 py-1 leading-none">
            <p className="text-gray-700 mb-1">Vilopuls</p>
            <p className="text-lg">{daily_note.heart_rate} slag/min</p>
          </div>
        ) : null}
      </div>
    </div>
  )
}

import React from 'react'
import { isInStandaloneMode } from '../Utilities'
import { useIsFirstRenderWithDelay } from '../Hooks/useIsFirstRender'

export function FixIOSStartupGlitch() {
  // iOS glitches when the PWA loads, the bottom safe area is not
  // immediately applied so we'll render a blank screen for a short while
  // to prevent the glitch. A few milliseconds seems to be enough.
  // This should look like the splash screen if we ever make one.
  let isFirstRender = useIsFirstRenderWithDelay(10)

  return isFirstRender && isInStandaloneMode() ? (
    <div className="fixed z-50 inset-0 bg-white" />
  ) : null
}

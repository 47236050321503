import React, { useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import { format } from 'date-fns'
import classNames from 'classnames'
import { gql, useMutation } from '@apollo/client'
import { PERIOD_WORKOUT } from './Calendar/PeriodQueries'
import {
  removeWorkoutFromCache,
  addWorkoutToCache,
} from '../Utilities/GraphQLUtilities'
import { groupBy, optimisticId } from '../Utilities'
import { useMainStore } from '../Stores/MainStore'
import { DropArea } from './DragDrop'

const MOVE_WORKOUT = gql`
  mutation MoveWorkout($id: ID!, $input: MoveWorkoutInput!) {
    moveWorkout(id: $id, input: $input) {
      workout {
        id
        date
        position
        planned
      }
      repositionedWorkouts {
        id
        position
      }
    }
  }
`

const CREATE_WORKOUT = gql`
  mutation CreateWorkout($input: CreateWorkoutInput!) {
    createWorkout(input: $input) {
      workout {
        id
        date
        position
        planned
        ...Period_workout
      }
      repositionedWorkouts {
        id
        position
      }
    }
  }
  ${PERIOD_WORKOUT}
`

export function WorkoutDropArea({
  children,
  Element = 'div',
  className = '',
  date,
  planned,
  area,
  workouts,
  onCompleteWorkout,
}) {
  const container = React.useRef()
  let [moveWorkout] = useMutation(MOVE_WORKOUT)
  let [createWorkout] = useMutation(CREATE_WORKOUT)
  let modifierKeyPressed = useMainStore(state => state.modifierKeyPressed)

  // TODO Handle copying between two of the same areas.
  function handleDrop({ type, workout, area: sourceArea }, { x, y }) {
    let copy =
      area.type !== sourceArea.type ||
      area.id !== sourceArea.id ||
      area.date !== sourceArea.date

    if (modifierKeyPressed) {
      copy =
        (area.type === sourceArea.type &&
          area.id === sourceArea.id &&
          area.date === sourceArea.date) ||
        !workout.writeable
    }

    // We'll always copy the workout if it's not writeable.
    if (!workout.writeable) {
      copy = true
    }

    // Now let's calculate the workout's new position -
    // where it should be inserted among the other workouts.
    let { rows, column, leftOfColumn } = findIntersectingWorkout(
      container.current,
      x,
      y,
    )

    let POSITION_DIFFERENCE = Math.pow(2, 16)
    let position = 0

    if (column) {
      // We should insert the workout before the workout
      // at this index.
      let index = column.index
      if (!leftOfColumn) {
        index++
      }

      if (index === 0) {
        // Insert the workout before the first one.
        position = workouts[0].position - POSITION_DIFFERENCE
      } else if (index === workouts.length) {
        // Insert the workout after the last one.
        position = workouts[workouts.length - 1].position + POSITION_DIFFERENCE
      } else {
        // Insert the workout between the index workout and the one before.
        position = (workouts[index - 1].position + workouts[index].position) / 2
      }
    } else if (rows.length) {
      // If we're not hovering on a row, we'll place the workout last.
      position = workouts[workouts.length - 1].position + POSITION_DIFFERENCE
    }

    // The api doesn't like floats for position.
    position = Math.round(position)

    // If nothing has changed - meaning the workout is ending
    // up at the same place and date as before, we'll abort
    // the operation (unless copying).
    let oldIndex = workouts.findIndex(x => x.id === workout.id)

    if (oldIndex !== -1 && !copy) {
      let newWorkouts = workouts.slice()

      newWorkouts[oldIndex] = {
        ...newWorkouts[oldIndex],
        position,
      }
      newWorkouts.sort((a, b) => a.position - b.position)

      let newIndex = newWorkouts.findIndex(x => x.id === workout.id)

      if (oldIndex === newIndex) {
        return
      }
    }

    if (!planned && workout.planned) {
      // If we're dragging from planned to done,
      // we'll show the "complete workout" form.
      // TODO Include the position here.
      onCompleteWorkout(workout.id, date)
    } else if (copy) {
      createWorkout({
        variables: {
          input: {
            date: format(date, 'yyyy-MM-dd'),
            planned,
            position,
            comment: workout.comment,
            shape: workout.shape,
            rest_day: workout.rest_day,
            analysis: workout.analysis,
            program_id: area.type === 'program' ? area.id : undefined,
            user_id:
              area.type === 'user' && area.id !== 'me' ? area.id : undefined,

            stamina_activities: workout.stamina_activities.map(x => ({
              distance: x.distance,
              duration: x.duration,
              sport_id: x.sport?.id,
              intensity_id: x.intensity?.id,
            })),
            biathlon_activities: workout.biathlon_activities.map(x => ({
              duration: x.duration,
              prone_score: x.prone_score,
              prone_max_score: x.prone_max_score,
              standing_score: x.standing_score,
              standing_max_score: x.standing_max_score,
              biathlon_type_id: x.biathlon_type?.id,
            })),
            strength_activities: workout.strength_activities.map(x => ({
              duration: x.duration,
              strength_type_id: x.strength_type?.id,
            })),
            explosivity_activities: workout.explosivity_activities.map(x => ({
              count: x.count,
              explosivity_type_id: x.explosivity_type?.id,
            })),
          },
        },
        optimisticResponse: {
          createWorkout: {
            workout: {
              id: optimisticId(),
              date: format(date, 'yyyy-MM-dd'),
              position,
              shape: workout.shape,
              planned,
              comment: workout.comment,
              coach_comment: null,
              rest_day: workout.rest_day,
              analysis: workout.analysis,
              program: area.type === 'program' ? { id: area.id } : null,
              user: area.type === 'user' ? { idOrMe: area.id } : null,
              writeable: false,
              coach_comment_is_writeable: false,
              stamina_activities: workout.stamina_activities,
              biathlon_activities: workout.biathlon_activities,
              strength_activities: workout.strength_activities,
              explosivity_activities: workout.explosivity_activities,
              workout_recordings: [],

              __typename: 'Workout',
            },
            repositionedWorkouts: null,
            __typename: 'CreateWorkoutOutput',
          },
        },
        update(cache, { data: { createWorkout } }) {
          addWorkoutToCache(cache, createWorkout.workout, area)
        },
      })
    } else {
      moveWorkout({
        variables: {
          id: workout.id,
          input: {
            date: format(date, 'yyyy-MM-dd'),
            planned,
            position,
            program_id: area.type === 'program' ? area.id : undefined,
            user_id:
              area.type === 'user' && area.id !== 'me' ? area.id : undefined,
          },
        },
        optimisticResponse: {
          moveWorkout: {
            workout: {
              id: workout.id,
              date: format(date, 'yyyy-MM-dd'),
              position,
              planned,
              __typename: 'Workout',
            },
            repositionedWorkouts: [],
            __typename: 'MoveWorkoutOutput',
          },
        },
        update(cache, { data: { moveWorkout } }) {
          if (
            area.type !== sourceArea.type ||
            area.id !== sourceArea.id ||
            area.date !== sourceArea.date
          ) {
            removeWorkoutFromCache(cache, workout, sourceArea)
            addWorkoutToCache(cache, moveWorkout.workout, area)
          }
        },
      })
    }
  }

  return (
    <DropArea
      accept={['workout']}
      onDrop={handleDrop}
      render={({ ref, isOver, data, x, y }) => {
        // We'll calculate whether we are performing a copy or move
        // (if anything), in order to display a hint in the
        // drop area.
        let copy = false
        let completing = false
        if (data) {
          let { workout, area: sourceArea } = data
          completing = !planned && workout.planned

          copy =
            area.type !== sourceArea.type ||
            area.id !== sourceArea.id ||
            area.date !== sourceArea.date

          if (modifierKeyPressed) {
            copy =
              (area.type === sourceArea.type &&
                area.id === sourceArea.id &&
                area.date === sourceArea.date) ||
              !workout.writeable
          }

          // We'll always copy the workout if it's not writeable.
          if (!workout.writeable) {
            copy = true
          }
        }

        let placeholder = null
        const PLACEHOLDER_WIDTH = 4
        const PLACEHOLDER_HEIGHT = 60
        let scrollY = window.scrollY

        // Calculate index for placeholder position based on coordinates
        // of the pointer and of the child workouts.
        if (container.current && isOver) {
          let containerRect = container.current.getBoundingClientRect()
          let { rows, row, column, leftOfColumn } = findIntersectingWorkout(
            container.current,
            x,
            y,
          )

          if (row && column) {
            let index = row.columns.indexOf(column)

            let left = null

            if (leftOfColumn) {
              // We're on the left side of a workout.
              if (index > 0) {
                left =
                  (row.columns[index - 1].right + column.left) / 2 -
                  PLACEHOLDER_WIDTH / 2
              } else {
                left = containerRect.left + 12 - 1 - PLACEHOLDER_WIDTH
              }
            } else {
              // We're on the right side of a workout.
              if (index < row.columns.length - 1) {
                left =
                  (column.right + row.columns[index + 1].left) / 2 -
                  PLACEHOLDER_WIDTH / 2
              } else {
                left = column.right + 2
              }
            }

            placeholder = {
              width: PLACEHOLDER_WIDTH,
              height: PLACEHOLDER_HEIGHT,
              top: scrollY + row.top - PLACEHOLDER_HEIGHT / 2 + row.height / 2,
              left: left,
            }
          }

          if (rows.length === 0) {
            placeholder = {
              width: PLACEHOLDER_WIDTH,
              height: PLACEHOLDER_HEIGHT,
              top:
                scrollY +
                containerRect.top -
                PLACEHOLDER_HEIGHT / 2 +
                containerRect.height / 2,
              left: containerRect.left + 12 - 1 - PLACEHOLDER_WIDTH,
            }
          } else if (!placeholder) {
            let lastRow = rows[rows.length - 1]
            let lastColumn = lastRow.columns[lastRow.columns.length - 1]
            placeholder = {
              width: PLACEHOLDER_WIDTH,
              height: PLACEHOLDER_HEIGHT,
              top:
                scrollY +
                lastRow.top -
                PLACEHOLDER_HEIGHT / 2 +
                lastRow.height / 2,
              left: lastColumn.right + 2,
            }
          }
        }

        return (
          <Element
            className={classNames('relative', className)}
            ref={x => {
              ref.current = x
              container.current = x
            }}
          >
            {children}
            {isOver ? (
              <>
                {placeholder
                  ? ReactDOM.createPortal(
                      <div
                        className="absolute bg-theme-600 opacity-50 rounded"
                        style={placeholder}
                      />,
                      document.body,
                    )
                  : null}

                {copy || completing ? <CopyCursor /> : null}
                {/* <div
                  className={classNames(
                    'absolute inset-0 m-.5 opacity-25 border-2 border-dashed flex items-center justify-center',
                    {
                      'bg-theme-300 border-theme-600': !copy && !completing,
                      'bg-green-300 border-green-600': copy || completing,
                    },
                  )}
                >
                  {completing ? <Check /> : copy ? <Plus /> : <ArrowRight />}
                </div> */}
              </>
            ) : null}
          </Element>
        )
      }}
    />
  )
}

function findIntersectingWorkout(container, x, y) {
  let workouts = [...container.querySelectorAll('.draggable-workout')].map(
    (x, index) => {
      let rect = x.getBoundingClientRect()
      return {
        index,
        left: rect.left,
        top: rect.top,
        width: x.offsetWidth,
        height: x.offsetHeight,
      }
    },
  )

  let grouped = groupBy(workouts, 'top')
  let rows = []

  for (let key in grouped) {
    let maxHeight = grouped[key].reduce(
      (prev, curr) => (curr.height > prev ? curr.height : prev),
      0,
    )

    let top = grouped[key][0].top

    rows.push({
      top,
      bottom: top + maxHeight,
      height: maxHeight,
      columns: grouped[key].map(x => ({
        index: x.index,
        left: x.left,
        right: x.left + x.width,
        center: x.left + x.width / 2,
      })),
    })
  }

  let row = rows.find(x => y >= x.top - 4 && y <= x.bottom + 4)
  let column = null
  let leftOfColumn = null

  if (row) {
    column = row.columns.reduce((prev, curr) => {
      if (Math.abs(x - curr.center) < Math.abs(x - prev.center)) {
        return curr
      }
      return prev
    })

    leftOfColumn = x - column.center < 0
  }

  return { rows, row, column, leftOfColumn }
}

function CopyCursor() {
  // TODO This make the drag quite a lot slower when moving between
  // rows in the calendar.
  useLayoutEffect(() => {
    document.body.classList.add('force-cursor-copy')

    return () => document.body.classList.remove('force-cursor-copy')
  }, [])

  return null
}

import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Components/Modal'
import { X, ChevronLeft, Heart, Zap, ArrowRight, Trash } from 'react-feather'
import { removeWorkoutRecordingFromCache } from '../Utilities/GraphQLUtilities'
import { useQuery, gql, useMutation } from '@apollo/client'
import { WorkoutRecordingSampleDetails } from '../Components/WorkoutRecordingDetails'
import { parse, stringify } from 'query-string'
import { RedButton, GreenButton } from '../Components/Buttons'
import { format, parseISO } from 'date-fns'

export const DELETE_WORKOUT_RECORDING = gql`
  mutation DeleteWorkoutRecording($id: ID!) {
    deleteWorkoutRecording(id: $id) {
      deletedWorkoutRecordingId
    }
  }
`

WorkoutRecordingPage.fragments = {
  workout_recording: gql`
    fragment WorkoutRecordingPage_workout_recording on WorkoutRecording {
      id
      date
      device
      writeable
      source
      average_heart_rate
      calories
    }
  `,
}

export function WorkoutRecordingPage({ location, navigate, id }) {
  let query = parse(location.search)

  let [
    deleteWorkoutRecording,
    { loading: removingWorkoutRecordingLoading },
  ] = useMutation(DELETE_WORKOUT_RECORDING)

  function remove(workout_recording) {
    deleteWorkoutRecording({
      variables: {
        id: workout_recording.id,
      },
      update(cache, { data: { deleteWorkoutRecording } }) {
        if (
          deleteWorkoutRecording.deletedWorkoutRecordingId !==
          workout_recording.id
        ) {
          return
        }

        removeWorkoutRecordingFromCache(cache, workout_recording)
      },
    }).then(res => back())
  }

  let { data: { workout_recording } = {} } = useQuery(
    gql`
      query WorkoutRecording($id: ID!) {
        workout_recording(id: $id) {
          ...WorkoutRecordingPage_workout_recording
        }
      }
      ${WorkoutRecordingPage.fragments.workout_recording}
    `,
    {
      variables: {
        id,
      },
    },
  )

  let titleText = workout_recording
    ? `Pulsfil ${workout_recording.date}`
    : 'Pulsfil'

  function back() {
    navigate(`../../${location.search}`)
  }

  function makeWorkout() {
    navigate(
      `../../new?${stringify({
        ...query,
        planned: false,
        on: workout_recording.date,
        recording: workout_recording.id,
      })}`,
    )
  }

  let disabled = removingWorkoutRecordingLoading

  let date = workout_recording ? parseISO(workout_recording.date) : null

  return (
    <Modal onClose={back}>
      <ModalHeader
        title={titleText}
        left={
          <button
            onClick={back}
            className="flex md:hidden items-center touching:opacity-25"
          >
            <ChevronLeft /> Kalender
          </button>
        }
        right={
          <>
            <button
              onClick={back}
              className="hidden md:flex items-center hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full"
            >
              <X />
            </button>
          </>
        }
      />
      <ModalBody>
        <div className="p-4">
          <div className="flex items-center leading-tight text-gray-800 rounded bg-gray-200 p-2 mb-4">
            <div className="flex flex-1 items-center justify-start">
              <Heart size={16} className="mr-2" />
              <span>{workout_recording?.average_heart_rate ?? '-'}</span>
            </div>
            <div className="flex flex-1 items-center justify-end">
              <Zap size={16} className="mr-2" />
              <span>{workout_recording?.calories ?? '-'}</span>
            </div>
            {workout_recording?.source === 'polar' && date ? (
              <div className="ml-2 text-sm">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://flow.polar.com/training/day/${format(
                    date,
                    'dd',
                  )}.${format(date, 'MM')}.${format(date, 'yyyy')}`}
                >
                  Visa i Polar Flow
                </a>
              </div>
            ) : null}
          </div>
          <WorkoutRecordingSampleDetails id={id} />
          <GreenButton
            className="flex md:hidden items-center w-full"
            onClick={makeWorkout}
          >
            <span>Bokför träningspass</span>
            <ArrowRight size={18} className="ml-auto" />
          </GreenButton>
          {workout_recording?.writeable ? (
            <RedButton
              disabled={disabled}
              loading={removingWorkoutRecordingLoading}
              onClick={() => remove(workout_recording)}
              className="mt-4 flex md:hidden items-center w-full"
            >
              <Trash size={18} className="mr-2" />
              <span>Ta bort pulsfil</span>
            </RedButton>
          ) : null}
        </div>
      </ModalBody>
      {workout_recording ? (
        <ModalFooter>
          {workout_recording.writeable ? (
            <RedButton
              disabled={disabled}
              loading={removingWorkoutRecordingLoading}
              onClick={() => remove(workout_recording)}
              className="mr-4"
            >
              Ta bort
            </RedButton>
          ) : null}
          <GreenButton
            className="ml-auto flex items-center"
            onClick={makeWorkout}
          >
            <span className="mr-2">Bokför träningspass</span>
            <ArrowRight size={18} />
          </GreenButton>
        </ModalFooter>
      ) : null}
    </Modal>
  )
}

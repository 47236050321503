import Bugsnag from '@bugsnag/js'

export function setBugsnagUser(user) {
  if (process.env.NODE_ENV === 'production') {
    if (user) {
      let { id, email, first_name, last_name } = user
      Bugsnag.setUser(id, email, `${first_name} ${last_name}`)
    } else {
      Bugsnag.setUser(null, null, null)
    }
  }
}

import React from 'react'
import { formatSeconds } from '../../../Utilities'
import { StatisticsTable } from '../StatisticsTable'

let formatters = {
  duration(value) {
    return value ? formatSeconds(value) : '-'
  },
}
function format(field, value) {
  if (formatters[field]) {
    return formatters[field](value)
  }

  return value || '-'
}

export function StaminaTable({
  field,
  title,
  rows,
  intensities,
  sports,
  onRowClick,
  tableId,
  showPlanned,
  mainMode,
}) {
  // A3, A3+, A3-
  let highIntensityIds = [3, 4, 5]

  let columns = [
    {
      title: 'Totalt',
      // title: 'Total tid',
      value(row, { mode, field }) {
        return format(field, row.stamina[mode][field])
      },
      total(rows, { mode, field }) {
        return format(
          field,
          rows.reduce((prev, curr) => prev + curr.stamina[mode][field], 0),
        )
      },
    },
  ]

  columns.push(
    ...intensities.map(intensity => ({
      title: intensity.alias,
      tooltip: intensity.name,
      color: intensity.color,
      center: true,
      value(row, { mode, field }) {
        return format(
          field,
          row.stamina[mode].intensities.find(
            x => intensity.id === x.id?.toString(),
          )?.[field] ?? 0,
        )
      },
      total(rows, { mode, field }) {
        return format(
          field,
          rows
            .flatMap(row => row.stamina[mode].intensities)
            .filter(x => x.id?.toString() === intensity.id)
            .reduce((prev, curr) => prev + curr[field], 0),
        )
      },
    })),
  )

  columns.push({
    title: '-A3+',
    tooltip: 'Högintensivt',
    center: true,
    value(row, { mode, field }) {
      return format(
        field,
        row.stamina[mode].intensities
          .filter(x => highIntensityIds.includes(x.id))
          .reduce((prev, curr) => prev + curr[field], 0),
      )
    },
    total(rows, { mode, field }) {
      return format(
        field,
        rows
          .flatMap(row => row.stamina[mode].intensities)
          .filter(x => highIntensityIds.includes(x.id))
          .reduce((prev, curr) => prev + curr[field], 0),
      )
    },
  })

  columns.push(
    ...sports.map(sport => ({
      title: sport.alias,
      tooltip: sport.name,
      center: true,
      value(row, { mode, field }) {
        return format(
          field,
          row.stamina[mode].sports.find(x => sport.id === x.id?.toString())?.[
            field
          ] ?? 0,
        )
      },
      total(rows, { mode, field }) {
        return format(
          field,
          rows
            .flatMap(row => row.stamina[mode].sports)
            .filter(x => x.id?.toString() === sport.id)
            .reduce((prev, curr) => prev + curr[field], 0),
        )
      },
    })),
  )

  return (
    <StatisticsTable
      id={tableId}
      columns={columns}
      title={title}
      rows={rows}
      onRowClick={onRowClick}
      showPlanned={showPlanned}
      mainMode={mainMode}
      field={field}
    />
  )
}

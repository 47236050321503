import React from 'react'
import classNames from 'classnames'
import { formatSeconds, round } from '../../../Utilities'

export function GeneralTable({
  title,
  rows,
  onRowClick,
  tableId,
  showPlanned,
}) {
  let weightRows = rows.filter(x => x.general.weight !== null).length
  let totalWeight = weightRows
    ? round(
        rows.reduce((prev, curr) => prev + (curr.general.weight ?? 0), 0) /
          weightRows,
        1,
      )
    : '-'

  let sleepRows = rows.filter(x => x.general.sleep !== null).length
  let totalSleep = sleepRows
    ? formatSeconds(
        rows.reduce((prev, curr) => prev + (curr.general.sleep ?? 0), 0) /
          sleepRows,
      )
    : '-'

  let heartRateRows = rows.filter(x => x.general.heart_rate !== null).length
  let totalHeartRate = heartRateRows
    ? round(
        rows.reduce((prev, curr) => prev + (curr.general.heart_rate ?? 0), 0) /
          heartRateRows,
        1,
      )
    : '-'

  let shapeRows = rows.filter(x => x.general.shape !== null).length
  let totalShape = shapeRows
    ? round(
        rows.reduce((prev, curr) => prev + (curr.general.shape ?? 0), 0) /
          shapeRows,
        1,
      )
    : '-'

  return (
    <table
      id={tableId}
      className="min-w-full table-fixed text-sm highlight-columns"
    >
      <thead>
        <tr className="border-b">
          <th className="w-18 text-left py-1 px-2">{title}</th>
          <th className="py-1 text-right px-2">Vila</th>
          <th className="py-1 text-right px-2">Planerad vila</th>
          <th className="py-1 text-right px-2">Sjuk</th>
          <th className="py-1 text-right px-2">Skadad</th>
          <th className="py-1 text-right px-2">Alternativ träning</th>
          <th className="py-1 text-right px-2">Resdag</th>
          <th className="py-1 text-right px-2">Menstruation</th>
          <th className="py-1 text-right px-2">Snittvikt (antal)</th>
          <th className="py-1 text-right px-2">Snittsömn (antal)</th>
          <th className="py-1 text-right px-2">Snittpuls (antal)</th>
          <th className="py-1 text-right px-2">Snittform (antal)</th>
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (
          <tr
            key={row.name}
            className={classNames('border-b group', {
              'bg-theme-100': row.current,
              'cursor-pointer': onRowClick,
            })}
            onClick={onRowClick ? () => onRowClick(row) : null}
          >
            <td className="py-1.5 px-2 text-left group-hover:bg-gray-200">
              {row.name}
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.rest || '-'}
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.planned_rest || '-'}
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.ill || '-'}
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.injured || '-'}
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.alternative_training || '-'}
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.travel || '-'}
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.menstruation || '-'}
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.weight ?? '-'} ({row.general.weight_count})
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.sleep ? formatSeconds(row.general.sleep) : '-'} (
              {row.general.sleep_count})
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.heart_rate ?? '-'} ({row.general.heart_rate_count})
            </td>
            <td className="py-1.5 px-2 text-right group-hover:bg-gray-200">
              {row.general.shape ?? '-'} ({row.general.shape_count})
            </td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr className="bg-gray-200">
          <td className="py-1 px-2 font-bold">Totalt</td>
          <td className="py-1.5 px-2 text-right">
            {rows.reduce((prev, curr) => prev + curr.general.rest, 0) || '-'}
          </td>
          <td className="py-1.5 px-2 text-right">
            {rows.reduce((prev, curr) => prev + curr.general.planned_rest, 0) ||
              '-'}
          </td>
          <td className="py-1.5 px-2 text-right">
            {rows.reduce((prev, curr) => prev + curr.general.ill, 0) || '-'}
          </td>
          <td className="py-1.5 px-2 text-right">
            {rows.reduce((prev, curr) => prev + curr.general.injured, 0) || '-'}
          </td>
          <td className="py-1.5 px-2 text-right">
            {rows.reduce(
              (prev, curr) => prev + curr.general.alternative_training,
              0,
            ) || '-'}
          </td>
          <td className="py-1.5 px-2 text-right">
            {rows.reduce((prev, curr) => prev + curr.general.travel, 0) || '-'}
          </td>
          <td className="py-1.5 px-2 text-right">
            {rows.reduce((prev, curr) => prev + curr.general.menstruation, 0) ||
              '-'}
          </td>
          <td className="py-1.5 px-2 text-right">{totalWeight}</td>
          <td className="py-1.5 px-2 text-right">{totalSleep}</td>
          <td className="py-1.5 px-2 text-right">{totalHeartRate}</td>
          <td className="py-1.5 px-2 text-right">{totalShape}</td>
        </tr>
      </tfoot>
    </table>
  )
}

import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Link } from '@reach/router'
import { GreenButton } from '../Components/Buttons'

export function SendPasswordResetPage({ location, navigate }) {
  let [email, setEmail] = useState('')

  function handleSubmit(e) {
    e.preventDefault()
    sendPasswordReset({ variables: { email } })
  }

  let [
    sendPasswordReset,
    { loading: sendPasswordResetLoading, data: sendPasswordResetData },
  ] = useMutation(
    gql`
      mutation SendPasswordReset($email: String!) {
        sendPasswordReset(email: $email) {
          status
          errors {
            key
            message
          }
        }
      }
    `,
  )

  return (
    <>
      <div className="md:mt-24 md:mx-auto bg-white rounded md:shadow md:max-w-sm overflow-hidden">
        <h1 className="text-lg _text-center leading-relaxed bg-gray-100 border-b p-4">
          Återställ ditt lösenord
        </h1>
        <div className="p-4">
          {sendPasswordResetData?.sendPasswordReset.errors ? (
            <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
              {sendPasswordResetData.sendPasswordReset.errors.map((x, i) => (
                <p key={i}>{x.message}</p>
              ))}
            </div>
          ) : null}
          {sendPasswordResetData?.sendPasswordReset.status ? (
            <div className="p-2 mb-4 rounded bg-green-200 border border-green-300 text-sm">
              {sendPasswordResetData.sendPasswordReset.status}
            </div>
          ) : null}
          <form onSubmit={handleSubmit}>
            <label className="block mb-4">
              <span className="text-gray-800 font-semibold">E-postadress</span>
              <input
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                className="form-input mt-1 block w-full"
                placeholder="john.smith@example.com"
                required
                autoFocus
              />
            </label>
            <GreenButton
              loading={sendPasswordResetLoading}
              className="block w-full"
            >
              Skicka återställningslänk
            </GreenButton>
          </form>
        </div>
      </div>
      <div className="mt-4 mb-12 flex justify-center">
        <Link to="/calendar" className="hover:underline text-theme-600 text-sm">
          Logga in istället
        </Link>
      </div>
    </>
  )
}

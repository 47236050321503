import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/client'
import { Link } from '@reach/router'
import { GreenButton } from '../Components/Buttons'
import { parse } from 'query-string'

export function ResetPasswordPage({ location, navigate, token }) {
  let [password, setPassword] = useState('')
  let [passwordConfirmation, setPasswordConfirmation] = useState('')
  let query = parse(location.search)
  let { email } = query

  function handleSubmit(e) {
    e.preventDefault()
    resetPassword({
      variables: {
        input: {
          token,
          email,
          password,
          password_confirmation: passwordConfirmation,
        },
      },
    })
  }

  let [
    resetPassword,
    { loading: resetPasswordLoading, data: resetPasswordData },
  ] = useMutation(
    gql`
      mutation ResetPassword($input: ResetPasswordInput!) {
        resetPassword(input: $input) {
          status
          errors {
            key
            message
          }
        }
      }
    `,
  )

  return (
    <>
      <div className="md:mt-24 md:mx-auto bg-white rounded md:shadow md:max-w-sm overflow-hidden">
        <h1 className="text-lg _text-center leading-relaxed bg-gray-100 border-b p-4">
          Återställ ditt lösenord
        </h1>
        <div className="p-4">
          {resetPasswordData?.resetPassword.errors ? (
            <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
              {resetPasswordData.resetPassword.errors.map((x, i) => (
                <p key={i}>{x.message}</p>
              ))}
            </div>
          ) : null}
          {resetPasswordData?.resetPassword.status ? (
            <>
              <div className="p-2 mb-4 rounded bg-green-200 border border-green-300 text-sm">
                {resetPasswordData.resetPassword.status}
              </div>
              <div className="flex justify-center">
                <Link
                  to="/calendar"
                  className="hover:underline text-theme-600 text-sm"
                >
                  Logga in
                </Link>
              </div>
            </>
          ) : (
            <form onSubmit={handleSubmit}>
              <label className="block mb-4">
                <span className="text-gray-800 font-semibold">Lösenord</span>
                <input
                  type="password"
                  className="form-input mt-1 block w-full"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
              </label>
              <label className="block mb-4">
                <span className="text-gray-800 font-semibold">
                  Upprepa lösenord
                </span>
                <input
                  type="password"
                  className="form-input mt-1 block w-full"
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  required
                />
              </label>
              <GreenButton
                loading={resetPasswordLoading}
                className="block w-full"
              >
                Byt lösenord
              </GreenButton>
            </form>
          )}
        </div>
      </div>
      {!resetPasswordData ? (
        <div className="mt-4 mb-12 flex justify-center">
          <Link
            to="/calendar"
            className="hover:underline text-theme-600 text-sm"
          >
            Logga in istället
          </Link>
        </div>
      ) : null}
    </>
  )
}

import React, { useState, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { usePopper } from 'react-popper'
import { isTouch } from '../Utilities'

export function HoverPopover({ trigger, children, modifiers, placement }) {
  let [triggerEl, setTriggerEl] = useState(null)
  let [popoverEl, setPopoverEl] = useState(null)
  let { styles, attributes } = usePopper(triggerEl, popoverEl, {
    placement,
    modifiers,
  })
  let [visible, setVisible] = useState(false)

  let ref = useRef()
  let justEntered = useRef(false)

  // TODO React does not register enter, move and leave
  // correctly when we're dragging an element that is
  // a popover trigger.
  useEffect(() => {
    function onMouseEnter() {
      justEntered.current = true
    }
    function onMouseMove(e) {
      if (!justEntered.current) {
        setVisible(true)
      }
      justEntered.current = false
    }

    function onMouseLeave() {
      justEntered.current = false
      setVisible(false)
    }

    let el = ref.current

    if (el && !isTouch()) {
      el.addEventListener('mouseenter', onMouseEnter)
      el.addEventListener('mousemove', onMouseMove)
      el.addEventListener('mouseleave', onMouseLeave)

      return () => {
        el.removeEventListener('mouseenter', onMouseEnter)
        el.removeEventListener('mousemove', onMouseMove)
        el.removeEventListener('mouseleave', onMouseLeave)
      }
    }
  })

  return (
    <>
      <div
        ref={x => {
          setTriggerEl(x)
          ref.current = x
        }}
      >
        {trigger}
      </div>
      {visible
        ? ReactDOM.createPortal(
            <div
              className="z-20"
              ref={setPopoverEl}
              style={styles.popper}
              {...attributes.popper}
            >
              {children}
            </div>,
            document.body,
          )
        : null}
    </>
  )
}

export function ClickPopover({
  trigger,
  children,
  modifiers,
  visible,
  placement,
  onClickOutside = () => {},
}) {
  let [triggerEl, setTriggerEl] = useState(null)
  let [popoverEl, setPopoverEl] = useState(null)
  let { styles, attributes } = usePopper(triggerEl, popoverEl, {
    placement,
    modifiers,
  })

  // Close the popover when the user clicks outside.
  // TODO If the click sets state, this effect might get cleaned
  // up and the click event is missed. This is prevented if
  // onClickOutside() is wrapped in useCallback at the call site.
  useEffect(() => {
    function handleClickOutside(e) {
      if (
        popoverEl &&
        triggerEl &&
        !popoverEl.contains(e.target) &&
        !triggerEl.contains(e.target)
      ) {
        e.preventDefault()
        e.stopPropagation()
        onClickOutside()
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => document.removeEventListener('click', handleClickOutside)
  }, [onClickOutside, popoverEl, triggerEl])

  // If the content of the popover is scrollable, we'll
  // make sure the document doesn't scroll when the
  // popover scroll area reaches its top or bottom.
  useEffect(() => {
    function handleScroll(e) {
      if (popoverEl && popoverEl.contains(e.target)) {
        let scrollingDown = e.deltaY > 0
        let scrollingUp = e.deltaY < 0
        let container = getScrollParent(e.target)
        let maxScroll = container.scrollHeight - container.offsetHeight
        let atBottom = maxScroll - container.scrollTop <= 0
        let atTop = container.scrollTop === 0

        if (scrollingDown && atBottom) {
          container.scrollTop = maxScroll
          e.preventDefault()
        } else if (scrollingUp && atTop) {
          container.scrollTop = 0
          e.preventDefault()
        }
      }
    }
    document.addEventListener('wheel', handleScroll)
    return () => document.removeEventListener('wheel', handleScroll)
  }, [popoverEl])

  return (
    <>
      <div ref={setTriggerEl}>{trigger}</div>
      {visible ? (
        <div
          className="z-20"
          ref={setPopoverEl}
          style={styles.popper}
          {...attributes.popper}
        >
          {children}
        </div>
      ) : null}
    </>
  )
}

function getScrollParent(node) {
  if (node == null) {
    return null
  }

  if (node.scrollHeight > node.clientHeight) {
    return node
  } else {
    return getScrollParent(node.parentNode)
  }
}

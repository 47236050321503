import create from 'zustand'
// import produce from 'immer'

import { ResizeObserver as Polyfill } from '@juggle/resize-observer'
import { isInStandaloneMode } from '../Utilities'

const ResizeObserver = window.ResizeObserver || Polyfill

const onMac = () => navigator.platform.indexOf('Mac') > -1

const [useMainStore, mainStoreApi] = create((set, get) => {
  // const put = fn => set(produce(fn))

  setupModifierKeyListeners(set, get)

  let rememberStorage = isInStandaloneMode() ? localStorage : sessionStorage

  return {
    heights: {},
    heightNodes: {},
    addHeightNode(name, node) {
      set(state => ({
        heightNodes: {
          ...state.heightNodes,
          [name]: (state.heightNodes[name] ?? []).concat(node),
        },
      }))
    },
    removeHeightNode(name, node) {
      set(state => ({
        heightNodes: {
          ...state.heightNodes,
          [name]: (state.heightNodes[name] ?? []).filter(x => x !== node),
        },
      }))
    },
    // The ResizeObserver runs every time an EqualHeight component
    // changes in height.
    resizeObserver: new ResizeObserver(entries => {
      // First we'll find all the changed rows (names).
      let names = entries.map(x => x.target.dataset.name)

      // Then we'll update all items in the rows (EqualHeights with
      // the same name) to the largest height found for each row.
      set(state => {
        let heights = { ...state.heights }
        for (let name of names) {
          // If we can't find a max height, it means all items with this
          // name were removed, so we'll reset the height to undefined,
          // so that it will be the correct height immediately when
          // the row is rendered again.
          heights[name] =
            Math.max(
              ...state.heightNodes[name].map(node => node.offsetHeight),
            ) || undefined
        }

        return { heights }
      })
    }),

    modifierKeyPressed: false,
    rememberedNavigation: {
      date: rememberStorage.getItem('date'),
      mode: rememberStorage.getItem('mode'),
      areas: rememberStorage.getItem('areas'),
    },
    calendarScrollTop: { current: 0 },
    setCalendarScrollTop(top) {
      rememberStorage.setItem('calendarScrollTop', top)
      set(x => {
        // Mutating since we don't want to watch for changes.
        x.calendarScrollTop.current = top
        return x
      })
    },
    setRememberedNavigation({ date, mode, areas }) {
      if (date) {
        rememberStorage.setItem('date', date)
      } else {
        rememberStorage.removeItem('date')
      }
      if (mode) {
        rememberStorage.setItem('mode', mode)
      } else {
        rememberStorage.removeItem('mode')
      }
      if (areas) {
        rememberStorage.setItem('areas', areas)
      } else {
        rememberStorage.removeItem('areas')
      }
      set({ rememberedNavigation: { date, mode, areas } })
    },
    reset() {
      get().setRememberedNavigation({})
      set(x => ({ forceUpdateApp: x.forceUpdateApp + 1 }))
    },
    refreshApplication() {
      set(x => ({ forceUpdateApp: x.forceUpdateApp + 1 }))
    },
    forceUpdateApp: 0,

    // The summary switches the content for a workout summary table in period mode.
    showCalendarSummary: false,
    // Single event listener for storing scroll position when summary is toggled on.
    onEnterCalendarSummaryFn: null,
    onEnterCalendarSummary(fn) {
      set({ onEnterCalendarSummaryFn: fn })
    },
    toggleCalendarSummary() {
      if (!get().showCalendarSummary) {
        if (get().onEnterCalendarSummaryFn) {
          get().onEnterCalendarSummaryFn()
        }
        set({
          showCalendarSummary: true,
        })
        // pushScrollY()
      } else {
        set({ showCalendarSummary: false })
        // popScrollY()
      }
    },
  }
})

function setupModifierKeyListeners(set, get) {
  function update(e) {
    let pressed = (onMac() && e.metaKey) || (!onMac() && e.ctrlKey) || e.altKey

    if (pressed !== get().modifierKeyPressed) {
      set({ modifierKeyPressed: pressed })
    }
  }

  window.addEventListener('keydown', update)
  window.addEventListener('keyup', update)

  // We'll always remove the copy key state when focusing a window,
  // since ctrl/meta might have been pressed before blur, and released before focus.
  window.addEventListener('focus', e => {
    if (get().modifierKeyPressed) {
      set({ modifierKeyPressed: false })
    }
  })
}

export { useMainStore, mainStoreApi }

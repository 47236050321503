import classNames from 'classnames'
import { format } from 'date-fns'
import produce from 'immer'
import React, { useLayoutEffect, useState, useRef, useEffect } from 'react'
import {
  ChevronLeft,
  ChevronRight,
  List,
  Lock,
  Plus,
  Unlock,
  X,
  BarChart2,
  HelpCircle,
} from 'react-feather'
import { Area, AreaHeader } from '../Components/Area'
import { AllSeasons } from '../Components/Calendar/AllSeasons'
import { Period } from '../Components/Calendar/Period'
import { Season } from '../Components/Calendar/Season'
import { biathlonPeriod } from '../Periods/MultiWeekPeriod'
import {
  fullSeason,
  isCurrent,
  isCurrentPeriod,
  SEASONS_PER_PAGE,
  shortSeason,
} from '../Periods/PeriodUtilities'
import { getAreas, Modes } from '../Utilities'
import { useMainStore } from '../Stores/MainStore'
import { useCalendarInfo } from '../Api/useCalendarInfo'
import { useCategories } from '../Api/useCategories'
import { useCalendarPeriod } from '../Api/useCalendarPeriod'
import { useQueryParams } from '../Hooks/useQueryParams'
import { useIsFirstRender } from '../Hooks/useIsFirstRender'
import { useNavigateQuery } from '../Hooks'
const { period, addPeriods, subPeriods, addSeasons, subSeasons } =
  biathlonPeriod

function useCalendarPageScrollHandling({ date, mode }) {
  let firstRender = useIsFirstRender()
  let setCalendarScrollTop = useMainStore(state => state.setCalendarScrollTop)
  let calendarScrollTop = useMainStore(state => state.calendarScrollTop)

  date = format(date, 'yyyy-MM-dd')

  // Scroll to the top when any global, time-related navigation occurs.
  useLayoutEffect(() => {
    if (firstRender.current) return
    window.scrollTo(window.scrollX, 0)
  }, [date, mode, firstRender])

  // Remember calendar scroll when navigating from another url to calendar.
  useLayoutEffect(() => {
    if (!firstRender.current) return
    window.scrollTo(window.scrollX, calendarScrollTop.current)
  }, [calendarScrollTop, firstRender])

  useEffect(() => {
    function handleScroll() {
      setCalendarScrollTop(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [setCalendarScrollTop])
}

function useCalendarSummaryToggle() {
  let summaryScrollY = useRef(null)

  // We're using global state here since this one can be toggled from
  // the navbar on mobile - and that's on a totally different subtree.
  let showSummary = useMainStore(state => state.showCalendarSummary)
  let toggleSummary = useMainStore(state => state.toggleCalendarSummary)

  // Remember scroll position when viewing the summary.
  // This is a singleton but we should only be using it
  // in the CalendarPage component so it's fine.
  // We must get the scroll position right after the
  // toggle click, so we can't do it in useEffect.
  let onEnterCalendarSummary = useMainStore(
    state => state.onEnterCalendarSummary,
  )

  useEffect(() => {
    onEnterCalendarSummary(() => {
      summaryScrollY.current = window.scrollY
    })

    return () => onEnterCalendarSummary(null)
  }, [onEnterCalendarSummary])

  useLayoutEffect(() => {
    requestAnimationFrame(() => {
      if (showSummary) {
        window.scrollTo(window.scrollX, 0)
      } else if (!showSummary && summaryScrollY.current !== null) {
        window.scrollTo(window.scrollX, summaryScrollY.current)
        summaryScrollY.current = null
      }
    })
  }, [showSummary])

  return [showSummary, toggleSummary]
}

function MobileCreateModeToggle({ createMode, setCreateMode }) {
  return (
    <button
      onClick={() => setCreateMode(!createMode)}
      className="flex justify-center items-center md:hidden fixed z-20 bottom-20 right-6 mb-ios-safe w-14 h-14 text-white rounded-full bg-theme-600 active:bg-theme-500 shadow-strong"
    >
      {createMode ? <X /> : <Plus />}
    </button>
  )
}

export function CalendarPage({ children }) {
  let navigateQuery = useNavigateQuery()
  let query = useQueryParams()
  let { date, mode, areas } = getAreas(query)

  // Create mode is for mobile, enables creation of workouts
  // at any given cell in the calendar by tapping them.
  const [createMode, setCreateMode] = useState(false)

  let [showSummary, toggleSummary] = useCalendarSummaryToggle()
  useCalendarPageScrollHandling({ date, mode })

  const formatAreaDate = area => ({
    ...area,
    date: area.date && format(area.date, 'yyyy-MM-dd'),
  })

  function updateArea(index, { date, mode }) {
    navigateQuery({
      areas: JSON.stringify(
        produce(areas, draft => {
          draft[index].date = date
          draft[index].mode = mode
        }).map(formatAreaDate),
      ),
    })
  }

  function closeArea(index) {
    navigateQuery({
      areas: JSON.stringify(
        areas.filter((_, i) => i !== index).map(formatAreaDate),
      ),
    })
  }

  function onNavigateAllAreas(date, mode) {
    navigateQuery({
      date: format(date, 'yyyy-MM-dd'),
      mode,
    })
  }

  return (
    <div className="flex justify-center md:px-3 md:my-4 flex-col md:flex-row">
      {areas.map((area, index) => {
        // When locking, we'll add the global date to the area.
        let onLock = () => updateArea(index, { date, mode })

        // When unlocking, we'll remove the global date to the area.
        let onUnlock = () =>
          updateArea(index, { date: undefined, mode: undefined })

        // When closing, we remove the area completely from the query.
        let onClose = () => closeArea(index)

        // Navigating inside an area will only change that area's date,
        // and not the global one.
        let onNavigateArea = (date, mode) => updateArea(index, { date, mode })

        return (
          <CalendarArea
            key={`${index}:${area.type}:${area.id}`}
            area={area}
            date={date}
            mode={mode}
            createMode={createMode}
            setCreateMode={setCreateMode}
            onLock={onLock}
            onUnlock={onUnlock}
            onClose={onClose}
            onNavigateArea={onNavigateArea}
            onNavigateAllAreas={onNavigateAllAreas}
            showSummary={showSummary}
            toggleSummary={toggleSummary}
          />
        )
      })}
      {children}
      {mode === Modes.Period ? (
        <MobileCreateModeToggle
          createMode={createMode}
          setCreateMode={setCreateMode}
        />
      ) : null}
    </div>
  )
}

function CalendarArea({
  area,
  date,
  mode,
  createMode,
  setCreateMode,
  onNavigateArea,
  onNavigateAllAreas,
  onClose,
  onLock,
  onUnlock,
  showSummary,
  toggleSummary,
}) {
  // If the area has a custom date set, we'll use that
  // instead of the global one - this area is locked
  // and navigation is separated from the others.
  let locked = !!area.date
  date = area.date || date
  mode = area.mode || mode

  let {
    writeable = false,
    userPlannedWriteable = false,
    title,
  } = useCalendarInfo(area)

  let { intensities = [] } = useCategories()

  let {
    workouts = [],
    workout_recordings = [],
    daily_notes = [],
  } = useCalendarPeriod(date, area, {
    skip: mode !== Modes.Period,
    preload: true,
  })

  // let loading = loadingTypes || loadingInfo || loadingPeriod

  function renderCalendarBody() {
    if (mode === Modes.All) {
      return (
        <AllSeasons
          date={date}
          area={area}
          onNavigate={locked ? onNavigateArea : onNavigateAllAreas}
        />
      )
    } else if (mode === Modes.Season) {
      return (
        <Season
          date={date}
          area={area}
          onNavigate={locked ? onNavigateArea : onNavigateAllAreas}
        />
      )
    } else if (mode === Modes.Period) {
      return (
        <Period
          date={date}
          area={area}
          writeable={writeable}
          userPlannedWriteable={userPlannedWriteable}
          title={title}
          createMode={createMode}
          setCreateMode={setCreateMode}
          showSummary={showSummary}
          workouts={workouts}
          workout_recordings={workout_recordings}
          daily_notes={daily_notes}
          intensities={intensities}
        />
      )
    }
    return null
  }

  return (
    <Area narrow={area.type === 'program'}>
      <AreaHeader>
        <div className="flex">
          <button
            onClick={() => (locked ? onUnlock() : onLock())}
            className={classNames(
              'px-2 rounded mr-2 hover:bg-gray-200 active:bg-gray-300 flex items-center',
              {
                'text-gray-800': !locked,
                'bg-gray-200 text-theme-600': locked,
              },
            )}
          >
            {locked ? <Lock size={18} /> : <Unlock size={18} />}
          </button>
          {/* Set height of button to match other buttons containing text */}
          <span className="invisible w-px py-1 text-sm">&nbsp;</span>
        </div>
        <p className="mr-2">{title}</p>
        {locked ? (
          <div className="flex-grow flex mr-2">
            <PeriodNav
              date={date}
              mode={mode}
              onNavigate={onNavigateArea}
              buttonClassName="rounded hover:bg-gray-200 active:bg-gray-300 text-gray-800 text-sm"
              activeButtonClassName="bg-gray-200 shadow-inner"
            />
          </div>
        ) : null}
        {/* {loading ? <Spinner dark /> : null} */}
        <button
          onClick={toggleSummary}
          className={classNames(
            'ml-auto text-sm px-2 py-1 rounded mr-2 hover:bg-gray-200 active:bg-gray-300 flex items-center',
            {
              'text-gray-800': !showSummary,
              'bg-gray-200 text-theme-600': showSummary,
            },
          )}
        >
          <BarChart2 size={18} className="mr-2" />
          <span>Summering</span>
        </button>
        <button
          onClick={onClose}
          className="hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full flex items-center"
        >
          <X />
        </button>
      </AreaHeader>
      {renderCalendarBody()}
    </Area>
  )
}

export function CalendarNav() {
  let query = useQueryParams()
  let navigateQuery = useNavigateQuery()
  let { date, mode, areas } = getAreas(query)

  let navigateMode = mode => navigateQuery({ mode })

  // Only one area can be active at a time on mobile.
  // We'll grab that one to set the navbar title.
  let mobileArea = areas[0]

  let { title } = useCalendarInfo(mobileArea, { skip: !mobileArea })

  return (
    <div className="md:bg-theme-500 md:text-white md:flex px-4 justify-center border-b md:border-none">
      <div className="flex-1 flex md:hidden h-12">
        {/* Left part */}
        <div className="flex-1 flex">
          {mode === Modes.All ? null : mode === Modes.Season ? (
            <button
              onClick={() => navigateMode(Modes.All)}
              className="flex items-center touching:opacity-25"
            >
              <ChevronLeft /> Säsonger
            </button>
          ) : mode === Modes.Period ? (
            <button
              onClick={() => navigateMode(Modes.Season)}
              className="flex items-center touching:opacity-25"
            >
              <ChevronLeft /> {shortSeason(date)}
            </button>
          ) : null}
        </div>
        <h2 className="flex items-center font-semibold">{title}</h2>
        {/* Right part */}
        <div className="flex-1 flex justify-end">
          <button
            onClick={() => navigateQuery('/calendar/area-switcher', true)}
            className="items-center touching:opacity-25"
          >
            <List />
          </button>
        </div>
      </div>

      <div className="flex-1 flex items-center max-w-6xl md:text-sm h-12 md:h-10">
        <div className="flex-1 hidden md:flex">
          <button
            onClick={() => navigateQuery('/calendar/help', true)}
            className="flex items-center px-2 rounded mr-2 hover:bg-theme-600 active:bg-theme-700 font-semibold"
          >
            {/* <Box className="mr-2" /> */}
            <HelpCircle size={22} className="mr-2" />
            <span>Hjälp</span>
          </button>
          {/* Sets the button padding-y equal to other buttons with text only */}
          <div className="py-1 invisible w-px">&nbsp;</div>
        </div>
        <PeriodNav
          date={date}
          mode={mode}
          onNavigate={(date, mode) => {
            navigateQuery({
              date: isCurrentPeriod(date, mode)
                ? undefined
                : format(date, 'yyyy-MM-dd'),
              mode: mode === Modes.Period ? undefined : mode,
            })
          }}
        />
      </div>
    </div>
  )
}

function PeriodNav({
  date,
  mode,
  onNavigate,
  buttonClassName = 'rounded md:hover:bg-theme-600 md:active:bg-theme-700 md:font-semibold',
  activeButtonClassName = 'md:bg-theme-600 text-theme-600 md:text-white md:shadow-inner',
}) {
  let showSummary = useMainStore(state => state.showCalendarSummary)
  let toggleSummary = useMainStore(state => state.toggleCalendarSummary)

  let previous = date
  let next = date
  if (mode === Modes.All) {
    previous = subSeasons(date, SEASONS_PER_PAGE)
    next = addSeasons(date, SEASONS_PER_PAGE)
  } else if (mode === Modes.Season) {
    previous = subSeasons(date, 1)
    next = addSeasons(date, 1)
  } else if (mode === Modes.Period) {
    previous = subPeriods(date, 1)
    next = addPeriods(date, 1)
  }

  return (
    <>
      <div className="hidden md:flex">
        <button
          onClick={() => onNavigate(date, Modes.All)}
          className={classNames(
            'hidden md:flex items-center py-1 px-2 mr-2',
            buttonClassName,
            {
              [activeButtonClassName]: mode === Modes.All,
            },
          )}
        >
          Alla säsonger
        </button>
        <button
          onClick={() => onNavigate(date, Modes.Season)}
          className={classNames(
            'hidden md:flex items-center py-1 px-2 mr-2',
            buttonClassName,
            {
              [activeButtonClassName]: mode === Modes.Season,
            },
          )}
        >
          Säsong {shortSeason(date)}
        </button>
        <button
          onClick={() => onNavigate(date, Modes.Period)}
          className={classNames(
            'flex items-center py-1 px-2 mr-2',
            buttonClassName,
            {
              [activeButtonClassName]: mode === Modes.Period,
            },
          )}
        >
          Period {period(date)}
        </button>
      </div>
      <div className="md:hidden">
        {mode === Modes.All ? (
          'Alla säsonger'
        ) : mode === Modes.Season ? (
          fullSeason(date)
        ) : mode === Modes.Period ? (
          <button
            onClick={toggleSummary}
            className={classNames(
              'flex items-center px-2 py-1 rounded active:bg-gray-300',
              {
                'text-gray-800': !showSummary,
                'bg-gray-200 text-theme-600': showSummary,
              },
            )}
          >
            <BarChart2 size={18} className="mr-2" />
            <span>Period {period(date)}</span>
          </button>
        ) : null}
      </div>
      <div className="flex-1 flex justify-end">
        <button
          onClick={() => onNavigate(previous, mode)}
          className={classNames(
            'flex items-center px-2 mr-2 touching:opacity-25',
            buttonClassName,
          )}
        >
          <ChevronLeft />
        </button>
        <button
          onClick={() => {
            // Navigate to the current "mode page". If we're already at the
            // current page, go to the more specific mode if available.
            let toMode = mode
            if (isCurrent(date, mode)) {
              if (mode === Modes.All) {
                toMode = Modes.Season
              } else {
                toMode = Modes.Period
              }
            }
            onNavigate(new Date(), toMode)
          }}
          className={classNames(
            'flex items-center py-1 px-3 mr-2 touching:opacity-25',
            buttonClassName,
            {
              [activeButtonClassName]: isCurrentPeriod(date, mode),
            },
          )}
        >
          Idag
        </button>
        <button
          onClick={() => onNavigate(next, mode)}
          className={classNames(
            'flex items-center px-2 touching:opacity-25 ',
            buttonClassName,
          )}
        >
          <ChevronRight />
        </button>
      </div>
    </>
  )
}

import React, { useRef, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useDragDropStore } from '../Stores/DragDropStore'

// function useLongPress2(callback, delay = 400) {
//   const timeout = useRef()

//   const start = useCallback(
//     e => {
//       e.persist()
//       timeout.current = setTimeout(() => {
//         callback(e)
//       }, delay)
//     },
//     [callback, delay],
//   )

//   const clear = useCallback(() => {
//     if (timeout.current) {
//       clearTimeout(timeout.current)
//     }
//   }, [])

//   return {
//     onTouchStart: e => start(e),
//     onTouchMove: e => clear(),
//     onTouchEnd: e => clear(),
//   }
// }

function useLongPress(callback = () => {}, delay = 400) {
  const [startLongPress, setStartLongPress] = useState(false)
  const [event, setEvent] = useState()

  useEffect(() => {
    let timerId
    if (startLongPress) {
      timerId = setTimeout(() => callback(event), delay)
    } else {
      clearTimeout(timerId)
    }

    return () => {
      clearTimeout(timerId)
    }
  }, [callback, delay, startLongPress, event])

  return {
    onTouchStart: e => {
      e.persist()
      setEvent(e)
      setStartLongPress(true)
    },
    onTouchMove: () => setStartLongPress(false),
    onTouchEnd: () => setStartLongPress(false),
  }
}

export function Draggable({ render, preview, data }) {
  let ref = useRef()
  let draggableRef = useRef()
  let beginDrag = useDragDropStore(state => state.beginDrag)
  let dragging = useDragDropStore(state => state.isDragging(ref))
  // TODO x and y changes frequently, this can be optimized.
  let x = useDragDropStore(state => state.x)
  let y = useDragDropStore(state => state.y)
  let offsetX = useDragDropStore(state => state.offsetX)
  let offsetY = useDragDropStore(state => state.offsetY)
  let width = useDragDropStore(state => state.rect?.width)
  let height = useDragDropStore(state => state.rect?.height)

  x -= offsetX
  y -= offsetY

  function onMouseDown(e) {
    e.preventDefault()
    let rect = draggableRef.current.getBoundingClientRect()
    beginDrag({
      ref,
      data,
      rect,
      x: e.clientX,
      y: e.clientY,
      mode: 'mouse',
    })
  }

  function onTouchStart(e) {
    e.preventDefault()
    let rect = draggableRef.current.getBoundingClientRect()
    beginDrag({
      ref,
      data,
      rect,
      x: e.touches[0].clientX,
      y: e.touches[0].clientY,
      mode: 'touch',
    })
  }

  let longPress = useLongPress(onTouchStart)

  return (
    <>
      {render({ onMouseDown, ...longPress, ref: draggableRef }, { dragging })}
      {dragging
        ? ReactDOM.createPortal(
            preview({
              style: {
                zIndex: '100',
                position: 'fixed',
                top: y,
                left: x,
                width,
                height,
              },
            }),
            document.body,
          )
        : null}
    </>
  )
}

export function DropArea({ render, onDrop = () => {}, accept }) {
  let ref = useRef()
  let addDropListener = useDragDropStore(state => state.addDropListener)
  // TODO x and y changes frequently, this can be optimized.
  let x = useDragDropStore(state => state.x)
  let y = useDragDropStore(state => state.y)
  let data = useDragDropStore(state => state.data)
  let dragging = useDragDropStore(state => state.dragging)

  let accepting = accept.find(x => x === data?.type)
  let isOver = false
  let rect = ref.current?.getBoundingClientRect()

  if (
    accepting &&
    rect &&
    dragging &&
    x >= rect.left &&
    x <= rect.right &&
    y >= rect.top &&
    y <= rect.bottom
  ) {
    isOver = true
  }

  useEffect(() => {
    function handleDrop() {
      if (isOver && accepting) {
        onDrop(data, { x, y })
      }
    }

    if (accepting) {
      let removeDropListener = addDropListener(handleDrop)

      return () => removeDropListener()
    }
  })

  if (!accepting) {
    return render({ ref, isOver: false, data: null, x: null, y: null })
  }

  return render({ ref, isOver, data, x, y })
}

import { parseISO, isValid } from 'date-fns'

export function round(value, digits) {
  return (
    Math.round((value + Number.EPSILON) * Math.pow(10, digits)) /
    Math.pow(10, digits)
  )
}

export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase()
  return /iphone|ipad|ipod/.test(userAgent)
}

export const isInStandaloneMode = () =>
  'standalone' in window.navigator && window.navigator.standalone

export function startsWith(string, search) {
  return string.substr(0, search.length) === search
}

export const intersperse = (array, delim) =>
  [].concat(...array.map(item => [delim, item])).slice(1)

export const cssVariable = name =>
  getComputedStyle(document.body).getPropertyValue(name).trim()

export const isTouch = () => window.matchMedia('(hover: none)').matches

export function formatSeconds(seconds, includeSeconds = false) {
  let hours = Math.floor(seconds / 3600),
    minutes = Math.floor((seconds - hours * 3600) / 60)

  if (hours < 10) {
    hours = '0' + hours
  }
  if (minutes < 10) {
    minutes = '0' + minutes
  }

  let text = '' + hours + ':' + minutes

  if (includeSeconds) {
    seconds = seconds - hours * 3600 - minutes * 60

    if (seconds < 10) {
      seconds = '0' + seconds
    }

    text += ':' + seconds
  }

  return text
}

export const Modes = {
  All: 'all',
  Season: 'season',
  Period: 'period',
}

const availableModes = Object.keys(Modes).map(x => Modes[x])

export function nextAreaKey(areas) {
  // Default to key = 0 for areas that are already in the query string when this
  // code was pushed. Those areas won't be keyed until the user closes and reopens them.
  return Math.max(...areas.map(a => parseInt(a.key, 10) || 0)) + 1
}

export function getAreas(query) {
  let date = new Date()
  if (query.date) {
    let parsed = parseISO(query.date)
    if (isValid(parsed)) date = parsed
  }

  let mode = Modes.Period
  if (availableModes.includes(query.mode)) {
    mode = query.mode
  }

  // Here we default to one area - the current user. If a JSON
  // array is set in the 'area' query param, we'll try to use
  // that as the source of the currently open areas.
  let areas = [{ type: 'user', id: 'me', key: '1' }]
  if (query.areas) {
    try {
      areas = JSON.parse(query.areas)
    } catch {}
  }

  let nextKey = nextAreaKey(areas)

  // Validate areas and parse dates.
  for (let area of areas) {
    if (area.date) {
      let parsed = parseISO(area.date)
      area.date = isValid(parsed) ? parsed : undefined
    }
    if (area.statsDate) {
      let parsed = parseISO(area.statsDate)
      area.statsDate = isValid(parsed) ? parsed : undefined
    }
    area.mode = availableModes.includes(area.mode) ? area.mode : undefined
    // Assign default keys to areas that don't have one - because of an old query string
    // for example.
    if (!area.key) {
      area.key = nextKey++
    }
  }

  // Sort areas in order: programs -> users -> me.
  areas.sort((a, b) => {
    if (a.type === 'user' && b.type === 'program') {
      return 1
    } else if (a.type === 'program' && b.type === 'user') {
      return -1
    } else if (a.type === 'user' && b.type === 'user') {
      if (a.id === b.id) return 0
      else if (a.id === 'me') return 1
      else if (b.id === 'me') return -1
      else return 0
    } else {
      return 0
    }
  })

  return { date, mode, areas }
}

export const percent = (fraction, total) =>
  total === 0 ? '-' : Math.round((fraction / total) * 100) + ' %'

export function groupBy(xs, key) {
  return xs.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export function keyBy(items, key) {
  return items.reduce((prev, curr) => {
    prev[curr[key]] = curr
    return prev
  }, {})
}

export function mapGroup(group, callback) {
  let keys = Object.keys(group)

  return keys.map(key => callback(key, group[key]))
}

export function distributeSamplesToZones(heart_rate_zones, sampleSet) {
  let keys = Object.keys(sampleSet)

  let zones = createZones(heart_rate_zones, keys)

  for (let key of keys) {
    let { samples, total } = sampleSet[key]
    let distributedTotal = 0

    for (let i = 1; i < samples.length; i++) {
      let previous = samples[i - 1]
      let current = samples[i]

      let zoneId = getZoneId(current.heart_rate, heart_rate_zones)

      let value = current[key] - previous[key]

      distributedTotal += value

      zones[zoneId][key] += value
    }

    // Add a zone without intensity if the sum of sample values doesn't add up to the total
    let diff = total - distributedTotal
    if (diff > 0) {
      zones[-1][key] += diff
    }
  }

  return Object.keys(zones).map(k => zones[k])
}

function createZones(heart_rate_zones, keys) {
  // Use id: -1 as a fallback zone for unspecified for time
  // outside of the heart rate boundaries.
  return heart_rate_zones.concat({ id: -1 }).reduce(
    (prev, curr) => ({
      ...prev,
      [curr.id]: {
        id: curr.id,
        intensity: curr.intensity,
        ...keys.reduce((prev, curr) => ({ ...prev, ...{ [curr]: 0 } }), {}),
      },
    }),
    {},
  )
}

function getZoneId(heartRate, zones) {
  let zone = zones.find(
    zone => heartRate >= zone.lower_limit && heartRate <= zone.upper_limit,
  )

  return zone ? zone.id : -1
}

export function biathlonStats(activities) {
  let shots = {
    prone: 0,
    standing: 0,
    pronePercentShots: 0,
    standingPercentShots: 0,
    proneHits: 0,
    standingHits: 0,
  }

  for (let activity of activities) {
    if (activity.biathlon_type?.category === 'points') {
      shots.prone += activity.prone_max_score / 10
      shots.standing += activity.standing_max_score / 10
    } else {
      shots.prone += activity.prone_max_score
      shots.standing += activity.standing_max_score
      if (activity.prone_score !== null) {
        shots.pronePercentShots += activity.prone_max_score
        shots.proneHits += activity.prone_score
      }
      if (activity.standing_score !== null) {
        shots.standingPercentShots += activity.standing_max_score
        shots.standingHits += activity.standing_score
      }
    }
  }

  return shots
}

let optimisticCounter = 0
export function optimisticId() {
  return `optimistic:${optimisticCounter++}`
}

export function isOptimistic(item) {
  if (typeof item.id !== 'string') return false

  return item.id.indexOf('optimistic:') === 0
}

export function translateRole(name) {
  return { athlete: 'Aktiv', coach: 'Tränare', owner: 'Ägare' }[name]
}

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
export function debounce(func, wait, immediate) {
  var timeout
  return function () {
    var context = this,
      args = arguments
    var later = function () {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}

export function cookie(name) {
  let match = document.cookie.match(
    new RegExp('(^|;\\s*)(' + name + ')=([^;]*)'),
  )
  return match ? decodeURIComponent(match[3]) : null
}

export function downloadTable(table_id, name, separator = ';') {
  // Select rows from table_id
  var rows = document.querySelectorAll('table#' + table_id + ' tr')

  // Construct csv
  var csv = []
  for (var i = 0; i < rows.length; i++) {
    var row = [],
      cols = rows[i].querySelectorAll('td, th')
    for (var j = 0; j < cols.length; j++) {
      // Clean innertext to remove multiple spaces and jumpline (break csv).
      // Use data-text attribute instead of inner text if it's set.
      var data = (cols[j].dataset.text || cols[j].innerText)
        .replace(/(\r\n|\n|\r)/gm, '')
        .replace(/(\s\s)/gm, ' ')
      // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
      data = data.replace(/"/g, '""')
      // Push escaped string
      row.push('"' + data + '"')
    }
    csv.push(row.join(separator))
  }

  var csv_string = csv.join('\n')

  // Download it
  var filename = name + ' ' + new Date().toLocaleDateString() + '.csv'

  var link = document.createElement('a')
  link.style.display = 'none'
  link.setAttribute('target', '_blank')
  link.setAttribute(
    'href',
    'data:text/csv;charset=utf-8,' + encodeURIComponent(csv_string),
  )
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

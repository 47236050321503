import React from 'react'

export function Controls({ field, setField, showPercent, setShowPercent }) {
  return (
    <div className="flex flex-wrap items-center text-sm bg-gray-200 rounded mx-4 mb-8 py-1">
      <label className="mx-2 my-1 inline-flex items-center">
        <input
          type="radio"
          className="form-radio border border-gray-500"
          value="duration"
          checked={field === 'duration'}
          onChange={e => setField(e.target.value)}
        />
        <span className="ml-2">Tid</span>
      </label>
      <label className="mx-2 my-1 inline-flex items-center">
        <input
          type="radio"
          className="form-radio border border-gray-500"
          value="workouts"
          checked={field === 'workouts'}
          onChange={e => setField(e.target.value)}
        />
        <span className="ml-2">Pass</span>
      </label>
      <label className="mx-2 my-1 inline-flex items-center">
        <input
          type="radio"
          className="form-radio border border-gray-500"
          value="distance"
          checked={field === 'distance'}
          onChange={e => setField(e.target.value)}
        />
        <span className="ml-2">Sträcka (km)</span>
      </label>
      <label className="mx-2 my-1 inline-flex items-center">
        <input
          type="radio"
          className="form-radio border border-gray-500"
          value="ascent"
          checked={field === 'ascent'}
          onChange={e => setField(e.target.value)}
        />
        <span className="ml-2">Höjdmeter</span>
      </label>
      {/* <label className="ml-auto px-2 py-1 inline-flex items-center opacity-50 cursor-not-allowed">
        <input
          type="checkbox"
          className="form-checkbox border border-gray-500"
          // checked={showPercent}
          // onChange={e => setShowPercent(e.target.checked)}
          disabled
        />
        <span className="ml-2">Visa procent</span>
      </label> */}
    </div>
  )
}

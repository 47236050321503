import { AllSeasons } from './AllSeasons'
import { Season } from './Season'

import React from 'react'

export default function General({
  start,
  end,
  showPlanned,
  area,
  onShowSeason,
  areaTitle,
  periodTitle,
  date,
  split,
}) {
  return (
    <>
      <div className="mb-8" />
      {date ? (
        <Season
          date={date}
          split={split}
          start={start}
          end={end}
          showPlanned={showPlanned}
          area={area}
          areaTitle={areaTitle}
          periodTitle={periodTitle}
        />
      ) : (
        <AllSeasons
          start={start}
          end={end}
          showPlanned={showPlanned}
          area={area}
          onShowSeason={onShowSeason}
          areaTitle={areaTitle}
          periodTitle={periodTitle}
        />
      )}
    </>
  )
}

import React, { useState, useEffect, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import { X, ChevronLeft } from 'react-feather'
import { Area, AreaHeader } from '../Components/Area'
import { useQuery, gql, useMutation } from '@apollo/client'
import { PrimaryButton, RedButton } from '../Components/Buttons'
import produce from 'immer'

export function SettingsPage({ children, navigate, location }) {
  useLayoutEffect(() => {
    window.scrollTo(window.scrollX, 0)
  }, [])

  let [firstName, setFirstName] = useState('')
  let [lastName, setLastName] = useState('')
  let [email, setEmail] = useState('')
  let [currentPassword, setCurrentPassword] = useState('')
  let [newPassword, setNewPassword] = useState('')
  let [newPasswordConfirmation, setNewPasswordConfirmation] = useState('')
  let [zones, setZones] = useState([])
  let [importRecordingsAsWorkouts, setImportRecordingsAsWorkouts] =
    useState(false)

  let { data } = useQuery(
    gql`
      {
        me {
          id
          first_name
          last_name
          email
          connected_to_polar
          connected_to_garmin
          import_recordings_as_workouts
          heart_rate_zones {
            id
            lower_limit
            upper_limit
            intensity {
              id
            }
          }
        }
        intensities {
          id
          name
          level
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  )

  // Set the initial state from the user.
  useEffect(() => {
    if (data?.me) {
      setFirstName(data.me.first_name)
      setLastName(data.me.last_name)
      setEmail(data.me.email)
      setImportRecordingsAsWorkouts(data.me.import_recordings_as_workouts)
      setZones(
        data.intensities
          .slice()
          .sort((a, b) => a.level - b.level)
          .map(intensity => {
            let zone = data.me.heart_rate_zones.find(
              x => x.intensity.id === intensity.id,
            )

            return {
              lower_limit: zone?.lower_limit ?? null,
              upper_limit: zone?.upper_limit ?? null,
              intensity,
            }
          }),
      )
    }
  }, [data])

  let [
    disconnectFromPolar,
    { loading: disconnectFromPolarLoading, data: disconnectFromPolarData },
  ] = useMutation(gql`
    mutation DisconnectFromPolar {
      disconnectFromPolar {
        user {
          id
          connected_to_polar
        }
        errors {
          key
          message
        }
      }
    }
  `)

  let [
    disconnectFromGarmin,
    { loading: disconnectFromGarminLoading, data: disconnectFromGarminData },
  ] = useMutation(gql`
    mutation DisconnectFromGarmin {
      disconnectFromGarmin {
        user {
          id
          connected_to_garmin
        }
        errors {
          key
          message
        }
      }
    }
  `)

  let [updateName, { loading: updateNameLoading, data: updateNameData }] =
    useMutation(gql`
      mutation UpdateName($firstName: String!, $lastName: String!) {
        updateName(first_name: $firstName, last_name: $lastName) {
          user {
            id
            first_name
            last_name
          }
          errors {
            key
            message
          }
        }
      }
    `)

  let [updateEmail, { loading: updateEmailLoading, data: updateEmailData }] =
    useMutation(gql`
      mutation UpdateEmail($email: String!) {
        updateEmail(email: $email) {
          user {
            id
            email
          }
          errors {
            key
            message
          }
        }
      }
    `)

  let [
    updatePassword,
    { loading: updatePasswordLoading, data: updatePasswordData },
  ] = useMutation(gql`
    mutation UpdatePassword($input: UpdatePasswordInput!) {
      updatePassword(input: $input) {
        user {
          id
        }
        errors {
          key
          message
        }
      }
    }
  `)

  let [
    updateHeartRateZones,
    { loading: updateHeartRateZonesLoading, data: updateHeartRateZonesData },
  ] = useMutation(gql`
    mutation UpdateHeartRateZones($input: UpdateHeartRateZonesInput!) {
      updateHeartRateZones(input: $input) {
        user {
          id
          import_recordings_as_workouts
          heart_rate_zones {
            id
            lower_limit
            upper_limit
            intensity {
              id
            }
          }
        }
        errors {
          key
          message
        }
      }
    }
  `)

  return (
    <>
      <div className="md:hidden fixed bg-white top-0 left-0 right-0 z-20 px-4 h-12 flex items-center justify-center border-b">
        <div className="flex-1">
          <button
            onClick={() => navigate('/more')}
            className="flex md:hidden items-center active:opacity-25"
          >
            <ChevronLeft /> Mer
          </button>
        </div>
        <h1 className="font-semibold">Inställningar</h1>
        <div className="flex-1"></div>
      </div>
      <div className="mt-12 md:mt-4 md:mb-4 flex justify-center md:px-3 flex-col md:flex-row md:flex-wrap">
        <Area>
          <AreaHeader>
            <p>Inställningar</p>
            <button className="ml-auto invisible hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full flex items-center">
              <X />
            </button>
          </AreaHeader>
          {data?.me ? (
            <>
              <div className="p-4 md:mb-48">
                <h2 className="mb-4 pb-1 text-lg border-b">Byt namn</h2>
                {updateNameData?.updateName.user ? (
                  <div className="p-2 mb-4 rounded bg-green-200 border border-green-300 text-sm">
                    Du har bytt namn till "
                    {updateNameData.updateName.user.first_name}{' '}
                    {updateNameData.updateName.user.last_name}".
                  </div>
                ) : null}
                {updateNameData?.updateName.errors ? (
                  <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
                    {updateNameData.updateName.errors.map((x, i) => (
                      <p key={i}>{x.message}</p>
                    ))}
                  </div>
                ) : null}
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    updateName({
                      variables: { firstName, lastName },
                    })
                  }}
                  className="md:w-1/2 text-sm mb-4"
                >
                  <label className="block md:flex md:items-center mb-4">
                    <span className="md:w-1/3 md:text-right mb-1 md:mb-0 pr-4 text-gray-800 font-semibold">
                      Förnamn
                    </span>
                    <input
                      className="md:w-2/3 form-input mt-1 block w-full md:w-auto"
                      placeholder="John"
                      value={firstName}
                      onChange={e => setFirstName(e.target.value)}
                    />
                  </label>
                  <label className="block md:flex md:items-center mb-4">
                    <span className="md:w-1/3 md:text-right mb-1 md:mb-0 pr-4 text-gray-800 font-semibold">
                      Efternamn
                    </span>
                    <input
                      className="md:w-2/3 form-input mt-1 block w-full md:w-auto"
                      placeholder="Smith"
                      value={lastName}
                      onChange={e => setLastName(e.target.value)}
                    />
                  </label>
                  <div className="md:flex md:items-center mb-4">
                    <div className="md:w-1/3"></div>
                    <div className="md:w-2/3">
                      <PrimaryButton loading={updateNameLoading}>
                        Byt namn
                      </PrimaryButton>
                    </div>
                  </div>
                </form>

                <h2 className="mb-4 pb-1 text-lg border-b">Byt e-postadress</h2>
                {updateEmailData?.updateEmail.user ? (
                  <div className="p-2 mb-4 rounded bg-green-200 border border-green-300 text-sm">
                    Du har bytt e-postadress till "
                    {updateEmailData.updateEmail.user.email}".
                  </div>
                ) : null}
                {updateEmailData?.updateEmail.errors ? (
                  <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
                    {updateEmailData.updateEmail.errors.map((x, i) => (
                      <p key={i}>{x.message}</p>
                    ))}
                  </div>
                ) : null}
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    updateEmail({
                      variables: { email },
                    })
                  }}
                  className="md:w-1/2 text-sm mb-4"
                >
                  <label className="block md:flex md:items-center mb-4">
                    <span className="md:w-1/3 md:text-right mb-1 md:mb-0 pr-4 text-gray-800 font-semibold">
                      E-postadress
                    </span>
                    <input
                      type="email"
                      className="md:w-2/3 form-input mt-1 block w-full md:w-auto"
                      placeholder="john@example.com"
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                    />
                  </label>
                  <div className="md:flex md:items-center mb-4">
                    <div className="md:w-1/3"></div>
                    <div className="md:w-2/3">
                      <PrimaryButton loading={updateEmailLoading}>
                        Byt e-postadress
                      </PrimaryButton>
                    </div>
                  </div>
                </form>

                <h2 className="mb-4 pb-1 text-lg border-b">Byt lösenord</h2>
                {updatePasswordData?.updatePassword.user ? (
                  <div className="p-2 mb-4 rounded bg-green-200 border border-green-300 text-sm">
                    Du har bytt lösenord.
                  </div>
                ) : null}
                {updatePasswordData?.updatePassword.errors ? (
                  <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
                    {updatePasswordData.updatePassword.errors.map((x, i) => (
                      <p key={i}>{x.message}</p>
                    ))}
                  </div>
                ) : null}
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    updatePassword({
                      variables: {
                        input: {
                          current_password: currentPassword,
                          new_password: newPassword,
                          new_password_confirmation: newPasswordConfirmation,
                        },
                      },
                    }).then(({ data }) => {
                      if (data.updatePassword.user) {
                        ReactDOM.unstable_batchedUpdates(() => {
                          setCurrentPassword('')
                          setNewPassword('')
                          setNewPasswordConfirmation('')
                        })
                      }
                    })
                  }}
                  className="md:w-1/2 text-sm mb-4"
                >
                  <label className="block md:flex md:items-center mb-4">
                    <span className="md:w-1/3 md:text-right mb-1 md:mb-0 pr-4 text-gray-800 font-semibold">
                      Nuvarande lösenord
                    </span>
                    <input
                      type="password"
                      className="md:w-2/3 form-input mt-1 block w-full md:w-auto"
                      placeholder="******"
                      value={currentPassword}
                      onChange={e => setCurrentPassword(e.target.value)}
                    />
                  </label>
                  <label className="block md:flex md:items-center mb-4">
                    <span className="md:w-1/3 md:text-right mb-1 md:mb-0 pr-4 text-gray-800 font-semibold">
                      Nytt lösenord
                    </span>
                    <input
                      type="password"
                      className="md:w-2/3 form-input mt-1 block w-full md:w-auto"
                      placeholder="******"
                      value={newPassword}
                      onChange={e => setNewPassword(e.target.value)}
                    />
                  </label>
                  <label className="block md:flex md:items-center mb-4">
                    <span className="md:w-1/3 md:text-right mb-1 md:mb-0 pr-4 text-gray-800 font-semibold">
                      Upprepa nytt lösenord
                    </span>
                    <input
                      type="password"
                      className="md:w-2/3 form-input mt-1 block w-full md:w-auto"
                      placeholder="******"
                      value={newPasswordConfirmation}
                      onChange={e => setNewPasswordConfirmation(e.target.value)}
                    />
                  </label>
                  <div className="md:flex md:items-center mb-4">
                    <div className="md:w-1/3"></div>
                    <div className="md:w-2/3">
                      <PrimaryButton loading={updatePasswordLoading}>
                        Byt lösenord
                      </PrimaryButton>
                    </div>
                  </div>
                </form>

                <h2 className="mb-4 pb-1 text-lg border-b">Pulszoner</h2>
                <div className="p-2 mb-4 rounded bg-theme-100 border border-theme-200 text-theme-900 text-sm">
                  Dina pulszoner används för att visa tid i respektive
                  intensitet för dina importerade pulsfiler. Lämna tomt för de
                  intensiteter som du inte vill använda.
                </div>
                {updateHeartRateZonesData?.updateHeartRateZones.user ? (
                  <div className="p-2 mb-4 rounded bg-green-200 border border-green-300 text-sm">
                    Du har uppdaterat dina pulszoner.
                  </div>
                ) : null}
                {updateHeartRateZonesData?.updateHeartRateZones.errors ? (
                  <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
                    {updateHeartRateZonesData.updateHeartRateZones.errors.map(
                      (x, i) => (
                        <p key={i}>{x.message}</p>
                      ),
                    )}
                  </div>
                ) : null}
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    updateHeartRateZones({
                      variables: {
                        input: {
                          zones: zones.map(x => {
                            let upper_limit = parseInt(x.upper_limit, 10)
                            let lower_limit = parseInt(x.lower_limit, 10)

                            return {
                              upper_limit,
                              lower_limit,
                              intensity_id: x.intensity.id,
                            }
                          }),
                          import_recordings_as_workouts:
                            importRecordingsAsWorkouts,
                        },
                      },
                    })
                  }}
                  className="md:w-1/2 text-sm mb-4"
                >
                  <label className="hidden md:flex md:items-center mb-2">
                    <div className="md:w-1/3 mb-1 md:mb-0 pr-4 text-gray-800 font-semibold" />
                    <span className="md:w-1/3 mb-1 md:mb-0 text-gray-800 font-semibold">
                      Nedre gräns
                    </span>
                    <span className="md:w-1/3 md:pl-2 mb-1 md:mb-0 text-gray-800 font-semibold">
                      Övre gräns
                    </span>
                  </label>
                  {zones.map((zone, i) => {
                    return (
                      <label
                        key={zone.intensity.id}
                        className="block md:flex md:items-center mb-4"
                      >
                        <span className="md:w-1/3 md:text-right mb-1 md:mb-0 pr-4 text-gray-800 font-semibold">
                          {zone.intensity.name}
                        </span>
                        <div className="md:w-1/3 md:pr-2">
                          <input
                            placeholder="Nedre gräns"
                            className="form-input mt-2 md:mt-1 block w-full"
                            value={zone.lower_limit ?? ''}
                            onChange={e => {
                              let value =
                                e.target.value === '' ? null : e.target.value
                              setZones(
                                produce(x => {
                                  x[i].lower_limit = value
                                }),
                              )
                            }}
                          />
                        </div>
                        <div className="md:w-1/3 md:pl-2">
                          <input
                            placeholder="Övre gräns"
                            className="form-input mt-2 md:mt-1 block w-full"
                            value={zone.upper_limit ?? ''}
                            onChange={e => {
                              let value =
                                e.target.value === '' ? null : e.target.value
                              setZones(
                                produce(x => {
                                  x[i].upper_limit = value
                                }),
                              )
                            }}
                          />
                        </div>
                      </label>
                    )
                  })}
                  <div className="md:flex md:items-center mb-2">
                    <div className="md:w-1/3 mb-1 md:mb-0" />
                    <div className="md:w-2/3 mb-1 md:mb-0">
                      <label className="inline-flex items-center mb-2">
                        <input
                          type="checkbox"
                          className="form-checkbox"
                          checked={importRecordingsAsWorkouts}
                          onChange={e =>
                            setImportRecordingsAsWorkouts(e.target.checked)
                          }
                        />
                        <span className="ml-2">
                          Skapa automatiskt träningspass utifrån dina pulszoner
                          när pulsfiler importeras
                        </span>
                      </label>
                      <p className="text-gray-800 mb-2">
                        Detta val rekommenderas endast om du nöjer dig med
                        pulsfilens data och inte vill justera tiden eller
                        bokföra kommentar, form, skytte eller styrka för dessa
                        pass.
                      </p>
                    </div>
                  </div>
                  <div className="md:flex md:items-center mb-4">
                    <div className="md:w-1/3"></div>
                    <div className="md:w-2/3">
                      <PrimaryButton loading={updateHeartRateZonesLoading}>
                        Uppdatera pulszoner
                      </PrimaryButton>
                    </div>
                  </div>
                </form>

                {process.env.REACT_APP_POLAR_CLIENT_ID ? (
                  <div className="mt-8 text-sm">
                    <h2 className="mb-4 pb-1 text-lg border-b">
                      Anslut till Polar
                    </h2>
                    <div className="p-2 mb-4 rounded bg-theme-100 border border-theme-200 text-theme-900 text-sm">
                      <p className="mb-2">
                        Maxpulse kan automatiskt hämta nya pulsfiler från din
                        Polar-klocka. Pulsfilerna hamnar i kalendern och du kan
                        sedan enkelt bokföra dem som träningpass. Det fungerar
                        med alla klockor som synkroniseras med Polars
                        webbtjänster.
                      </p>
                      <p className="mb-2">
                        Endast de pass som du tränar efter att du har anslutit
                        kommer att synkroniseras. För Polar Flow-klockor bokförs
                        de sporter som inte finns i Maxpulse som "Övrig
                        träning". För äldre klockor bokförs allt utom klockans
                        inbyggda sporter "Löpning" och "Cykel" som "Övrig
                        träning". Detta är tyvärr en begränsning från Polars
                        sida.
                      </p>
                      <p>
                        Multisportpass delas upp i flera enskilda pulsfiler
                        eftersom det inte finns något bra sätt för Maxpulse att
                        ta reda på vilka delar som hör ihop. Detta är också en
                        begränsning från Polars sida.
                      </p>
                    </div>

                    {data.me.connected_to_polar ? (
                      <>
                        <p className="mb-4">
                          Du är ansluten till Polar. Det kan ta upp till 10
                          minuter från att du synkroniserat din klocka till dess
                          att träningspassen kommer in i Maxpulse.
                        </p>
                        {disconnectFromPolarData?.disconnectFromPolar.errors ? (
                          <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
                            {disconnectFromPolarData.disconnectFromPolar.errors.map(
                              (x, i) => (
                                <p key={i}>{x.message}</p>
                              ),
                            )}
                          </div>
                        ) : null}
                        <RedButton
                          loading={disconnectFromPolarLoading}
                          onClick={disconnectFromPolar}
                        >
                          Ta bort anslutning
                        </RedButton>
                      </>
                    ) : (
                      <PrimaryButton
                        onClick={() => {
                          window.location.href = `https://flow.polar.com/oauth2/authorization?response_type=code&client_id=${process.env.REACT_APP_POLAR_CLIENT_ID}`
                        }}
                      >
                        Anslut till Polar
                      </PrimaryButton>
                    )}
                  </div>
                ) : null}

                <div className="mt-8 text-sm">
                  <h2 className="mb-4 pb-1 text-lg border-b">
                    Anslut till Garmin
                  </h2>
                  <div className="p-2 mb-4 rounded bg-theme-100 border border-theme-200 text-theme-900 text-sm">
                    <p className="_mb-2">
                      Maxpulse kan automatiskt hämta nya pulsfiler från din
                      Garmin-klocka. Pulsfilerna hamnar i kalendern och du kan
                      sedan enkelt bokföra dem som träningpass. Det fungerar med
                      alla klockor som synkroniseras med Garmins webbtjänster.
                    </p>
                    <p className="mb-2">
                      Endast de pass som du tränar efter att du har anslutit
                      kommer att synkroniseras. De sporter som inte finns i
                      Maxpulse bokförs som "Övrig träning".
                    </p>
                  </div>

                  {data.me.connected_to_garmin ? (
                    <>
                      <p className="mb-4">
                        Du är ansluten till Garmin. Det kan ta upp till 10
                        minuter från att du synkroniserat din klocka till dess
                        att träningspassen kommer in i Maxpulse.
                      </p>
                      {disconnectFromGarminData?.disconnectFromGarmin.errors ? (
                        <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
                          {disconnectFromGarminData.disconnectFromGarmin.errors.map(
                            (x, i) => (
                              <p key={i}>{x.message}</p>
                            ),
                          )}
                        </div>
                      ) : null}
                      <RedButton
                        loading={disconnectFromGarminLoading}
                        onClick={disconnectFromGarmin}
                      >
                        Ta bort anslutning
                      </RedButton>
                    </>
                  ) : (
                    <PrimaryButton
                      onClick={() => {
                        window.location.href = `${process.env.REACT_APP_ROOT_URL}/garmin/oauth/redirect`
                      }}
                    >
                      Anslut till Garmin
                    </PrimaryButton>
                  )}
                </div>
              </div>
            </>
          ) : null}
        </Area>
      </div>
      {children}
    </>
  )
}

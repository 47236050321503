import React, { useLayoutEffect } from 'react'
import classNames from 'classnames'
import { Modes } from '../../Utilities'
import {
  SEASONS_PER_PAGE,
  isCurrentSeason,
} from '../../Periods/PeriodUtilities'
import { biathlonPeriod } from '../../Periods/MultiWeekPeriod'
const { season, endOfSeason, addSeasons, subSeasons } = biathlonPeriod

export function AllSeasons({ date, area, onNavigate }) {
  // We'll calculate a list of seasons, ending in the current
  // season, paged by SEASONS_PER_PAGE
  let seasons = []
  let dateSeason = season(date)
  let seasonRemainder = (season(new Date()) - dateSeason) % SEASONS_PER_PAGE
  seasonRemainder =
    (seasonRemainder > 0
      ? seasonRemainder
      : SEASONS_PER_PAGE + seasonRemainder) % SEASONS_PER_PAGE

  let start = subSeasons(date, SEASONS_PER_PAGE - seasonRemainder - 1)
  let end = endOfSeason(addSeasons(date, seasonRemainder))

  // Create a list of seasons.
  for (let current = start; current <= end; current = addSeasons(current, 1)) {
    seasons.push({ date: current })
  }

  // Use gray background on mobile.
  useLayoutEffect(() => {
    document.body.classList.add('bg-gray-100')

    return () => document.body.classList.remove('bg-gray-100')
  }, [])

  return (
    <div className="flex-1 bg-gray-100">
      <div
        className="grid gap-4 p-4"
        style={{ gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr)' }}
      >
        {seasons.map(({ date }) => (
          <button
            className={classNames(
              'text-left md:text-center rounded shadow bg-white p-4 md:p-8 hover:bg-gray-200 active:bg-gray-300',
              {
                'border border-theme-500': isCurrentSeason(date),
              },
            )}
            onClick={() => onNavigate(date, Modes.Season)}
            key={date.valueOf()}
          >
            <span className="text-xl">{season(date)}</span>
            <span className="text-sm text-gray-500">/{season(date) + 1}</span>
          </button>
        ))}
      </div>
    </div>
  )
}

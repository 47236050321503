import useSWR from 'swr'
import { gql, useQuery } from '@apollo/client'
import { Summary } from '../Components/Calendar/Summary'

export function _useCategories({ skip } = {}) {
  let { data } = useSWR(skip ? null : '/calendar/global')
  return { ...data, loading: !data }
}

// Only the intensities are used right now when doing
// GraphQL - that's for the calendar stamina summary.
// The swr approach needs all categories since it
// combines sports, intensities etc. with workouts
// in useCalendarPeriod().
export function useCategories({ skip } = {}) {
  let { data, loading } = useQuery(
    gql`
      {
        intensities {
          ...Summary_intensities
        }
      }
      ${Summary.fragments.intensities}
    `,
    { skip },
  )

  return { intensities: data?.intensities ?? [], loading }
}

import { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import { useNavigate } from '@reach/router'
import { cache, mutate } from 'swr'
import { client } from '../Api/apollo'
import { cookie } from '../Utilities'
import create from 'zustand'

export const AuthState = {
  UNKNOWN: 'unknown',
  SIGNED_IN: 'signed_in',
  SIGNED_OUT: 'signed_out',
}

export const [useAuthStore, authStoreApi] = create((set, get) => {
  return {
    authState: AuthState.UNKNOWN,
    setAuthState: authState => set({ authState }),
  }
})

export async function resetAuth() {
  client.stop()
  await client.clearStore()
  cache.clear()
  // TODO Stop in-flight swr requests. Clicking "logout" immediately after focus
  // causes refetch of /me to be done just before logout, meaning the user will
  // appear to be signed in again when the request completes.
  // Reproduce: Open logout poput, focus devtools, wait, click logout without
  // clicking anywhere else first.
  // A workaround is to mutate /me to null - since cache.clear() doesn't seem to cut it.
  // Not sure it handles all cases though.
  mutate('/me', null, false)
  authStoreApi.getState().setAuthState(AuthState.SIGNED_OUT)
}

export function useAuth() {
  let client = useApolloClient()
  let navigate = useNavigate()

  let setLoggedIn = useCallback(async () => {
    await client.clearStore()
    authStoreApi.getState().setAuthState(AuthState.SIGNED_IN)
    mutate('/me')
  }, [client])

  let login = useCallback(
    async (email, password) => {
      await fetch(process.env.REACT_APP_ROOT_URL + '/sanctum/csrf-cookie', {
        credentials: 'include',
      })

      let response = await fetch(process.env.REACT_APP_API_URL + '/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-XSRF-TOKEN': cookie('XSRF-TOKEN'),
        },
        body: JSON.stringify({
          email,
          password,
        }),
        credentials: 'include',
      })

      if (response.ok) {
        await client.clearStore()
        authStoreApi.getState().setAuthState(AuthState.SIGNED_IN)
        mutate('/me')
      }

      return response
    },
    [client],
  )

  let logout = useCallback(async () => {
    await fetch(process.env.REACT_APP_API_URL + '/logout', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-XSRF-TOKEN': cookie('XSRF-TOKEN'),
      },
      credentials: 'include',
    })

    await resetAuth()

    navigate('/')
  }, [navigate])

  return { login, logout, setLoggedIn }
}

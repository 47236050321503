import React from 'react'
import {
  formatSeconds,
  startsWith,
  percent,
  biathlonStats,
  isOptimistic,
} from '../Utilities'
import { gql } from '@apollo/client'
import classNames from 'classnames'
import Rating from './Rating'
import {
  Edit3,
  AlertCircle,
  Clock,
  TrendingUp,
  GitCommit,
  Heart,
  Zap,
  Target,
  ArrowRight,
  Home,
} from 'react-feather'
import { HoverPopover } from './Popover'
import { Draggable } from './DragDrop'
import { HeartRateChart } from './HeartRateChart'
import { stringify } from 'query-string'
import { Link } from '@reach/router'
import { WorkoutRecordingSampleDetails } from './WorkoutRecordingDetails'

Workout.fragments = {
  workout: gql`
    fragment Workout_workout on Workout {
      writeable
      date
      planned
      shape
      comment
      coach_comment
      rest_day
      analysis
      workout_recordings {
        id
        average_heart_rate
        calories
        samples {
          heart_rate(max_points: 150)
        }
      }
      stamina_activities {
        id
        duration
        distance
        ascent
        sport {
          id
          name
          alias
        }
        intensity {
          id
          name
          alias
          color
          level
        }
      }
      biathlon_activities {
        id
        prone_score
        prone_max_score
        standing_score
        standing_max_score
        duration
        biathlon_type {
          id
          name
          alias
          category
        }
      }
      strength_activities {
        id
        duration
        strength_type {
          id
          name
          alias
        }
      }
      explosivity_activities {
        id
        count
        explosivity_type {
          id
          name
          alias
        }
      }
    }
  `,
}

export function DraggableWorkout({ onClick, ...rest }) {
  // The workout is not draggable until it is saved and has an id.
  if (isOptimistic(rest.workout)) {
    return <Workout {...rest} onClick={onClick} />
  }

  return (
    <Draggable
      data={{ type: 'workout', workout: rest.workout, area: rest.area }}
      render={(props, { dragging }) => (
        <div {...props} style={{ opacity: dragging ? 0.5 : 1 }}>
          <WorkoutPopover popover={<WorkoutSummary workout={rest.workout} />}>
            <Workout {...rest} onClick={dragging ? undefined : onClick} />
          </WorkoutPopover>
        </div>
      )}
      preview={props => (
        <div style={{ ...props.style, opacity: 0.7 }}>
          <Workout {...rest} dragging={true} />
        </div>
      )}
    />
  )
}

export function WorkoutPopover({ children, popover }) {
  return (
    <HoverPopover
      placement="bottom-start"
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: [
              'bottom-start',
              'top-start',
              'right-end',
              'left-end',
            ],
          },
        },
        {
          name: 'preventOverflow',
          options: { boundary: 'viewport' },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ]}
      trigger={children}
    >
      <div
        style={{ width: 400 }}
        className="bg-white rounded shadow-popover overflow-hidden"
      >
        {popover}
      </div>
    </HoverPopover>
  )
}

export function Workout({ workout, onClick, dragging = false }) {
  let toughest = workout.stamina_activities
    .filter(x => x.intensity)
    .sort((a, b) => b.intensity.level - a.intensity.level)[0]

  let color = toughest && toughest.intensity.color

  let label = [
    ...new Set(
      workout.stamina_activities
        .filter(x => x.sport)
        .map(x => x.sport.alias)
        .filter(x => x),
    ),
  ].join('/')

  let duration = workout.stamina_activities.reduce(
    (prev, curr) => prev + curr.duration,
    0,
  )

  let shots = workout.biathlon_activities
    // .filter(x => !x.biathlon_type)
    .reduce((prev, curr) => {
      let shots = curr.prone_max_score + curr.standing_max_score

      if (curr.biathlon_type?.category === 'points') {
        shots /= 10
      }

      return prev + shots
    }, 0)

  let biathlonDuration = workout.biathlon_activities.reduce(
    (prev, curr) => prev + curr.duration,
    0,
  )

  let biathlonLabel = [
    ...new Set(
      workout.biathlon_activities
        .filter(x => x.biathlon_type)
        .map(x =>
          x.biathlon_type.category === 'combination'
            ? 'Komb'
            : x.biathlon_type.alias,
        )
        .filter(x => x),
    ),
  ].join('/')

  let strengthDuration = workout.strength_activities.reduce(
    (prev, curr) => prev + curr.duration,
    0,
  )

  let strengthLabel = [
    ...new Set(
      workout.strength_activities
        .filter(x => x.strength_type)
        .map(x => x.strength_type.alias)
        .filter(x => x),
    ),
  ].join('/')

  let explosivityCount = workout.explosivity_activities.some(
    x => x.count !== null,
  )
    ? workout.explosivity_activities.reduce(
        (prev, curr) => prev + curr.count,
        0,
      )
    : null

  let explosivityLabel = [
    ...new Set(
      workout.explosivity_activities
        .filter(x => x.explosivity_type)
        .map(x => x.explosivity_type.alias)
        .filter(x => x),
    ),
  ].join('/')

  function renderPart(color, first, second) {
    return (
      <div
        className="flex-1 flex items-center px-2 py-1.5"
        style={{ borderLeftColor: color, borderLeftWidth: 6 }}
      >
        <span className="mr-auto font-semibold antialiased truncate">
          {first}
        </span>
        {second && <span className="ml-2 md:ml-4 antialiased">{second}</span>}
      </div>
    )
  }

  function renderIconPart(icon, label) {
    return (
      <div
        className="flex-1 flex items-center px-2 py-1.5"
        style={{ borderLeftWidth: 6 }}
      >
        <div className="mr-auto antialiased">{icon}</div>
        {label && <span className="ml-2 md:ml-4 antialiased">{label}</span>}
      </div>
    )
  }

  let optimistic = isOptimistic(workout)

  return (
    <button
      // We wont allow interacting with a workout before it's saved.
      onClick={optimistic ? undefined : onClick}
      className={classNames(
        'draggable-workout w-full min-h-8 rounded leading-none flex flex-col items-stretch bg-gray-100 touching:bg-gray-200 text-gray-800 text-sm shadow-workout focus:outline-none',
        {
          'cursor-default': dragging || optimistic,
          'opacity-50': optimistic,
        },
      )}
    >
      {workout.analysis
        ? renderPart('#ee2299', <Zap size={16} className="" />, 'Analys')
        : null}
      {workout.stamina_activities.length
        ? renderPart(color, label, formatSeconds(duration))
        : null}
      {workout.biathlon_activities.length
        ? renderPart(
            '#3182ce',
            biathlonLabel,
            shots > 0
              ? shots
              : biathlonDuration > 0
              ? formatSeconds(biathlonDuration)
              : null,
          )
        : null}
      {workout.strength_activities.length
        ? renderPart(
            '#571b3c',
            strengthLabel,
            strengthDuration > 0 && formatSeconds(strengthDuration),
          )
        : null}
      {workout.explosivity_activities.length
        ? renderPart('#f26157', explosivityLabel, explosivityCount)
        : null}
      {!workout.stamina_activities.length &&
      !workout.biathlon_activities.length &&
      !workout.strength_activities.length &&
      !workout.explosivity_activities.length &&
      !workout.analysis
        ? workout.rest_day
          ? renderIconPart(<Home size={18} className="-mr-2" />, 'Vila')
          : workout.comment
          ? renderIconPart(<Edit3 size={18} className="-mr-2" />, 'Notering')
          : renderIconPart(
              <AlertCircle size={18} className="-mr-2" />,
              'Ingen aktivitet',
            )
        : null}
    </button>
  )
}

export function WorkoutSummary({ workout, onComplete, mobile = false }) {
  // TODO Maybe pass mobile/desktop popover flag as prop instead of
  // relying on media queries.

  let stamina = {
    duration: workout.stamina_activities.reduce(
      (prev, curr) => prev + curr.duration,
      0,
    ),
    distance: workout.stamina_activities.reduce(
      (prev, curr) => prev + curr.distance,
      0,
    ),
    ascent: workout.stamina_activities.reduce(
      (prev, curr) => prev + curr.ascent,
      0,
    ),
  }

  let biathlon = biathlonStats(workout.biathlon_activities)

  let noActivity =
    workout.stamina_activities.length === 0 &&
    workout.biathlon_activities.length === 0 &&
    workout.strength_activities.length === 0 &&
    workout.explosivity_activities.length === 0 &&
    !workout.comment &&
    !workout.rest_day &&
    !workout.analysis

  return (
    <div className="md:text-sm">
      <p className="hidden md:block py-1.5 px-4 border-b-2 border-theme-600">
        <span className="mr-1 text-base">{workout.date}</span>
        <span className="text-xs">
          {workout.planned ? 'Planerat' : 'Utfört'} träningspass
        </span>
      </p>
      <div className="divide-y">
        <p className="md:hidden px-4 py-3">{workout.date}</p>
        {noActivity ? (
          <p className="px-4 py-3 md:py-2">Ingen aktivitet</p>
        ) : null}
        {workout.rest_day ? <p className="px-4 py-3 md:py-2">Vila</p> : null}
        {workout.analysis ? <p className="px-4 py-3 md:py-2">Analys</p> : null}
        {workout.shape && !workout.planned ? (
          <div className="flex items-center px-4 py-3 md:py-2">
            <p className="mr-auto">Form</p>
            <Rating stars={5} value={workout.shape} disabled />
          </div>
        ) : null}
        {workout.comment ? (
          <p className="whitespace-pre-wrap px-4 py-3 md:py-2">
            {workout.comment.split(/(#[\wåäöÅÄÖ]+)/).map((part, i) =>
              startsWith(part, '#') ? (
                <Link
                  className="text-theme-600"
                  key={i}
                  to={`/search?${stringify({ search: part })}`}
                >
                  {part}
                </Link>
              ) : (
                <React.Fragment key={i}>{part}</React.Fragment>
              ),
            )}
          </p>
        ) : null}
        {workout.coach_comment ? (
          <div>
            <div className="whitespace-pre-wrap px-4 py-3 md:py-2">
              <p className="block text-xs font-medium">Tränarkommentar</p>
              <p className="">{workout.coach_comment}</p>
            </div>
          </div>
        ) : null}
        {workout.workout_recordings.map(recording =>
          recording.samples.heart_rate.length ? (
            <div key={recording.id}>
              <div className="flex text-base leading-tight bg-gray-100 border-b border-gray-200 px-4 py-2 text-gray-800">
                <div className="flex flex-1 items-center justify-start">
                  <Heart size={16} className="mr-2" />
                  <span>{recording.average_heart_rate ?? '-'}</span>
                </div>
                <div className="flex flex-1 items-center justify-end">
                  <Zap size={16} className="mr-2" />
                  <span>{recording.calories ?? '-'}</span>
                </div>
              </div>
              <div className="p-2">
                {mobile ? (
                  <WorkoutRecordingSampleDetails id={recording.id} />
                ) : (
                  <HeartRateChart recording={recording} />
                )}
              </div>
            </div>
          ) : null,
        )}
        {workout.stamina_activities.length ? (
          <div>
            <div className="flex text-base leading-tight bg-gray-100 border-b border-gray-200 px-4 py-2 text-gray-800">
              <div className="flex flex-1 items-center justify-start">
                <span>Kondition</span>
              </div>
              <div className="flex flex-1 items-center justify-end">
                <Clock size={16} className="mr-2" />
                <span>{formatSeconds(stamina.duration)}</span>
              </div>
              <div className="flex flex-1 items-center justify-end">
                <GitCommit size={16} className="mr-2" />
                <span>{stamina.distance} km</span>
              </div>
              <div className="flex flex-1 items-center justify-end">
                <TrendingUp size={16} className="mr-2" />
                <span>{stamina.ascent} m</span>
              </div>
            </div>

            <div className="px-2">
              <table className="w-full my-2 md:my-1">
                <tbody>
                  {workout.stamina_activities.map(activity => (
                    <tr key={activity.id}>
                      <td className="px-2 py-1">
                        {activity.sport?.name ?? 'Ingen sport'}
                      </td>
                      <td className="px-2 py-1">
                        <div className="flex items-center">
                          {activity.intensity ? (
                            <>
                              <div
                                className="rounded-full w-2 h-2 mr-2"
                                style={{
                                  backgroundColor:
                                    activity.intensity &&
                                    activity.intensity.color,
                                }}
                              />
                              <span>{activity.intensity.alias}</span>
                            </>
                          ) : (
                            'Ingen intensitet'
                          )}
                        </div>
                      </td>
                      <td className="px-2 py-1">
                        {formatSeconds(activity.duration)}
                      </td>
                      <td className="px-2 py-1">
                        {activity.distance ? `${activity.distance} km` : null}
                      </td>
                      <td className="px-2 py-1">
                        {activity.ascent ? `${activity.ascent} m` : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        {workout.biathlon_activities.length ? (
          <div>
            <div className="flex text-base leading-tight bg-gray-100 border-b border-gray-200 px-4 py-2 text-gray-800">
              <div className="flex flex-1 items-center justify-start">
                <span>Skytte</span>
              </div>
              <div className="flex flex-1 items-center justify-end">
                <Target size={16} className="mr-2" />
                <span>
                  L {biathlon.prone}, S {biathlon.standing}
                </span>
              </div>
            </div>
            <div className="px-2">
              <table className="w-full my-2 md:my-1">
                <thead>
                  <tr>
                    <th></th>
                    <th className="font-normal text-left px-2 py-1">Ligg</th>
                    <th className="font-normal text-left px-2 py-1">Stå</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {workout.biathlon_activities.map(activity => {
                    let points = activity.biathlon_type?.category === 'points'

                    return (
                      <tr key={activity.id}>
                        <td className="px-2 py-1">
                          {activity.biathlon_type?.name ?? 'Ingen skyttetyp'}
                        </td>
                        <td className="px-2 py-1">
                          {activity.prone_score !== null ? (
                            <>
                              <span className="mr-1">
                                {points
                                  ? `${activity.prone_score}p / ${
                                      activity.prone_max_score / 10
                                    }`
                                  : `${activity.prone_score} / ${activity.prone_max_score}`}
                              </span>
                              {!points ? (
                                <span className="text-xs">
                                  (
                                  {percent(
                                    activity.prone_score,
                                    activity.prone_max_score,
                                  )}
                                  )
                                </span>
                              ) : null}
                            </>
                          ) : (
                            <span>
                              {points
                                ? activity.prone_max_score / 10
                                : activity.prone_max_score}
                            </span>
                          )}
                        </td>
                        <td className="px-2 py-1">
                          {activity.standing_score !== null ? (
                            <>
                              <span className="mr-1">
                                {points
                                  ? `${activity.standing_score}p / ${
                                      activity.standing_max_score / 10
                                    }`
                                  : `${activity.standing_score} / ${activity.standing_max_score}`}
                              </span>
                              {!points ? (
                                <span className="text-xs">
                                  (
                                  {percent(
                                    activity.standing_score,
                                    activity.standing_max_score,
                                  )}
                                  )
                                </span>
                              ) : null}
                            </>
                          ) : (
                            <span>
                              {points
                                ? activity.standing_max_score / 10
                                : activity.standing_max_score}
                            </span>
                          )}
                        </td>
                        <td className="px-2 py-1">
                          {formatSeconds(activity.duration)}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        {workout.strength_activities.length ? (
          <div>
            <div className="flex text-base leading-tight bg-gray-100 border-b border-gray-200 px-4 py-2 text-gray-800">
              <div className="flex flex-1 items-center justify-start">
                <span>Styrka</span>
              </div>
            </div>
            <div className="px-2">
              <table className="w-full my-2 md:my-1">
                <tbody>
                  {workout.strength_activities.map(activity => (
                    <tr key={activity.id}>
                      <td className="px-2 py-1">
                        {activity.strength_type?.name ?? 'Ingen styrketyp'}
                      </td>
                      <td className="px-2 py-1">
                        {formatSeconds(activity.duration)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        {workout.explosivity_activities.length ? (
          <div>
            <div className="flex text-base leading-tight bg-gray-100 border-b border-gray-200 px-4 py-2 text-gray-800">
              <div className="flex flex-1 items-center justify-start">
                <span>Explosivitet</span>
              </div>
            </div>
            <div className="px-2">
              <table className="w-full my-2 md:my-1">
                <tbody>
                  {workout.explosivity_activities.map(activity => (
                    <tr key={activity.id}>
                      <td className="px-2 py-1">
                        {activity.explosivity_type?.name ?? 'Ingen övning'}
                      </td>
                      <td className="px-2 py-1">{activity.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : null}
        {onComplete ? (
          <div className="p-4 md:hidden">
            <button
              className="flex items-center w-full text-white bg-green-600 rounded px-3 py-2 hover:bg-green-700 active:bg-green-800 disabled:bg-green-500"
              onClick={onComplete}
            >
              <span>Utför träningspass</span>
              <ArrowRight size={18} className="ml-auto" />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  )
}

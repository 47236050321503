import React, { useEffect, useRef } from 'react'
import { useMainStore } from '../Stores/MainStore'

export default function EqualHeight({ name, children, render }) {
  let observer = useMainStore(state => state.resizeObserver)
  let addHeightNode = useMainStore(state => state.addHeightNode)
  let removeHeightNode = useMainStore(state => state.removeHeightNode)

  let height = useMainStore(state => state.heights[name])
  let ref = useRef()

  useEffect(() => {
    let node = ref.current
    addHeightNode(name, node)
    observer.observe(node)

    return () => {
      observer.unobserve(node)
      removeHeightNode(name, node)
    }
  }, [addHeightNode, name, observer, removeHeightNode])

  // The outer div sets the height as the max value of each row,
  // while the inner div detects changes to the height.
  return (
    <div style={{ height }}>
      <div ref={ref} data-name={name}>
        {children}
      </div>
    </div>
  )
}

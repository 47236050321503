import React from 'react'
import { formatSeconds } from '../Utilities'
import { Chart } from './Chart'

export function HeartRateChart({ recording, height = 150 }) {
  return (
    <Chart
      height={height}
      title="Timmar per säsong"
      type="line"
      data={{
        datasets: [
          {
            fill: false,
            label: 'Puls',
            data: recording.samples.heart_rate.map(([x, y]) => ({
              x,
              y,
            })),
            pointRadius: 0,
            borderColor: '#F56565',
            borderWidth: 2,
            borderCapStyle: 'round',
          },
        ],
      }}
      options={{
        legend: { display: false },
        animation: { duration: 0 },
        tooltips: {
          // mode: 'interpolate',
          // intersect: false,
          callbacks: {
            title: function (item) {
              return formatSeconds(item[0].xLabel, true)
            },
          },
        },
        plugins: {
          crosshair: {
            line: {
              color: 'rgba(0, 0, 0, .3)',
              width: 3,
            },
            // sync: {
            //   enabled: true, // enable trace line syncing with other charts
            //   group: 1, // chart group
            //   suppressTooltips: false, // suppress tooltips when showing a synced tracer
            // },
            zoom: {
              enabled: true,
              zoomboxBackgroundColor: 'rgba(66,133,244,0.2)',
              zoomboxBorderColor: '#48F',
              zoomButtonText: 'Zooma ut',
              zoomButtonClass:
                'absolute text-sm text-gray-800 rounded shadow leading top-0 right-0 my-4 mr-6 bg-white hover:bg-gray-100 active:bg-gray-200 px-2 py-1',
            },
            // callbacks: {
            //   beforeZoom: function (start, end) {
            //     // called before zoom, return false to prevent zoom
            //     return true
            //   },
            //   afterZoom: function (start, end) {
            //     // called after zoom
            //   },
            // },
          },
        },
        scales: {
          yAxes: [
            {
              ticks: {
                stepSize: 30,
              },
            },
          ],
          xAxes: [
            {
              type: 'linear',
              position: 'bottom',
              ticks: {
                callback: function (value, index, values) {
                  return formatSeconds(value)
                },
                stepSize: 1800,
              },
              gridLines: {
                display: false,
              },
            },
          ],
        },
      }}
    />
  )
}

import React from 'react'

export function Controls({ field, setField }) {
  return (
    <div className="flex flex-wrap items-center text-sm bg-gray-200 rounded mx-4 mb-8 py-1">
      <label className="mx-2 my-1 inline-flex items-center">
        <input
          type="radio"
          className="form-radio border border-gray-500"
          value="duration"
          checked={field === 'duration'}
          onChange={e => setField(e.target.value)}
        />
        <span className="ml-2">Tid</span>
      </label>
      <label className="mx-2 my-1 inline-flex items-center">
        <input
          type="radio"
          className="form-radio border border-gray-500"
          value="workouts"
          checked={field === 'workouts'}
          onChange={e => setField(e.target.value)}
        />
        <span className="ml-2">Pass</span>
      </label>
    </div>
  )
}

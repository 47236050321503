import React from 'react'
import { formatSeconds, percent, biathlonStats } from '../../Utilities'
import { useHoverToggle } from '../../Hooks'
import { gql } from '@apollo/client'
import classNames from 'classnames'

Summary.fragments = {
  workouts: gql`
    fragment Summary_workouts on Workout {
      id
      planned
      stamina_activities {
        id
        duration
        intensity {
          id
        }
      }
      biathlon_activities {
        id
        prone_max_score
        prone_score
        standing_max_score
        standing_score
        biathlon_type {
          id
          name
          position
          category
        }
      }
      strength_activities {
        id
        strength_type {
          id
          name
          position
        }
      }
    }
  `,

  intensities: gql`
    fragment Summary_intensities on Intensity {
      id
      name
      alias
      color
      level
    }
  `,
}

function uniqueBy(values, callback) {
  let found = new Set()
  let unique = []

  for (let value of values) {
    let id = callback(value)
    if (found.has(id)) {
      continue
    }
    found.add(id)
    unique.push(value)
  }

  return unique
}

export function Summary({ workouts, intensities, showDone, wide = false }) {
  let [showPercent, toggleProps] = useHoverToggle()

  let plannedStaminaTotal = workouts
    .filter(x => x.planned)
    .flatMap(x => x.stamina_activities)
    .reduce((prev, curr) => prev + curr.duration, 0)

  let doneStaminaTotal = workouts
    .filter(x => !x.planned)
    .flatMap(x => x.stamina_activities)
    .reduce((prev, curr) => prev + curr.duration, 0)

  let plannedBiathlonTotal = workouts
    .filter(x => x.planned)
    .map(x => biathlonStats(x.biathlon_activities))
    .reduce((prev, curr) => prev + curr.prone + curr.standing, 0)

  let doneBiathlonTotal = workouts
    .filter(x => !x.planned)
    .map(x => biathlonStats(x.biathlon_activities))
    .reduce((prev, curr) => prev + curr.prone + curr.standing, 0)

  let biathlonTypes = uniqueBy(
    workouts
      .flatMap(x => x.biathlon_activities)
      .map(x => x.biathlon_type)
      .filter(x => x !== null),
    x => x.id,
  ).sort((a, b) => a.position - b.position)

  let plannedStrengthTotal = workouts
    .filter(x => x.planned)
    .filter(x => x.strength_activities.length > 0).length
  let doneStrengthTotal = workouts
    .filter(x => !x.planned)
    .filter(x => x.strength_activities.length > 0).length

  let strengthTypes = uniqueBy(
    workouts
      .flatMap(x => x.strength_activities)
      .map(x => x.strength_type)
      .filter(x => x !== null),
    x => x.id,
  ).sort((a, b) => a.position - b.position)

  return (
    <div
      className={classNames({
        'flex overflow-x-auto space-x-4': wide,
        'space-y-4': !wide,
      })}
    >
      <div className="flex-1 flex-shrink-0" style={{ flexBasis: 250 }}>
        <table className="w-full text-sm text-gray-800" {...toggleProps}>
          <tbody>
            <tr>
              <th className="py-1 px-2 text-left font-semibold">Kondition</th>
              <th className="py-1 px-2 text-right font-semibold">
                ({formatSeconds(plannedStaminaTotal)})
              </th>
              {showDone ? (
                <th className="py-1 px-2 text-right font-semibold">
                  {formatSeconds(doneStaminaTotal)}
                </th>
              ) : null}
            </tr>
            {/* Here we display the duration by intensity (including all intensities). */}
            {intensities
              .slice()
              .sort((a, b) => a.level - b.level)
              .map(intensity => {
                let planned = workouts
                  .filter(x => x.planned)
                  .flatMap(x => x.stamina_activities)
                  .filter(x => x.intensity && x.intensity.id === intensity.id)
                  .reduce((prev, curr) => prev + curr.duration, 0)
                let done = workouts
                  .filter(x => !x.planned)
                  .flatMap(x => x.stamina_activities)
                  .filter(x => x.intensity && x.intensity.id === intensity.id)
                  .reduce((prev, curr) => prev + curr.duration, 0)
                return (
                  <tr className="border-t" key={intensity.id}>
                    <td className="py-1 px-2 text-left">
                      <div className="flex items-center">
                        <span
                          className="w-2 h-2 rounded-full mr-2"
                          style={{ backgroundColor: intensity.color }}
                        />
                        <span>{intensity.alias}</span>
                      </div>
                    </td>
                    <td className="py-1 px-2 text-right">
                      (
                      {showPercent
                        ? percent(planned, plannedStaminaTotal)
                        : formatSeconds(planned)}
                      )
                    </td>
                    {showDone ? (
                      <td className="py-1 px-2 text-right">
                        {showPercent
                          ? percent(done, doneStaminaTotal)
                          : formatSeconds(done)}
                      </td>
                    ) : null}
                  </tr>
                )
              })}
          </tbody>
        </table>
      </div>

      <div className="flex-shrink-0 flex-1" style={{ flexBasis: 250 }}>
        <table className="w-full text-sm text-gray-800">
          <tbody>
            <tr>
              <th className="py-1 px-2 text-left font-semibold">Antal skott</th>
              <th className="py-1 px-2 text-right font-semibold">
                ({plannedBiathlonTotal})
              </th>
              {showDone ? (
                <th className="py-1 px-2 text-right font-semibold">
                  {doneBiathlonTotal}
                </th>
              ) : null}
            </tr>
            {/* Here we display the number of shots by biathlon_type. */}
            {biathlonTypes.map(biathlon_type => {
              let plannedStats = biathlonStats(
                workouts
                  .filter(x => x.planned)
                  .flatMap(x => x.biathlon_activities)
                  .filter(
                    x =>
                      x.biathlon_type &&
                      x.biathlon_type.id === biathlon_type.id,
                  ),
              )
              let planned = plannedStats.prone + plannedStats.standing

              let doneStats = biathlonStats(
                workouts
                  .filter(x => !x.planned)
                  .flatMap(x => x.biathlon_activities)
                  .filter(
                    x =>
                      x.biathlon_type &&
                      x.biathlon_type.id === biathlon_type.id,
                  ),
              )
              let done = doneStats.prone + doneStats.standing

              return (
                <tr className="border-t" key={biathlon_type.id}>
                  <td className="py-1 px-2 text-left">
                    <div className="flex items-center">
                      <span>{biathlon_type.name}</span>
                    </div>
                  </td>
                  <td className="py-1 px-2 text-right">({planned})</td>
                  {showDone ? (
                    <td className="py-1 px-2 text-right">{done}</td>
                  ) : null}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>

      <div className="flex-shrink-0 flex-1" style={{ flexBasis: 250 }}>
        <table className="w-full text-sm text-gray-800">
          <tbody>
            <tr>
              <th className="py-1 px-2 text-left font-semibold">Styrkepass</th>
              <th className="py-1 px-2 text-right font-semibold">
                ({plannedStrengthTotal})
              </th>
              {showDone ? (
                <th className="py-1 px-2 text-right font-semibold">
                  {doneStrengthTotal}
                </th>
              ) : null}
            </tr>
            {/* Here we display the number of workouts by strength_type. */}
            {strengthTypes.map(strength_type => {
              let planned = workouts
                .filter(x => x.planned)
                .filter(
                  x =>
                    x.strength_activities.filter(
                      a =>
                        a.strength_type &&
                        a.strength_type.id === strength_type.id,
                    ).length > 0,
                ).length
              let done = workouts
                .filter(x => !x.planned)
                .filter(
                  x =>
                    x.strength_activities.filter(
                      a =>
                        a.strength_type &&
                        a.strength_type.id === strength_type.id,
                    ).length > 0,
                ).length
              return (
                <tr className="border-t" key={strength_type.id}>
                  <td className="py-1 px-2 text-left">
                    <div className="flex items-center">
                      <span>{strength_type.name}</span>
                    </div>
                  </td>
                  <td className="py-1 px-2 text-right">({planned})</td>
                  {showDone ? (
                    <td className="py-1 px-2 text-right">{done}</td>
                  ) : null}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

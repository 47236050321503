import React from 'react'
import { formatSeconds } from '../../../Utilities'
import { StatisticsTable } from '../StatisticsTable'

let formatters = {
  duration(value) {
    return value ? formatSeconds(value) : '-'
  },
}
function format(field, value) {
  if (formatters[field]) {
    return formatters[field](value)
  }

  return value || '-'
}

export function StrengthTable({
  field,
  title,
  rows,
  strength_types,
  onRowClick,
  tableId,
  showPlanned,
  mainMode,
}) {
  let columns = [
    {
      title: 'Total tid',
      value(row, { mode, field }) {
        return formatSeconds(row.strength[mode].duration)
      },
      total(rows, { mode, field }) {
        return formatSeconds(
          rows.reduce((prev, curr) => prev + curr.strength[mode].duration, 0),
        )
      },
    },
    {
      title: 'Antal pass',
      value(row, { mode, field }) {
        return row.strength[mode].workouts
      },
      total(rows, { mode, field }) {
        return rows.reduce(
          (prev, curr) => prev + curr.strength[mode].workouts,
          0,
        )
      },
    },
  ]

  columns.push(
    ...strength_types.map(strength_type => ({
      title: strength_type.alias,
      tooltip: strength_type.name,
      value(row, { mode, field }) {
        return format(
          field,
          row.strength[mode].strength_types.find(
            x => strength_type.id === x.id?.toString(),
          )?.[field] ?? 0,
        )
      },
      total(rows, { mode, field }) {
        return format(
          field,
          rows
            .flatMap(row => row.strength[mode].strength_types)
            .filter(x => x.id?.toString() === strength_type.id)
            .reduce((prev, curr) => prev + curr[field], 0),
        )
      },
    })),
  )

  return (
    <StatisticsTable
      id={tableId}
      columns={columns}
      title={title}
      rows={rows}
      onRowClick={onRowClick}
      showPlanned={showPlanned}
      mainMode={mainMode}
      field={field}
    />
  )
}

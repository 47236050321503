import React from 'react'
import { Link } from '@reach/router'
import { ReactComponent as Talking } from '../assets/svg/undraw_conversation_h12g.svg'
import { ReactComponent as Mobile } from '../assets/svg/undraw_order_confirmed_1m3v.svg'
import { ReactComponent as Calendar } from '../assets/svg/undraw_personal_notebook_sobb.svg'
import { ReactComponent as Search } from '../assets/svg/undraw_file_searching_duff.svg'
import { ReactComponent as Charts } from '../assets/svg/undraw_visual_data_b1wx.svg'
import { ReactComponent as People } from '../assets/svg/undraw_online_friends_x73e.svg'
import { CheckCircle } from 'react-feather'

function Container({ children }) {
  return (
    <div className="max-w-5xl mx-auto">
      <div className="px-4">{children}</div>
    </div>
  )
}

export default function LandingPage() {
  return (
    <>
      <div className="bg-blue-500 text-white">
        <Container>
          <div className="flex items-center py-4">
            <img
              className="rounded w-8 h-8"
              src="icons/icon-ios-60@3x.png"
              alt="Maxpulse logo"
            />
            <h1 className="text-2xl ml-3">Maxpulse</h1>
            <Link className="ml-auto" to="/calendar">
              Logga in
            </Link>
          </div>
          <p className="text-4xl mt-16 mb-10 max-w-xl font-semibold">
            En träningsdagbok för ungdom, motionär och elit
          </p>
          <p className="text-xl mb-8 max-w-3xl font-thin">
            Med Maxpulse{' '}
            <span className="text-gray-200 font-semibold">
              planerar du och följer upp
            </span>{' '}
            din träning själv eller tillsammans med din tränare. Du kan använda
            en pulsklocka eller bokföra helt manuellt.
          </p>
          <button className="mb-16 py-3 px-6 rounded-full border border-white bg-blue-500 hover:bg-white text-xl text-white hover:text-blue-500">
            Prova Maxpulse gratis i 14 dagar!
          </button>
          <div className="shadow relative z-10">
            <div className="bg-gray-700 rounded-tl rounded-tr flex">
              <div className="rounded-full bg-red-500 w-3 h-3 my-2 ml-3"></div>
              <div className="rounded-full bg-green-500 w-3 h-3 my-2 ml-3"></div>
              <div className="rounded-full bg-yellow-500 w-3 h-3 my-2 ml-3"></div>
            </div>
            <img
              className="w-full rounded-bl rounded-br"
              src="screenshot_2.jpg"
              alt="Screenshot of the calendar in Maxpulse"
            />
          </div>
        </Container>
      </div>
      <div
        className="bg-cover bg-top py-24 text-white"
        style={{
          marginTop: '-5%',
          marginBottom: '-5%',
          WebkitClipPath:
            'polygon(0 0, 100% 3vw, 100% 100%, 0 calc(100% - 3vw))',
          backgroundImage:
            'linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.3)), url("team_sweden_2.jpg")',
        }}
      >
        <div className="h-12"></div>
        <div className="h-64"></div>
        <Container>
          <p className="text-4xl md:text-6xl max-w-xl">
            Gör som{' '}
            <span className="text-gray-200 font-bold">
              Svenska Skidskyttelandslaget
            </span>{' '}
          </p>
          <p className="mt-8 text-xl antialiased leading-relaxed">
            {/* <span className="font-bold">
              Hanna Öberg, Sebastian Samuelsson och de andra stjärnorna
            </span>{' '} */}
            <span className="font-bold">Både tränarna och de aktiva</span> i
            Svenska Skidskyttelandslaget använder Maxpulse dagligen för att
            planera och följa upp träningen.
          </p>
        </Container>
      </div>
      <div className="bg-gray-100 py-32 antialiased">
        <Container>
          <p className="text-4xl mb-12 text-center">Hur fungerar Maxpulse?</p>

          <div className="flex bg-white shadow rounded py-4 px-8 mb-16">
            <div className="flex-1 mr-4 flex flex-col justify-center">
              <p className="text-2xl mb-4">
                Planera och bokför träning i kalendern
              </p>
              <p className="text-lg">
                Kalendern ger dig en överblick över dina träningsprogram, din
                egen planerade träning och din utförda träning.
              </p>
            </div>
            <div className="h-48">
              <Calendar className="w-auto h-full" />
            </div>
          </div>

          <div className="flex bg-white shadow rounded py-4 px-8 mb-16">
            <div className="h-48">
              <Search className="w-auto h-full" />
            </div>
            <div className="flex-1 ml-16 flex flex-col justify-center">
              <p className="text-2xl mb-4">
                Tagga och sök bland utförda träningspass
              </p>
              <p className="text-lg">
                Använd hashtags som{' '}
                <span className="text-blue-600">#tävling</span> och{' '}
                <span className="text-blue-600">#pers</span> i dina
                träningspass, så kan du enkelt hitta tillbaka till dem senare.
              </p>
            </div>
          </div>

          <div className="flex bg-white shadow rounded py-4 px-8 mb-12">
            <div className="flex-1 mr-4 flex flex-col justify-center">
              <p className="text-2xl mb-4">
                Analysera träningen med diagram och tabeller
              </p>
              <p className="text-lg">
                Använd statistikverktygen när du vill gå på djupet och analysera
                din träning i detalj.
              </p>
            </div>
            <div className="h-48">
              <Charts className="w-auto h-full" />
            </div>
          </div>
        </Container>
      </div>
      <div
        style={{
          marginTop: '-5%',
          marginBottom: '-5%',
          WebkitClipPath:
            'polygon(0 0, 100% 3vw, 100% 100%, 0 calc(100% - 3vw))',
        }}
        className="bg-gray-200 pt-32 pb-32 antialiased"
      >
        <Container>
          <p className="text-4xl mb-12 text-center">
            Planera och träna i grupp
          </p>
          <div className="flex items-center mb-24">
            <div className="flex-1">
              <People className="h-48 w-auto mx-auto" />
            </div>
            <p className="flex-1 text-xl ml-16 text-center">
              Med träningsgrupper får tränare möjlighet att dela ut
              träningsprogram till aktiva och följa upp deras träning.
            </p>
          </div>
          <p className="text-4xl mb-12 text-center">
            Samspel mellan aktiva och tränare
          </p>
          <div className="flex items-center mb-24">
            <p className="flex-1 text-xl mr-16 text-center">
              Som tränare kan du planera dina aktivas träning, både i grupp och
              individuellt. Som aktiv kan ta del av träning från flera olika
              tränare, och samtidigt anpassa den för dig själv.
            </p>
            <div className="flex-1">
              <Talking className="h-64 w-auto mx-auto" />
            </div>
          </div>
          <p className="text-4xl mb-12 text-center">Maxpulse i mobilen</p>
          <div className="flex items-center">
            <div className="flex-1">
              <Mobile className="h-64 w-auto mx-auto" />
            </div>
            <p className="flex-1 text-xl ml-16 text-center">
              Ta med dig träningen när du är ute på språng. Maxpulse fungerar
              lika bra på mobilen och paddan som på datorn.
            </p>
          </div>
        </Container>
      </div>
      <div className="bg-gray-100 -text-white py-32">
        <Container>
          <p className="text-5xl mb-6 antialiased">
            Ett abonnemang, alla funktioner
          </p>

          <ul className="text-xl mb-6 antialiased">
            <li className="flex items-center mb-2">
              <CheckCircle className="flex-shrink-0 mr-2" />
              Planera, bokför och analysera din träning
            </li>
            <li className="flex items-center mb-2">
              <CheckCircle className="flex-shrink-0 mr-2" />
              Arbeta tillsammans med tränare
            </li>
            <li className="flex items-center">
              <CheckCircle className="flex-shrink-0 mr-2" />
              Anslut din Polar-klocka
            </li>
          </ul>

          <div className="flex -mx-2 text-black antialiased">
            <div className="flex flex-col mx-2 flex-1 shadow rounded bg-gray-800 p-4 text-blue-200 border-blue-500">
              <p className="text-xl">Personligt konto</p>
              <p className="mb-4">
                När du vill föra träningsdagbok för eget bruk eller i en
                träningsgrupp
              </p>
              <p className="text-4xl mt-auto">79 kr</p>
              <p>per månad</p>
            </div>
            <div className="flex flex-col mx-2 flex-1 shadow rounded bg-white p-4">
              <p className="text-xl">Organisation</p>
              <p className="text-gray-800 mb-4">
                Använd Maxpulse i din organisation och hantera betalning av
                konton för både tränare och aktiva
              </p>
              <p className="text-4xl mt-auto">79 kr</p>
              <p>per användare och månad</p>
            </div>
            {/* <div className="flex flex-col mx-2 flex-1 shadow rounded bg-white p-4">
              <p className="text-xl">Tränare</p>
              <p className="text-gray-800 mb-4">
                Som tränare kan du skapa träningsgrupper där du planerar och
                analyserar dina aktivas träning
              </p>
              <p className="text-4xl mt-auto">199 kr</p>
              <p>per månad</p>
            </div> */}
          </div>
          <div className="flex mt-4 flex-1 _border rounded bg-gray-200 p-4">
            <div>
              <p className="text-xl">Förening</p>
              <p className="mb-2 text-gray-800">
                Använd Maxpulse för ungdomsverksamhet i din förening till ett
                rabatterat pris
                {/* För ungdomar upp till 15 år */}
              </p>
              <p className="text-gray-800">
                <a
                  className="text-blue-500 underline"
                  href="mailto:hello@maxpulse.se"
                >
                  Kontakta oss
                </a>{' '}
                så hjälper vi dig!
                {/* För ungdomar upp till 15 år */}
              </p>
            </div>
            <div className="ml-auto text-right">
              <p className="text-2xl">500 kr</p>
              <p className="text-sm text-gray-800">per användare och år</p>
              <p className="text-xs text-gray-800">(~42 kr/mån)</p>
            </div>
          </div>
          <div className="flex flex-col items-center bg-white shadow px-4 py-10 mt-10">
            <p className="text-xl mb-6">
              Prova gratis i 14 dagar, inga kortuppgifter behövs.
            </p>
            <button className="py-3 px-6 rounded-full border border-blue-500 hover:bg-blue-500 text-xl text-blue-700 hover:text-white">
              Skapa ditt konto nu!
            </button>
          </div>
        </Container>
      </div>
      <footer className="bg-gray-800 pb-16 pt-12 text-gray-300">
        <Container>
          <div className="flex flex-col items-start mb-8">
            <a href="/landing" className="mb-2">
              Maxpulse.se
            </a>
          </div>
        </Container>
      </footer>
    </>
  )
}

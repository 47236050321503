import React, { useEffect, useRef } from 'react'
import ChartJS from 'chart.js'
import 'chartjs-adapter-date-fns'
import 'chartjs-plugin-crosshair'

export function Chart({ className = '', height = 300, ...props }) {
  const container = useRef(null)
  const chart = useRef(null)

  useEffect(() => {
    chart.current = new ChartJS(container.current, {
      ...props,
      options: {
        ...(props.options ?? {}),
        maintainAspectRatio: false,
        tooltips: {
          intersect: false,
          mode: 'index',
          ...(props.options?.tooltips ?? {}),
        },
        plugins: {
          ...(props.options?.plugins ?? {}),
          crosshair: props.options?.plugins?.crosshair ?? false,
        },
      },
    })
    return () => chart.current.destroy()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    chart.current.data.datasets = props.data.datasets
    chart.current.update()
  }, [props.data.datasets])

  return (
    <div className={'relative ' + className} style={{ height }}>
      <canvas
        className="absolute left-0 right-0 top-0 bottom-0"
        ref={container}
      />
    </div>
  )
}

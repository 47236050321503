import React, { useState, useLayoutEffect } from 'react'
import { CornerUpLeft, X, ChevronLeft, List } from 'react-feather'
import { Area } from '../Components/Area'
import { useNavigateQuery } from '../Hooks'
import { format, parseISO } from 'date-fns'
import { useQueryParams } from '../Hooks/useQueryParams'
import { getAreas } from '../Utilities'
import { useCalendarInfo } from '../Api/useCalendarInfo'
import produce from 'immer'
import General from '../Components/Statistics/General'
import Stamina from '../Components/Statistics/Stamina'
import Biathlon from '../Components/Statistics/Biathlon'
import Strength from '../Components/Statistics/Strength'
import { fullSeason } from '../Periods/PeriodUtilities'
import { useLocation } from '@reach/router'
import { biathlonPeriod } from '../Periods/MultiWeekPeriod'
import { Select } from '../Components/Form'
let { season } = biathlonPeriod

export function StatisticsPage({ children }) {
  let location = useLocation()
  let navigateQuery = useNavigateQuery()
  let query = useQueryParams()
  let { areas } = getAreas(query)

  // Scroll to top whenever a query param changes. This will likely
  // have to change later.
  useLayoutEffect(() => {
    window.scrollTo(window.scrollX, 0)
  }, [location.search])

  const formatAreaDate = area => ({
    ...area,
    date: area.date && format(area.date, 'yyyy-MM-dd'),
    statsDate: area.statsDate && format(area.statsDate, 'yyyy-MM-dd'),
  })

  function updateArea(index, { statsDate }) {
    navigateQuery({
      areas: JSON.stringify(
        produce(areas, draft => {
          draft[index].statsDate = statsDate
        }).map(formatAreaDate),
      ),
    })
  }

  function closeArea(index) {
    navigateQuery({
      areas: JSON.stringify(
        areas.filter((_, i) => i !== index).map(formatAreaDate),
      ),
    })
  }

  return (
    <>
      <div className="md:px-5">
        <div className="md:max-w-6xl md:mx-auto">
          <p className="text-sm mx-4 md:mx-2 mt-4 md:mt-6 md:-mb-2 p-2 md:p-0 bg-gray-200 md:bg-none rounded">
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:underline"
              href={`${
                process.env.REACT_APP_ROOT_URL
              }/reports/stamina/time/season/${season(new Date())}/1`}
            >
              Gå till den gamla versionen av statistiken.
            </a>
          </p>
        </div>
      </div>
      <div className="flex justify-center md:px-3 md:my-4 flex-col md:flex-row md:flex-wrap">
        {areas.map((area, index) => {
          let onShowSeason = season =>
            updateArea(index, { statsDate: parseISO(season.start) })

          let onBackToAll = () => updateArea(index, { statsDate: undefined })

          return (
            <StatisticsArea
              key={area.key}
              area={area}
              onClose={() => closeArea(index)}
              onShowSeason={onShowSeason}
              onBackToAll={onBackToAll}
              date={area.statsDate}
            />
          )
        })}
      </div>
      {children}
    </>
  )
}

// TODO These should be derived from the biathlonPeriod instead of having
// a hard coded 14.
const dateStartOptions = Array(14)
  .fill()
  .map((_, i) => ({ key: `period_${i + 1}`, type: 'period', value: i + 1 }))

const dateEndOptions = dateStartOptions.concat({
  key: 'today',
  type: 'today',
  value: undefined,
})

function StatisticsArea({ area, date, onClose, onShowSeason, onBackToAll }) {
  let { title } = useCalendarInfo(area)
  let [start, setStart] = useState(dateStartOptions[0])
  let [end, setEnd] = useState(dateEndOptions[13])
  let [statisticsMode, setStatisticsMode] = useState('stamina')
  let [showPlanned, setShowPlanned] = useState(false)
  let [split, setSplit] = useState('periods')

  let Module = {
    general: General,
    stamina: Stamina,
    biathlon: Biathlon,
    strength: Strength,
  }[statisticsMode]

  let periodTitle = date ? `Säsong ${fullSeason(date)}` : 'Alla säsonger'

  function renderBody() {
    return (
      <Module
        start={start}
        end={end}
        showPlanned={showPlanned}
        area={area}
        mainMode={area.type === 'program' ? 'planned' : 'done'}
        onShowSeason={onShowSeason}
        areaTitle={title}
        periodTitle={periodTitle}
        date={date}
        split={split}
      />
    )
  }

  return (
    <Area>
      {/* Not sure if the statistics settings should be in the area header, always on top. */}
      <div className="hidden md:block md:sticky top-nav-height z-10 bg-white-blur shadow md:shadow-none md:bg-white md:border-b-2 border-theme-600 px-4 py-1.5">
        <div className="flex flex-wrap items-center">
          {date ? (
            <button
              className="mr-4 flex items-center rounded bg-theme-500 text-white hover:bg-theme-600 active:bg-theme-700 shadow py-1 px-2 text-sm"
              onClick={onBackToAll}
            >
              <CornerUpLeft size={16} />
              <span className="ml-2">Alla säsonger</span>
            </button>
          ) : null}
          <div className="mr-2">
            <p className="leading-none">
              {title} - {periodTitle}
            </p>
            {area.type === 'program' ? (
              <p className="mt-1.5 text-xs text-gray-800 leading-none">
                Träningsprogram
              </p>
            ) : null}{' '}
          </div>
          <button
            onClick={onClose}
            className="ml-auto hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full flex items-center"
          >
            <X />
          </button>
        </div>
      </div>

      <div className="md:flex space-y-2 md:space-y-0 text-sm bg-gray-200 rounded mx-4 mt-4 mb-2 p-2">
        <div className="flex flex-wrap items-center">
          {area.type === 'user' ? (
            <label className="inline-flex items-center mr-4">
              <input
                type="radio"
                className="form-radio border border-gray-500"
                value="general"
                checked={statisticsMode === 'general'}
                onChange={e => setStatisticsMode(e.target.value)}
              />
              <span className="ml-2">Allmänt</span>
            </label>
          ) : null}
          <label className="inline-flex mr-4 items-center">
            <input
              type="radio"
              className="form-radio border border-gray-500"
              value="stamina"
              checked={statisticsMode === 'stamina'}
              onChange={e => setStatisticsMode(e.target.value)}
            />
            <span className="ml-2">Kondition</span>
          </label>
          <label className="mr-4 inline-flex items-center">
            <input
              type="radio"
              className="form-radio border border-gray-500"
              value="biathlon"
              checked={statisticsMode === 'biathlon'}
              onChange={e => setStatisticsMode(e.target.value)}
            />
            <span className="ml-2">Skytte</span>
          </label>
          <label className="mr-4 inline-flex items-center">
            <input
              type="radio"
              className="form-radio border border-gray-500"
              value="strength"
              checked={statisticsMode === 'strength'}
              onChange={e => setStatisticsMode(e.target.value)}
            />
            <span className="ml-2">Styrka</span>
          </label>
        </div>
        <div className="ml-auto space-x-4 flex items-center">
          {area.type === 'user' ? (
            <label className="inline-flex items-center">
              <input
                type="checkbox"
                className="form-checkbox border border-gray-500"
                checked={showPlanned}
                onChange={e => setShowPlanned(e.target.checked)}
              />
              <span className="ml-2">Visa planerad träning</span>
            </label>
          ) : null}
          {date ? (
            <>
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio border border-gray-500"
                  value="periods"
                  checked={split === 'periods'}
                  onChange={e => setSplit(e.target.value)}
                />
                <span className="ml-2">Perioder</span>
              </label>
              <label className="ml-4 inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio border border-gray-500"
                  value="weeks"
                  checked={split === 'weeks'}
                  onChange={e => setSplit(e.target.value)}
                />
                <span className="ml-2">Veckor</span>
              </label>
            </>
          ) : null}
        </div>
      </div>

      <div className="text-sm bg-gray-200 rounded mx-4 mb-2 p-2">
        <div className="md:flex md:items-center">
          <div className="flex items-center">
            <p>Räkna från</p>
            <div className="ml-auto md:ml-2 md:mr-2">
              <Select
                items={dateStartOptions}
                value={start.key}
                getLabel={x => `Period ${x.value}`}
                getValue={x => x.key}
                onChange={key => {
                  setStart(dateStartOptions.find(x => x.key === key))
                }}
              />
            </div>
          </div>
          <div className="flex items-center mt-2 md:mt-0">
            <p>till och med</p>
            <div className="ml-auto md:ml-2">
              <Select
                items={dateEndOptions}
                value={end.key}
                getLabel={x =>
                  x.type === 'today' ? 'Idag' : `Period ${x.value}`
                }
                getValue={x => x.key}
                onChange={key => {
                  setEnd(dateEndOptions.find(x => x.key === key))
                }}
              />
            </div>
          </div>
        </div>
        <p className="mt-2 text-xs text-gray-700">
          "Till och med idag" räknar med precis så många dagar som har gått
          under nuvarande säsong, så att du kan jämföra hur du ligger till mot
          tidigare säsonger.
          <br />
          För år med 53 veckor har säsongen 14 perioder, där period 14 är en
          vecka lång.
        </p>
      </div>

      {renderBody()}
    </Area>
  )
}

export function MobileStatisticsNavbar() {
  let query = useQueryParams()
  let navigateQuery = useNavigateQuery()
  let { areas } = getAreas(query)

  const formatAreaDate = area => ({
    ...area,
    date: area.date && format(area.date, 'yyyy-MM-dd'),
    statsDate: area.statsDate && format(area.statsDate, 'yyyy-MM-dd'),
  })

  function updateArea(index, { statsDate }) {
    navigateQuery({
      areas: JSON.stringify(
        produce(areas, draft => {
          draft[index].statsDate = statsDate
        }).map(formatAreaDate),
      ),
    })
  }

  let backToAllSeasons = () => updateArea(0, { statsDate: undefined })

  // Only one area can be active at a time on mobile.
  // We'll grab that one to set the navbar title.
  let mobileArea = areas[0]

  let date = mobileArea?.statsDate

  let periodTitle = date ? `Säsong ${fullSeason(date)}` : 'Alla säsonger'

  let { title } = useCalendarInfo(mobileArea, { skip: !mobileArea })

  return (
    <div className="md:hidden border-b">
      <div className="px-4 h-12 flex items-center justify-center">
        {/* Left part */}
        <div className="flex-1 flex">
          {date ? (
            <button
              onClick={() => backToAllSeasons()}
              className="flex items-center touching:opacity-25"
            >
              <ChevronLeft /> Säsonger
            </button>
          ) : null}
        </div>
        <div className="flex flex-col justify-center items-center">
          {title ? (
            <>
              <h2 className="text-sm font-semibold">{title}</h2>
              <p className="text-xs text-gray-800">{periodTitle}</p>
            </>
          ) : null}
        </div>
        {/* Right part */}
        <div className="flex-1 flex justify-end">
          <button
            onClick={() => navigateQuery('/statistics/area-switcher', true)}
            className="items-center touching:opacity-25"
          >
            <List />
          </button>
        </div>
      </div>
    </div>
  )
}

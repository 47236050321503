import React, { useEffect } from 'react'
import {
  MobileOrDesktopNavbar,
  MobileTabBar,
  NavSafeArea,
} from './Components/Nav'
import { LoginPage } from './Pages/LoginPage'
import { PWAInstallHint } from './Components/PWAInstallHint'
import { FixIOSStartupGlitch } from './Components/FixIOSStartupGlitch'
import { useRememberCalendarNavigation } from './Hooks/useRememberCalendarNavigation'
import { setBugsnagUser } from './Utilities/setBugsnagUser'
import { useQuery } from '@apollo/client'
import { GLOBAL_DATA } from './Pages/EditWorkoutPage'
import { useAuthStore, AuthState } from './Utilities/Auth'
import useSWR from 'swr'
import Spinner from './Components/Spinner'

export default function App({ children }) {
  useRememberCalendarNavigation()
  let authState = useAuthStore(state => state.authState)
  let setAuthState = useAuthStore(state => state.setAuthState)

  // We use a simple API call to determine if the user is signed in or not.
  // It's easier to manage than using apollo, and faster. /me is even
  // preloaded in index.html to make it even faster. Right now we're
  // not using this user object for any actual data, that still comes
  // from GraphQL.
  // This hook will revalidate on focus, so the user will be immedieately
  // returned to the login screen when the session has expired.
  let { data } = useSWR('/me', {
    // The focus login check can be useful to toggle off for testing.
    // revalidateOnFocus: false,
  })

  let loading = !data
  let me = data?.me

  useEffect(() => {
    // Include current user in error reporting.
    setBugsnagUser(me)
    // We'll update the authentication state according to the latest
    // api call to /me.
    if (!loading) {
      setAuthState(me ? AuthState.SIGNED_IN : AuthState.SIGNED_OUT)
    }
  }, [me, loading, setAuthState])

  // Preload sports, intensities etc. for use in the workout form.
  // TODO This should probably go in a data loading hook when
  // we refactor to those.
  useQuery(GLOBAL_DATA, { skip: !me })

  // If we don't know the authentication state yet (it comes from the api),
  // we'll simply show nothing until we know, or a spinner, in case
  // loading takes a long time.
  if (authState === AuthState.UNKNOWN) {
    return (
      <div className="delayed-visibility h-screen flex justify-center mt-48 text-xl">
        <Spinner dark />
      </div>
    )
  }

  // If the user is unauthenticated we'll show the login screen,
  // but they will stay on the same url, no redirects. Not sure
  // if this is a good apporach or not.
  if (authState === AuthState.SIGNED_OUT) {
    return (
      <>
        <LoginPage />
        <PWAInstallHint />
      </>
    )
  }

  return (
    <>
      <MobileOrDesktopNavbar />
      {/* The main area is hidden on mobile when a modal shows, since the mobile modals
      are displayed in the document flow and not fixed. This prevents some mobile issues
      regarding scroll, input focus etc. */}
      <div className="modal-hidden md:modal-block">
        <NavSafeArea>{children}</NavSafeArea>
      </div>
      <PWAInstallHint />
      <MobileTabBar />
      <FixIOSStartupGlitch />
    </>
  )
}

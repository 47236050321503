import { useEffect, useReducer, useRef } from 'react'

export function useIsFirstRender() {
  let isFirst = useRef(true)

  // We'll use this effect to detect first mount.
  useEffect(() => {
    isFirst.current = false
  }, [])

  return isFirst
}

export function useIsFirstRenderWithDelay(delay = 0) {
  let isFirst = useRef(true)
  let [, forceUpdate] = useReducer(x => x + 1, 0)

  useEffect(() => {
    if (!isFirst.current) {
      return
    }

    let timeout = setTimeout(() => {
      isFirst.current = false
      forceUpdate()
    }, delay)

    return () => clearTimeout(timeout)
  }, [delay, forceUpdate])

  return isFirst.current
}

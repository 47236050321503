import React from 'react'
import { Modal, ModalHeader, ModalBody } from '../Components/Modal'
import AreaSwitcher from '../Components/AreaSwitcher'
import { parse, stringify } from 'query-string'

export default function AreaSwitcherPage({ navigate, location }) {
  let query = parse(location.search)

  return (
    <Modal>
      <ModalHeader
        title="Träningsprogram"
        left={
          <button
            onClick={() => navigate(`../${location.search}`)}
            className="flex items-center touching:opacity-25"
          >
            Stäng
          </button>
        }
      />
      <ModalBody>
        <AreaSwitcher
          onNewProgramClicked={() =>
            navigate(`../program/new${location.search}`)
          }
          onProgramSettingsClicked={id =>
            navigate(`../program/${id}${location.search}`)
          }
          onAreaClicked={area =>
            navigate(
              `../?${stringify({
                ...query,
                areas: JSON.stringify([area]),
              })}`,
            )
          }
          mobile={true}
        />
      </ModalBody>
    </Modal>
  )
}

import React from 'react'
import { HelpCircle, LogOut, Settings, Users } from 'react-feather'
import { useAuth } from '../Utilities/Auth'

export default function MorePage({ navigate, location }) {
  let { logout } = useAuth()

  function handleLogout() {
    logout()
  }

  return (
    <>
      <div className="md:hidden fixed bg-white top-0 left-0 right-0 z-20 px-4 h-12 flex items-center justify-center border-b">
        <h1 className="font-semibold">Mer</h1>
      </div>
      <div className="mt-12 md:mt-4 md:mb-4 flex justify-center md:px-3 flex-col md:flex-row md:flex-wrap">
        <button
          className="mt-8 flex items-center border-t py-2 px-4 active:bg-gray-200 text-gray-900"
          onClick={() => navigate('/groups')}
        >
          <Users size={18} className="mr-2" />
          <span>Grupper</span>
        </button>
        <button
          className="flex items-center border-t py-2 px-4 active:bg-gray-200 text-gray-900"
          onClick={() => navigate('/settings')}
        >
          <Settings size={18} className="mr-2" />
          <span>Inställningar</span>
        </button>
        <button
          className="flex items-center border-t border-b py-2 px-4 active:bg-gray-200 text-gray-900"
          onClick={() => navigate('/more/help')}
        >
          <HelpCircle size={18} className="mr-2" />
          <span>Hjälp</span>
        </button>
        <button
          className="mt-8 flex items-center border-t border-b py-2 px-4 active:bg-gray-200 text-gray-900"
          onClick={handleLogout}
        >
          <LogOut size={18} className="mr-2" />
          <span>Logga ut</span>
        </button>
        {/* <p className="text-sm mt-4 px-4 text-center text-gray-800">
          Appen är en betaversion och allt är inte färdigt än. Skicka ett mail
          till{' '}
          <a className="text-theme-600" href="mailto:hello@maxpulse.se">
            hello@maxpulse.se
          </a>{' '}
          om du stöter på problem.
        </p> */}
      </div>
    </>
  )
}

// Importing queries here will lead to cyclic dependencies that could cause issues.
import { PERIOD_WORKOUTS } from '../Components/Calendar/PeriodQueries'
// import { WORKOUT_RECORDING_PAGE_WORKOUT_RECORDING_QUERY } from './Pages/WorkoutRecordingPage'
import { parseISO, format } from 'date-fns'
import { biathlonPeriod } from '../Periods/MultiWeekPeriod'
const { startOfPeriod, endOfPeriod } = biathlonPeriod

export function addWorkoutToCache(cache, workout, area) {
  changeWorkoutInCache(cache, workout, area, false)
}

export function removeWorkoutFromCache(cache, workout, area) {
  changeWorkoutInCache(cache, workout, area, true)
}

function changeWorkoutInCache(cache, workout, area, remove) {
  let date = parseISO(workout.date)
  let start = startOfPeriod(date)
  let end = endOfPeriod(date)

  let variables = {
    start: format(start, 'yyyy-MM-dd'),
    end: format(end, 'yyyy-MM-dd'),
    program: area.type === 'program',
    user: area.type === 'user',
    id: area.id,
  }

  let query

  try {
    query = cache.readQuery({
      query: PERIOD_WORKOUTS,
      variables,
    })
  } catch (e) {
    // The query has not been loaded yet so we'll simply return.
    return
  }

  // Since the workout can be added to either a program or
  // the current user, we'll have to add it to the
  // appropriate place in the cache.
  let data =
    area.type === 'program'
      ? {
          program: {
            ...query.program,
            workouts: remove
              ? query.program.workouts.filter(x => x.id !== workout.id)
              : query.program.workouts.concat([workout]),
          },
        }
      : {
          user: {
            ...query.user,
            workouts: remove
              ? query.user.workouts.filter(x => x.id !== workout.id)
              : query.user.workouts.concat([workout]),
          },
        }

  cache.writeQuery({
    query: PERIOD_WORKOUTS,
    variables,
    data,
  })
}

export function addWorkoutRecordingToCache(cache, recording) {
  changeWorkoutRecordingInCache(cache, recording, false)
}

export function removeWorkoutRecordingFromCache(cache, recording) {
  changeWorkoutRecordingInCache(cache, recording, true)
}

function changeWorkoutRecordingInCache(cache, recording, remove) {
  let date = parseISO(recording.date)
  let start = startOfPeriod(date)
  let end = endOfPeriod(date)

  let variables = {
    start: format(start, 'yyyy-MM-dd'),
    end: format(end, 'yyyy-MM-dd'),
    program: false,
    user: true,
    id: 'me',
  }

  // TODO This might fail if the dates have not been queried yet?
  let query = cache.readQuery({
    query: PERIOD_WORKOUTS,
    variables,
  })

  let data = {
    user: {
      ...query.user,
      workout_recordings: remove
        ? query.user.workout_recordings.filter(x => x.id !== recording.id)
        : query.user.workout_recordings.concat([recording]),
    },
  }

  cache.writeQuery({
    query: PERIOD_WORKOUTS,
    variables,
    data,
  })

  // TODO Remove recording from individual 'root queries'. Do the same
  // with workouts. Back button shows deleted result otherwise.
  // query = cache.readQuery({
  //   query: WORKOUT_RECORDING_PAGE_WORKOUT_RECORDING_QUERY,
  //   variables: { id: recording.id },
  // })
  // console.log(query)
}

import React, { useState } from 'react'
import { AllSeasons } from './AllSeasons'
import { Season } from './Season'
import { Controls } from './Controls'

export default function Biathlon({
  start,
  end,
  showPlanned,
  mainMode,
  area,
  onShowSeason,
  areaTitle,
  periodTitle,
  date,
  split,
}) {
  let [field, setField] = useState('total_shots')
  // let [showPercent, setShowPercent] = useState(false)

  return (
    <>
      <Controls field={field} setField={setField} />
      {date ? (
        <Season
          field={field}
          date={date}
          split={split}
          start={start}
          end={end}
          showPlanned={showPlanned}
          mainMode={mainMode}
          area={area}
          areaTitle={areaTitle}
          periodTitle={periodTitle}
        />
      ) : (
        <AllSeasons
          field={field}
          start={start}
          end={end}
          showPlanned={showPlanned}
          mainMode={mainMode}
          area={area}
          onShowSeason={onShowSeason}
          areaTitle={areaTitle}
          periodTitle={periodTitle}
        />
      )}
    </>
  )
}

import React from 'react'
import classNames from 'classnames'

export default function Spinner({ dark, className = '', ...props }) {
  return (
    <div
      {...props}
      className={classNames('Spinner Spinner--spinning', className, {
        'Spinner--dark': dark,
      })}
    />
  )
}

import { useQuery, gql } from '@apollo/client'
import useSWR from 'swr'

export function _useGroups() {
  let { data } = useSWR('/groups')

  return { memberships: data?.memberships }
}

export function useGroups() {
  let { data } = useQuery(
    gql`
      {
        me {
          id
          memberships {
            id
            group {
              id
              name
            }
            roles {
              id
              name
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  )

  return { memberships: data?.me.memberships ?? [] }
}

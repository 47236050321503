import React, { useState, useLayoutEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Components/Modal'
import { X, CheckCircle, Circle } from 'react-feather'
import { useQuery, useMutation, gql } from '@apollo/client'
import produce from 'immer'
import { NumberInput, TimeInput } from '../Components/Form'
import { parseISO, format } from 'date-fns'
import { biathlonPeriod } from '../Periods/MultiWeekPeriod'
import { PERIOD_WORKOUTS } from '../Components/Calendar/PeriodQueries'
import { PrimaryButton, GrayButton, MobileButton } from '../Components/Buttons'
const { startOfPeriod, endOfPeriod } = biathlonPeriod

DailyNotePage.fragments = {
  daily_note: gql`
    fragment DailyNotePage_daily_note on DailyNote {
      id
      writeable
      date
      ill
      travel
      injured
      alternative_training
      menstruation
      weight
      sleep
      heart_rate
    }
  `,
}

const UPDATE_DAILY_NOTE = gql`
  mutation UpdateDailyNote($input: UpdateDailyNoteInput!) {
    updateDailyNote(input: $input) {
      daily_note {
        ...DailyNotePage_daily_note
      }
    }
  }
  ${DailyNotePage.fragments.daily_note}
`

const NOTE_QUERY = gql`
  query Note($id: ID!) {
    daily_note(id: $id) {
      ...DailyNotePage_daily_note
    }
  }

  ${DailyNotePage.fragments.daily_note}
`

function useDraftNote(date, mode, id) {
  // This is the initial draft in case we're
  // creating a new note.
  let [note, setNote] = useState({
    writeable: true,
    date,
    ill: false,
    travel: false,
    injured: false,
    alternative_training: false,
    menstruation: false,
    weight: null,
    sleep: null,
    heart_rate: null,
  })

  // If we're instead editing an existing note,
  // we'll fetch it here and then set our draft
  // to the result later in useLayoutEffect.
  // TODO This queries by date and not id, so it doesn't
  // use the cache.
  let { data } = useQuery(NOTE_QUERY, {
    variables: {
      id,
    },
    skip: mode === 'new',
  })

  // Since the initial data might come in later, when editing or
  // completing, we'll set our workout here.
  useLayoutEffect(() => {
    if (data && data.daily_note) {
      setNote(data.daily_note)
    }
  }, [data, date])

  // If the query still is loading we'll return
  // null as the draft.
  if (!data && mode === 'edit') {
    note = null
  }

  return [note, setNote]
}

function Checkbox({ label, value, onChange, disabled }) {
  return (
    <button
      disabled={disabled}
      onClick={() => onChange(!value)}
      className="flex items-center w-full text-gray-800 bg-gray-200 rounded px-3 py-2 hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-200 disabled:cursor-default"
    >
      <span>{label}</span>
      {value ? (
        <CheckCircle className="text-gray-700 ml-auto" size={18} />
      ) : (
        <Circle className="text-gray-700 ml-auto" size={18} />
      )}
    </button>
  )
}

export function DailyNotePage({ location, navigate, mode, id, date }) {
  const [updateDailyNote, { loading }] = useMutation(UPDATE_DAILY_NOTE)

  let [note, setNote] = useDraftNote(date, mode, id)

  function back() {
    if (mode === 'new') {
      navigate(`../../../${location.search}`)
    } else {
      navigate(`../../${location.search}`)
    }
  }

  function save() {
    updateDailyNote({
      variables: {
        input: {
          date: note.date,
          ill: note.ill,
          travel: note.travel,
          injured: note.injured,
          alternative_training: note.alternative_training,
          menstruation: note.menstruation,
          weight: note.weight,
          sleep: note.sleep,
          heart_rate: note.heart_rate,
        },
      },
      update(cache, { data: { updateDailyNote } }) {
        let noteDate = parseISO(note.date)
        let start = startOfPeriod(noteDate)
        let end = endOfPeriod(noteDate)

        let variables = {
          start: format(start, 'yyyy-MM-dd'),
          end: format(end, 'yyyy-MM-dd'),
          program: false,
          user: true,
          id: 'me',
        }

        let remove = !updateDailyNote.daily_note

        // A daily note is removed if the fields are empty and
        // the server response is null in edit mode.
        // Otherwise it's just updated, so we don't have
        // to modify the cache.
        if (!remove && mode === 'edit') {
          return
        }

        const query = cache.readQuery({
          query: PERIOD_WORKOUTS,
          variables,
        })

        // We'll add or remove the note from the cache.
        let data = {
          user: {
            ...query.user,
            daily_notes: remove
              ? query.user.daily_notes.filter(x => x.id !== note.id)
              : query.user.daily_notes.concat([updateDailyNote.daily_note]),
          },
        }

        cache.writeQuery({
          query: PERIOD_WORKOUTS,
          variables,
          data,
        })
      },
    }).then(res => back())
  }

  function change(updater) {
    setNote(produce(note, updater))
  }

  let disabled = !note?.writeable

  return (
    <Modal width="md:max-w-lg">
      <ModalHeader
        title={`Daglig notering ${note?.date ?? ''}`}
        left={
          <MobileButton disabled={loading} onClick={back} className="md:hidden">
            {note?.writeable ? 'Avbryt' : 'Stäng'}
          </MobileButton>
        }
        right={
          <>
            <button
              disabled={loading}
              onClick={back}
              className="ml-auto hidden md:flex items-center hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full"
            >
              <X />
            </button>
            {note?.writeable ? (
              <MobileButton
                disabled={loading}
                loading={loading}
                onClick={save}
                className="md:hidden"
              >
                Spara
              </MobileButton>
            ) : null}
          </>
        }
      />
      <ModalBody>
        <div className="p-4">
          {note ? (
            <div className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4 text-sm">
              <div className="flex-1 space-y-2">
                <Checkbox
                  label="Sjuk"
                  disabled={disabled}
                  value={note.ill}
                  onChange={value =>
                    change(n => {
                      n.ill = value
                    })
                  }
                />
                <Checkbox
                  label="Resdag"
                  disabled={disabled}
                  value={note.travel}
                  onChange={value =>
                    change(n => {
                      n.travel = value
                    })
                  }
                />
                <Checkbox
                  label="Skadad"
                  disabled={disabled}
                  value={note.injured}
                  onChange={value =>
                    change(n => {
                      n.injured = value
                    })
                  }
                />
                <Checkbox
                  label="Alternativ träning"
                  disabled={disabled}
                  value={note.alternative_training}
                  onChange={value =>
                    change(n => {
                      n.alternative_training = value
                    })
                  }
                />
                <Checkbox
                  label="Menstruation"
                  disabled={disabled}
                  value={note.menstruation}
                  onChange={value =>
                    change(n => {
                      n.menstruation = value
                    })
                  }
                />
              </div>
              <div className="flex-1 space-y-2">
                <div>
                  <p className="pl-1 mb-1">Vikt (kg)</p>
                  <NumberInput
                    placeholder="Vikt"
                    disabled={disabled}
                    value={note.weight}
                    onChange={value =>
                      change(n => {
                        n.weight = value
                      })
                    }
                  />
                </div>
                <div>
                  <p className="pl-1 mb-1">Sömn (h:m)</p>
                  <TimeInput
                    disabled={disabled}
                    value={note.sleep}
                    onChange={value =>
                      change(n => {
                        n.sleep = value || null
                      })
                    }
                  />
                </div>
                <div>
                  <p className="pl-1 mb-1">Vilopuls (slag/min)</p>
                  <NumberInput
                    placeholder="Vilopuls"
                    disabled={disabled}
                    value={note.heart_rate}
                    onChange={value =>
                      change(n => {
                        n.heart_rate = value
                      })
                    }
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        <GrayButton disabled={loading} onClick={back} className="ml-auto">
          {note?.writeable ? 'Avbryt' : 'Stäng'}
        </GrayButton>
        {note?.writeable ? (
          <PrimaryButton
            disabled={loading}
            loading={loading}
            onClick={save}
            className="ml-4"
          >
            Uppdatera daglig notering
          </PrimaryButton>
        ) : null}
      </ModalFooter>
    </Modal>
  )
}

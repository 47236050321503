import format from 'date-fns/format'
import produce from 'immer'
import { stringify } from 'query-string'
import useSWR, { cache, mutate } from 'swr'
import { keyBy } from '../Utilities'

import { biathlonPeriod } from '../Periods/MultiWeekPeriod'
import { useCategories } from './useCategories'
import { useEffect } from 'react'
import { fetcher } from './fetcher'
import { useApolloClient, useQuery } from '@apollo/client'
import { PERIOD_WORKOUTS } from '../Components/Calendar/PeriodQueries'
const { addPeriods, subPeriods, startOfPeriod, endOfPeriod } = biathlonPeriod

export function _useCalendarPeriod(
  date,
  area,
  { skip = false, preload = false },
) {
  let start = format(startOfPeriod(date), 'yyyy-MM-dd')
  let end = format(endOfPeriod(date), 'yyyy-MM-dd')

  let user_id = area.type === 'user' && area.id !== 'me' ? area.id : undefined
  let program_id = area.type === 'program' ? area.id : undefined

  // Preload previous and next period.
  useEffect(() => {
    if (skip || !preload) return

    let previousVariables = stringify({
      user_id,
      program_id,
      start: format(startOfPeriod(subPeriods(date, 1)), 'yyyy-MM-dd'),
      end: format(endOfPeriod(subPeriods(date, 1)), 'yyyy-MM-dd'),
    })

    let nextVariables = stringify({
      user_id,
      program_id,
      start: format(startOfPeriod(addPeriods(date, 1)), 'yyyy-MM-dd'),
      end: format(endOfPeriod(addPeriods(date, 1)), 'yyyy-MM-dd'),
    })

    let previous = `/calendar/workouts?${previousVariables}`
    let next = `/calendar/workouts?${nextVariables}`

    if (!cache.has(previous)) {
      mutate(previous, fetcher(previous))
    }
    if (!cache.has(next)) {
      mutate(next, fetcher(next))
    }
  }, [user_id, program_id, date, preload, skip])

  let variables = stringify({
    user_id,
    program_id,
    start,
    end,
  })

  let { data } = useSWR(skip ? null : `/calendar/workouts?${variables}`)

  let {
    sports,
    intensities,
    biathlon_types,
    strength_types,
    explosivity_types,
    loading: loadingTypes,
  } = useCategories({ skip })

  if (loadingTypes || !data) return { loading: true }

  sports = keyBy(sports, 'id')
  intensities = keyBy(intensities, 'id')
  biathlon_types = keyBy(biathlon_types, 'id')
  strength_types = keyBy(strength_types, 'id')
  explosivity_types = keyBy(explosivity_types, 'id')

  // Map sports, intensities etc. to the workouts.
  let workouts = produce(data.workouts, draft => {
    draft.forEach(workout => {
      workout.stamina_activities.forEach(activity => {
        activity.sport = sports[activity.sport_id] ?? null
        activity.intensity = intensities[activity.intensity_id] ?? null
      })
      workout.biathlon_activities.forEach(activity => {
        activity.biathlon_type =
          biathlon_types[activity.biathlon_type_id] ?? null
      })
      workout.strength_activities.forEach(activity => {
        activity.strength_type =
          strength_types[activity.strength_type_id] ?? null
      })
      workout.explosivity_activities.forEach(activity => {
        activity.explosivity_type =
          explosivity_types[activity.explosivity_type_id] ?? null
      })
    })
  })

  return {
    loading: false,
    workouts,
    workout_recordings: data.workout_recordings,
    daily_notes: data.daily_notes,
  }
}

export function useCalendarPeriod(
  date,
  area,
  { skip = false, preload = false },
) {
  const client = useApolloClient()
  let start = format(startOfPeriod(date), 'yyyy-MM-dd')
  let end = format(endOfPeriod(date), 'yyyy-MM-dd')

  let variables = {
    user: area.type === 'user',
    program: area.type === 'program',
    id: area.id,
  }

  // Here we fetch the data for the selected period.
  // TODO Handle error and loading states.
  let { data, loading } = useQuery(PERIOD_WORKOUTS, {
    variables: {
      ...variables,
      start,
      end,
    },
    // nextFetchPolicy has to be set in order to not perform a refetch
    // immediately after the first fetch.
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  // Preload previous and next period.
  if ((!loading || data) && !skip && preload) {
    let previousVariables = {
      ...variables,
      start: format(startOfPeriod(subPeriods(date, 1)), 'yyyy-MM-dd'),
      end: format(endOfPeriod(subPeriods(date, 1)), 'yyyy-MM-dd'),
    }

    let nextVariables = {
      ...variables,
      start: format(startOfPeriod(addPeriods(date, 1)), 'yyyy-MM-dd'),
      end: format(endOfPeriod(addPeriods(date, 1)), 'yyyy-MM-dd'),
    }

    // Here we prefetch previous and next period for faster navigation.
    // Refetching only happens on the current preiod upon navigation.
    // We'll only prefetch after the first query has loaded to make the
    // initial reponse faster. If the first query is already in the cache
    // but about to be refetched, we'll prefetch right away.

    client.query({
      query: PERIOD_WORKOUTS,
      variables: previousVariables,
    })

    client.query({
      query: PERIOD_WORKOUTS,
      variables: nextVariables,
    })
  }

  let workouts =
    (area.type === 'user' ? data?.user.workouts : data?.program.workouts) ?? []
  let workout_recordings = data?.user?.workout_recordings ?? []
  let daily_notes = data?.user?.daily_notes ?? []

  return {
    loading,
    workouts,
    workout_recordings,
    daily_notes,
  }
}

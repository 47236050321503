import { stringify } from 'query-string'
import useSWR, { cache } from 'swr'
import { useQuery, gql } from '@apollo/client'
import { EditWorkoutPage } from '../Pages/EditWorkoutPage'

export function _useCalendarInfo(area = null, { skip = false } = {}) {
  let variables

  if (!skip) {
    let user_id = area.type === 'user' && area.id !== 'me' ? area.id : undefined
    let program_id = area.type === 'program' ? area.id : undefined

    variables = stringify({ user_id, program_id })
  }

  // TODO Include hashtags in the response so they are preloaded
  // for the EditWorkoutPage.
  let { data } = useSWR(skip ? null : `/calendar?${variables}`)

  // Try to fetch the title of the calendar from cache, since
  // it likley already exists in the /area-switcher cache.
  let title
  let cached

  if (!data && !skip) {
    cached = cache.get('/area-switcher')
  }

  if (area?.type === 'user' && area?.id === 'me') {
    title = 'Min träning'
  } else if (area?.type === 'user') {
    let user = data?.user

    if (!user && cached) {
      // We'll look through the /area-switcher cache to find the user.
      user = cached.memberships
        .flatMap(x => x.group.memberships ?? [])
        .map(x => x.user)
        .find(x => x.id === parseInt(area.id, 10))
    }

    title = user ? `${user.first_name} ${user.last_name}` : null
  } else if (area?.type === 'program') {
    let program = data?.program

    if (!program && cached) {
      // We'll look through the /area-switcher cache to find the program.
      program = cached.memberships
        .flatMap(x => (x.programs ?? []).concat(x.group.programs ?? []))
        .find(x => x.id === parseInt(area.id, 10))
    }

    title = program ? program.name : null
  }

  return {
    loading: !data,
    user: data?.user,
    program: data?.program,
    writeable: data?.writeable,
    title,
  }
}

const AREA_INFO_QUERY = gql`
  query AreaInfoQuery($id: ID!, $user: Boolean!, $program: Boolean!) {
    user(id: $id) @include(if: $user) {
      id
      first_name
      last_name
    }
    program(id: $id) @include(if: $program) {
      id
      name
      writeable
    }
  }
`

export function useCalendarInfo(area = null, { skip = false } = {}) {
  // Load info about the area. Should be preloaded from the area switcher.
  let { data } = useQuery(AREA_INFO_QUERY, {
    variables: {
      user: area?.type === 'user',
      program: area?.type === 'program',
      id: area?.id,
    },
    skip,
  })

  // Preload the hashtags for EditWorkoutPage separately, since they
  // are not already cached from the area switcher.
  useQuery(
    gql`
      query PreloadHashtagsQuery(
        $id: ID!
        $user: Boolean!
        $program: Boolean!
      ) {
        user(id: $id) @include(if: $user) {
          id
          ...EditWorkoutPage_userHashtags
        }
        program(id: $id) @include(if: $program) {
          id
          ...EditWorkoutPage_programHashtags
        }
      }
      ${EditWorkoutPage.fragments.userHashtags}
      ${EditWorkoutPage.fragments.programHashtags}
    `,
    {
      variables: {
        user: area?.type === 'user',
        program: area?.type === 'program',
        id: area?.id,
      },
      skip,
    },
  )

  // Calculate the calendar title.
  let title

  if (area?.type === 'user' && area?.id === 'me') {
    title = 'Min träning'
  } else if (area?.type === 'user') {
    let user = data?.user

    title = user ? `${user.first_name} ${user.last_name}` : null
  } else if (area?.type === 'program') {
    let program = data?.program

    title = program ? program.name : null
  }

  return {
    loading: !data,
    user: data?.user,
    program: data?.program,
    writeable:
      (area?.type === 'user' && area?.id === 'me') || data?.program?.writeable,
    // Users can have their planned training edited by a coach. Since only coaches
    // can view users we can allow the edit UI for all planned training in user calendars.
    // Of course updates to the training are authorized server side.
    userPlannedWriteable: area?.type === 'user',
    title,
  }
}

import React from 'react'
import { groupBy, mapGroup } from '../Utilities'

const HTML_NOT_SELECTED = ''

function selectText(e) {
  e.target.select()
  e.preventDefault()
}

export function NumberInput({ placeholder, value, onChange, disabled }) {
  return (
    <input
      disabled={disabled}
      type="number"
      placeholder={placeholder}
      className="w-full form-input text-sm py-1.5 px-2"
      value={value ?? HTML_NOT_SELECTED}
      onMouseDown={selectText}
      onChange={e => onChange(parseFloat(e.target.value) || null)}
    />
  )
}

// export function DecimalInput({
//   factor,
//   decimals = 2,
//   value,
//   onChange,
//   disabled,
//   placeholder,
// }) {
//   console.log(value)
//   if (value) {
//     value = round(value / factor, decimals)
//   }

//   return (
//     <input
//       disabled={disabled}
//       type="number"
//       placeholder={placeholder}
//       className="w-full form-input text-sm py-1.5 px-2"
//       value={value ?? HTML_NOT_SELECTED}
//       onMouseDown={selectText}
//       onChange={e => onChange(parseFloat(e.target.value) * factor || null)}
//     />
//   )
// }

export function TimeInput({ value = 0, onChange, disabled }) {
  let hours = Math.floor(value / 3600)
  let minutes = Math.floor((value / 60) % 60)

  return (
    <div className="flex items-center">
      <div className="flex-1">
        <input
          disabled={disabled}
          type="number"
          placeholder="00"
          className="w-full form-input text-right text-sm py-1.5"
          value={hours || HTML_NOT_SELECTED}
          onMouseDown={selectText}
          onChange={e => {
            let value = Math.floor(e.target.value * 3600 + minutes * 60)
            onChange(value)
          }}
        />
      </div>
      <span className="text-lg mx-1">:</span>
      <div className="flex-1">
        <input
          disabled={disabled}
          type="number"
          placeholder="00"
          className="w-full form-input text-sm py-1.5"
          value={minutes || HTML_NOT_SELECTED}
          onMouseDown={selectText}
          onChange={e => {
            let value = Math.floor(hours * 3600 + e.target.value * 60)
            onChange(value)
          }}
        />
      </div>
    </div>
  )
}

export function Select({
  label = null,
  items,
  groupBy: group,
  value,
  getLabel,
  getValue,
  onChange,
  disabled,
}) {
  return (
    <select
      disabled={disabled}
      className="w-full form-select text-sm py-1.5"
      value={value ?? HTML_NOT_SELECTED}
      onChange={e => onChange(e.target.value)}
    >
      {label ? (
        <option key="choose" value={HTML_NOT_SELECTED}>
          {label}
        </option>
      ) : null}

      {group
        ? mapGroup(groupBy(items, group), (name, items) => (
            <optgroup key={name} label={name}>
              {items.map(item => (
                <option key={getValue(item)} value={getValue(item)}>
                  {getLabel(item)}
                </option>
              ))}
            </optgroup>
          ))
        : items.map(item => (
            <option key={getValue(item)} value={getValue(item)}>
              {getLabel(item)}
            </option>
          ))}
    </select>
  )
}

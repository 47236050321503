import { useEffect, useLayoutEffect } from 'react'
import { useMainStore } from '../Stores/MainStore'
import { stringify, parse } from 'query-string'
import { isInStandaloneMode } from '../Utilities'
import { useIsFirstRender } from './useIsFirstRender'
import { useLocation, useNavigate } from '@reach/router'

export function useRememberCalendarNavigation() {
  let location = useLocation()
  let navigate = useNavigate()
  let firstRender = useIsFirstRender()

  let { date, mode, areas } = parse(location.search)

  const setRememberedNavigation = useMainStore(
    state => state.setRememberedNavigation,
  )
  let rememberedNavigation = useMainStore(state => state.rememberedNavigation)
  let rememberedNavigationQuery = stringify(rememberedNavigation)

  // In standalone mode, we'll display the calendars that were previously
  // open when restarting the app.
  useEffect(() => {
    if (!firstRender.current) return

    if (isInStandaloneMode()) {
      navigate(`/calendar?${rememberedNavigationQuery}`)
    }
  }, [navigate, rememberedNavigationQuery, firstRender])

  // Remember the date and areas if we're on the calendar page,
  // so that we can return to the same page when navigating
  // back to the calendar page from another page.
  useLayoutEffect(() => {
    if (firstRender.current) return

    if (/\/(calendar|statistics)(\?+|\/+|$)/.test(location.pathname)) {
      setRememberedNavigation({ date, mode, areas })
    }
  }, [
    date,
    mode,
    areas,
    setRememberedNavigation,
    location.pathname,
    firstRender,
  ])
}

import React, { useState, useRef } from 'react'
import { useQuery, gql } from '@apollo/client'
import classNames from 'classnames'
import { User, Plus, Settings } from 'react-feather'
import { useQueryParams } from '../Hooks/useQueryParams'
import { getAreas, nextAreaKey } from '../Utilities'

export const AREA_SWITCHER_PROGRAM = gql`
  fragment AreaSwitcher_program on Program {
    id
    name
    # TODO For preloading, should use fragments instead as this will likely break.
    writeable
  }
`

export const AREA_SWITCHER_QUERY = gql`
  {
    me {
      id
      memberships {
        id
        roles {
          id
          name
          permissions {
            id
            name
          }
        }
        programs {
          ...AreaSwitcher_program
        }
        group {
          id
          name
          memberships(role: "athlete") {
            id
            user {
              id
              first_name
              last_name
              email
            }
          }
          programs {
            ...AreaSwitcher_program
          }
        }
      }
    }
  }
  ${AREA_SWITCHER_PROGRAM}
`

export default function AreaSwitcher({
  onAreaClicked,
  onNewProgramClicked,
  onProgramSettingsClicked,
  mobile = false,
}) {
  let scrollArea = useRef()

  let query = useQueryParams()
  let { areas } = getAreas(query)

  // TODO When using swr.
  // let { data } = useSWR('/area-switcher')
  // let loading = !data
  // let memberships = data?.memberships ?? []

  let { data, loading } = useQuery(AREA_SWITCHER_QUERY, {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
  })

  let memberships = data?.me.memberships ?? []

  // Link to id: "me" for the current user's calendar.
  // Here we have the actual id, but it might not be available
  // when the page first loads, so it's better to use "me"
  // consistently.
  let [search, setSearch] = useState('')

  let isCoach = memberships.some(m =>
    m.roles.some(role => role.permissions.some(x => x.name === 'manage_group')),
  )

  let hasPrograms = memberships.some(m => m.programs.length > 0)

  function areaClicked(type, id) {
    // We'll add a unique incrementing key to the area, so that they can be
    // identified and state can be preserved.
    let key = nextAreaKey(areas)

    onAreaClicked({ type, id, key })
  }

  return (
    <>
      <div className="m-2 md:m-0 md:mb-2">
        <input
          type="text"
          placeholder="Sök..."
          // TODO Autofocus sometimes causes screen to jump,
          // since we're position: fixed here.
          // autoFocus={!mobile}
          className="w-full rounded bg-gray-300 px-4 py-2"
          value={search}
          onChange={e => {
            setSearch(e.target.value)
            scrollArea.current.scrollTop = 0
          }}
        />
      </div>
      <div className="mx-2 md:mx-0 flex space-x-2">
        {isCoach ? (
          <button
            onClick={() => onNewProgramClicked()}
            className="mb-2 flex items-center w-full text-gray-800 hover:text-black hover:bg-gray-200 active:bg-gray-300 p-2 rounded"
          >
            <Plus size={18} className="mr-2" />{' '}
            <span className="md:hidden">Program</span>
            <span className="hidden md:block">Nytt träningsprogram</span>
          </button>
        ) : null}
        <button
          onClick={() => areaClicked('user', 'me')}
          className="mb-2 flex items-center w-full text-gray-800 hover:text-black hover:bg-gray-200 active:bg-gray-300 p-2 rounded"
        >
          <User size={18} className="mr-2" /> Min träning
        </button>
      </div>
      {isCoach && !hasPrograms ? (
        <div className="mb-2 mx-2 pt-2 pb-3 md:mx-0">
          <p className="px-2 text-gray-800">
            Lägg till träningsprogram för att planera träning åt dina aktiva.
          </p>
        </div>
      ) : null}
      {isCoach ? (
        <div className="mb-2 mx-2 md:mx-0 flex">
          <p className="flex-1 mr-1 px-2">Program</p>
          <p className="flex-1 ml-1 px-2">Aktiva</p>
        </div>
      ) : null}
      <div
        ref={scrollArea}
        className={classNames({
          'max-h-64 overflow-y-scroll overflow-x-hidden scrollbar': !mobile,
        })}
      >
        {memberships.length === 0 && !loading ? (
          <p className="mb-2 ml-2 text-gray-800">
            Gå med i en träningsgrupp och se dina träningsprogram här!
          </p>
        ) : null}
        <ul>
          {memberships.map(membership => {
            // Find all available programs and users, matching the search
            // term if given.
            let programs = (
              membership.group.programs ?? membership.programs
            ).filter(
              program =>
                program.name.toLowerCase().indexOf(search.toLowerCase()) !== -1,
            )

            let users =
              membership.group.memberships
                ?.map(x => x.user)
                ?.filter(
                  user =>
                    `${user.first_name} ${user.last_name}`
                      .toLowerCase()
                      .indexOf(search.toLowerCase()) !== -1 ||
                    user.email.toLowerCase().indexOf(search.toLowerCase()) !==
                      -1,
                ) ?? []

            // We'll hide the group if the user has entered something in the search box
            // but nothing was found in this group.
            if (search && !programs.length && !users.length) return null

            let writeable = membership.roles.some(role =>
              role.permissions.some(x => x.name === 'manage_group'),
            )

            return (
              <li key={membership.id} className="mb-2 last:mb-0">
                <p
                  className={classNames(
                    'sticky top-modal-header-height md:mr-1 md:top-0 bg-gray-200 md:rounded py-2 px-4 md:px-2 text-gray-900',
                    {},
                  )}
                >
                  {membership.group.name}
                </p>
                <div className="flex mx-2 md:mx-0 md:mr-1">
                  <ul
                    className={classNames({
                      'w-1/2 overflow-hidden mr-1': isCoach,
                      'w-full': !isCoach,
                    })}
                  >
                    {programs.map(program => (
                      <li key={program.id} className="mt-2 flex group">
                        <button
                          className="w-full flex-1 text-left text-gray-800 hover:text-black truncate hover:bg-gray-200 active:bg-gray-300 p-2 rounded"
                          onClick={() => areaClicked('program', program.id)}
                        >
                          {program.name}
                        </button>
                        {writeable ? (
                          <button
                            className="flex md:hidden group-hover:flex items-center text-gray-800 hover:text-black hover:bg-gray-200 active:bg-gray-300 px-4 md:px-3 py-2 rounded"
                            onClick={() => onProgramSettingsClicked(program.id)}
                          >
                            <Settings size={16} />
                          </button>
                        ) : null}
                      </li>
                    ))}
                  </ul>
                  {users ? (
                    <ul className="w-1/2 overflow-hidden ml-1">
                      {users.map(user => (
                        <li key={user.id} className="mt-2">
                          <button
                            className="w-full text-left text-gray-800 hover:text-black truncate hover:bg-gray-200 active:bg-gray-300 p-2 rounded"
                            onClick={() => areaClicked('user', user.id)}
                          >
                            {user.first_name} {user.last_name}
                          </button>
                        </li>
                      ))}
                    </ul>
                  ) : null}
                </div>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

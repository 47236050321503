import React from 'react'
import { ChevronLeft, X } from 'react-feather'
import { Modal, ModalHeader, ModalBody } from '../Components/Modal'

export default function HelpPage({ navigate, location, fromMore }) {
  function back() {
    navigate(`../${location.search}`)
  }

  return (
    <Modal onClose={back}>
      <ModalHeader
        title="Hjälp"
        left={
          fromMore ? (
            <button
              onClick={() => navigate('/more')}
              className="flex md:hidden items-center active:opacity-25"
            >
              <ChevronLeft /> Mer
            </button>
          ) : (
            <button
              onClick={back}
              className="flex md:hidden items-center active:opacity-25"
            >
              Stäng
            </button>
          )
        }
        right={
          <button
            onClick={back}
            className="hidden md:flex items-center hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full"
          >
            <X />
          </button>
        }
      />
      <ModalBody>
        <div className="p-4 text-sm">
          <h3 className="text-base mb-2">Hör av dig till Maxpulse</h3>
          <p className="text-2 mb-2">
            Skicka ett mail till{' '}
            <a
              className="text-theme-500 hover:underline"
              href="mailto:hello@maxpulse.se"
            >
              hello@maxpulse.se
            </a>{' '}
            om du stöter på problem, har idéer på förbättringar eller bara
            allmänt funderar över något!
          </p>
          <hr className="my-4" />
          <h3 className="text-base mb-2">Planera och bokföra träning</h3>
          <p className="mb-2 text-gray-800">
            I din kalender ligger planerad träning i den vänstra kolumnen och
            utförd träning i den högra. Lägg till ett pass på ett givet datum
            genom att föra musen över dagen och klicka på plusknappen.
          </p>
          <p className="mb-2 text-gray-800">
            Klicka på ett träningspass för att redigera eller ta bort det. Varje
            vecka har en summering som visar planerad (inom parentes) och utförd
            tid m.m. Knappen "Summering" visar samma tabeller fast för hela
            perioden.
          </p>
          <h3 className="text-base mb-2 mt-5">Daglig notering</h3>
          <p className="mb-2 text-gray-800">
            Knappen längst till höger på varje dag öppnar fönstret för daglig
            notering. Där kan man fylla i sömntimmar, vikt, sjukdag m.m. Knappen
            blir blå om något är ifyllt den dagen, och röd om man fyllt i
            sjukdag.
          </p>
          <h3 className="text-base mb-2 mt-5">Kopiera och flytta pass</h3>
          <p className="mb-2 text-gray-800">
            Det går att dra och släppa pass med musen för att flytta dem mellan
            dagar, och ändra ordning på dem. Håll in ctrl-tangenten (⌘ på macOS)
            för att kopiera istället för att flytta.
          </p>
          <h3 className="text-base mb-2 mt-5">Dina träningsprogram</h3>
          <p className="mb-2 text-gray-800">
            Öppna dina träningsprogram sida vid sida med din kalender med
            knappen "Träningsprogram". Träningsprogrammen kommer från dina
            tränare, och du kan inte själv ändra i dessa.
          </p>
          <p className="mb-2 text-gray-800">
            Hämta en hel veckas pass direkt till den egna planeringskolumnen med
            den gröna knappen som ligger i varje vecka vid träningsprogrammet,
            eller dra i den blå knappen för att hämta till valfri vecka.
            Enskilda pass kan också hämtas genom att dra dem var för sig. I
            planeringskolumnen i din kalender går det sedan att ändra fritt i de
            hämtade passen, så att du kan anpassa träningen efter behov.
          </p>
          <h3 className="text-base mb-2 mt-5">Utföra planerad träning</h3>
          <p className="mb-2 text-gray-800">
            Du kan snabbt utföra ett planerat pass genom att dra passet till
            lämplig dag i utfört-kolumnen. Du kan även öppna ett planerat pass
            och klicka på utfört. Det går att utföra pass direkt från dina
            träningsprogram eller från din planerat-kolumn.
          </p>
          <h3 className="text-base mb-2 mt-5">Pulsfiler</h3>
          <p className="mb-2 text-gray-800">
            Du kan koppla din pulsklocka till Maxpulse, läs mer i{' '}
            <a className="color-2" href="/account">
              dina inställningar
            </a>
            . Synkroniserade pulsfiler hamnar som svarta ikoner i din kalender.
            Klicka på en pulsfil för att visa större diagram. Välj "Bokför
            träningspass" för att bokföra den som ett träningspass.
          </p>
          <p className="mb-2 text-gray-800">
            Dra en pulsfil till ett planerat pass för att bokföra pulsfilen med
            tiderna från det planerade passet. Om du redan har bokfört passet
            kan du dra pulsfilen till det utförda passet för att koppla den. En
            pulsfil går att koppla bort från ett pass genom att öppna passet och
            välja "Koppla bort" vid pulsfilen.
          </p>

          {/* For coaches */}
          <div>
            <h3 className="text-base mb-2 mt-5">
              För tränare - Planera träningsprogram
            </h3>
            <p className="mb-2 text-gray-800">
              Träningsprogram innehåller planerade träningspass som kan göras
              tillgängliga för en eller flera aktiva. Ett träningsprogram skapas
              under menyn "Träningsprogram", och där kan du välja vilken grupp
              programmet tillhör samt vilka aktiva som ska få åtkomst till det.
            </p>
            <p className="mb-2 text-gray-800">
              Du måste alltså skapa ett träningsprogram för att kunna planera
              träning till dina aktiva - och det går bra att göra ett
              träningsprogram för en enda aktiv.
            </p>
            <p className="mb-2 text-gray-800">
              För att planera träning öppnar du träningsprogrammen i
              kalendersidan, med menyn "Träningsprogram". Du kan öppna och
              planera flera träningsprogram sida vid sida. Kopiera en vecka
              genom att dra i den blå knappen. Kopiera pass mellan olika
              träningsprogram genom att dra och släppa dem, eller håll in
              ctrl-tangenten (⌘ på macOS) för att flytta istället.
            </p>
            <h3 className="text-base mb-2 mt-5">
              För tränare - Visa dina aktivas träning
            </h3>
            <p className="mb-2 text-gray-800">
              Öppna dina aktivas kalendrar med menyn "Träningsprogram". Det går
              ej att ändra eller lägga till pass direkt i en aktivs kalender -
              skapa träningsprogram för att planera träning åt dina aktiva.
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

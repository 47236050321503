import React from 'react'
import { Router, Link, useMatch, useLocation } from '@reach/router'
import {
  Calendar as CalendarIcon,
  PieChart,
  Search as SearchIcon,
  LogOut,
  MoreHorizontal,
  List,
  Users,
  ChevronDown,
  Settings,
} from 'react-feather'
import { stringify } from 'query-string'
import { startsWith, getAreas } from '../Utilities'
import { useMainStore } from '../Stores/MainStore'
import AreaSwitcher from './AreaSwitcher'
import { useAuth } from '../Utilities/Auth'
import { Dropdown } from '../Components/Dropdown'
import logo from '../assets/img/logo.png'
import { useMe } from '../Api/useMe'
import { CalendarNav } from '../Pages/CalendarPage'
import { MobileStatisticsNavbar } from '../Pages/StatisticsPage'
import { MobileSearchNavbar } from '../Pages/SearchPage'
import { useMeasurement, useNavigateQuery } from '../Hooks'

function NavButton({ className = '', ...rest }) {
  return (
    <button
      {...rest}
      className={
        className +
        ' p-2 rounded-lg flex items-center hover:bg-gray-200 active:bg-gray-300'
      }
    />
  )
}

function ActiveLink({
  className = '',
  activeClassName = '',
  highlightActive = false,
  highlightActiveExact = false,
  ...rest
}) {
  return (
    <Link
      {...rest}
      getProps={({ isCurrent, isPartiallyCurrent, location, href }) => {
        // We'll skip comparing the query string when determining
        // if a path is active or not.
        isPartiallyCurrent = startsWith(
          location.pathname,
          encodeURI(href).split('?')[0],
        )
        return {
          className:
            (isCurrent && highlightActiveExact) ||
            (isPartiallyCurrent && highlightActive)
              ? className + ' ' + activeClassName
              : className,
        }
      }}
    />
  )
}

function NavLink(props) {
  let {
    className = '',
    highlightActive = false,
    highlightActiveExact = false,
    active = false,
    ...rest
  } = props
  className += ' p-2 rounded-lg flex items-center hover:bg-gray-200'
  return (
    <Link
      {...rest}
      getProps={({ isCurrent, isPartiallyCurrent, location, href }) => {
        // We'll skip comparing the query string when determining
        // if a path is active or not.
        isPartiallyCurrent = startsWith(
          location.pathname,
          encodeURI(href).split('?')[0],
        )
        return {
          className:
            (isCurrent && highlightActiveExact) ||
            (isPartiallyCurrent && highlightActive) ||
            active
              ? className + ' md:bg-gray-200 text-theme-600'
              : className,
        }
      }}
    />
  )
}

export function DesktopNavbar() {
  let location = useLocation()
  let navigateQuery = useNavigateQuery()
  let { me } = useMe()

  let fullName = me ? `${me.first_name} ${me.last_name}` : null

  let navigation = useMainStore(state => state.rememberedNavigation)
  let navigationQuery = stringify(navigation)
  let { areas } = getAreas(navigation)

  let onCalendar = useMatch('/calendar/*')
  let onStatistics = useMatch('/statistics/*')
  let onSearch = useMatch('/search/*')
  let includeAreaSwitcher = onCalendar || onStatistics || onSearch

  let { logout } = useAuth()

  function handleLogout() {
    logout()
  }

  function switchTheme() {
    if (document.body.classList.contains('theme-blue')) {
      document.body.classList.replace('theme-blue', 'theme-pink')
      document.getElementById('favicon').href = '/favicon_pink.ico'
    } else {
      document.body.classList.replace('theme-pink', 'theme-blue')
      document.getElementById('favicon').href = '/favicon.ico'
    }
  }

  return (
    <nav className="h-12 md:h-16 border-theme-600 md:border-t-2 hidden md:flex px-4 justify-center">
      <div className="max-w-6xl flex-1 flex items-center">
        <button
          onClick={switchTheme}
          className="hidden md:flex font-semibold antialiased text-gray-700 items-end p-2 ml-2 mr-4"
        >
          <img className="w-8 mr-2" src={logo} alt="Maxpulse logo" />
          <span>Maxpulse</span>
        </button>
        <div className="flex items-center font-semibold mr-auto">
          <NavLink
            to={`/calendar?${navigationQuery}`}
            className="mr-2"
            highlightActive
          >
            <CalendarIcon className="mr-2" />
            <span>Kalender</span>
          </NavLink>
          <NavLink
            to={`/statistics?${navigationQuery}`}
            className="mr-2"
            highlightActive
          >
            <PieChart className="mr-2" />
            <span>Statistik</span>
          </NavLink>
          <NavLink to="/search" className="px-3 mr-2" highlightActive>
            <SearchIcon className="mr-2" />
            <span>Sök</span>
          </NavLink>
          <NavLink to="/groups" className="px-3 mr-2" highlightActive>
            <Users className="mr-2" />
            <span>Grupper</span>
          </NavLink>
        </div>
        {includeAreaSwitcher ? (
          <div className="mr-2">
            <Dropdown
              trigger={toggle => (
                <NavButton className="px-3" onClick={toggle}>
                  <List className="lg:mr-2" />
                  <span className="hidden lg:inline">Träningsprogram</span>
                </NavButton>
              )}
              content={hide => (
                <div
                  className="text-sm bg-gray-100 rounded p-3 border border-gray-300 shadow-xl"
                  // style={{ width: hasUsers ? 500 : 300, right: 350 }}
                  style={{ width: 500 }}
                >
                  <AreaSwitcher
                    onNewProgramClicked={() => {
                      hide()
                      navigateQuery(`${location.pathname}/program/new`, true)
                    }}
                    onProgramSettingsClicked={id => {
                      hide()
                      navigateQuery(`${location.pathname}/program/${id}`, true)
                    }}
                    onAreaClicked={area =>
                      navigateQuery({
                        areas: JSON.stringify(areas.concat(area)),
                      })
                    }
                  />
                </div>
              )}
            />
          </div>
        ) : null}
        <Dropdown
          trigger={toggle => (
            <NavButton onClick={toggle} className="px-3">
              <span className="hidden lg:inline mr-2">{fullName}</span>
              <ChevronDown />
            </NavButton>
          )}
          content={hide => (
            <div
              className="p-2 bg-gray-100 rounded border border-gray-300 shadow-xl space-y-2 text-sm"
              style={{ width: 250 }}
            >
              <button
                onClick={() => {
                  hide()
                  navigateQuery('/settings')
                }}
                className="w-full rounded hover:bg-gray-200 active:bg-gray-300 px-3 py-2 flex items-center"
              >
                <Settings size={18} className="mr-2" />
                <span>Inställningar</span>
              </button>
              <button
                onClick={handleLogout}
                className="w-full rounded hover:bg-gray-200 active:bg-gray-300 px-3 py-2 flex items-center"
              >
                <LogOut size={18} className="mr-2" />
                <span>Logga ut</span>
              </button>
            </div>
          )}
        />
      </div>
    </nav>
  )
}

export function MobileTabBar() {
  let navigation = useMainStore(state => state.rememberedNavigation)
  let navigationQuery = stringify(navigation)

  return (
    <div className="fixed md:hidden bottom-0 w-screen z-20 pb-ios-safe bg-white border-t">
      <nav className="h-14 flex items-center text-gray-600 text-2xs font-semibold">
        <ActiveLink
          to={`/calendar?${navigationQuery}`}
          className="flex-1 flex flex-col items-center mt-1"
          activeClassName="text-theme-600"
          highlightActive
        >
          <CalendarIcon />
          <span className="mt-1">Kalender</span>
        </ActiveLink>
        <ActiveLink
          to={`/statistics?${navigationQuery}`}
          className="flex-1 flex flex-col items-center mt-1"
          activeClassName="text-theme-600"
          highlightActive
        >
          <PieChart />
          <span className="mt-1">Statistik</span>
        </ActiveLink>
        <ActiveLink
          to="/search"
          className="flex-1 flex flex-col items-center mt-1"
          activeClassName="text-theme-600"
          highlightActive
        >
          <SearchIcon />
          <span className="mt-1">Sök</span>
        </ActiveLink>
        <ActiveLink
          to="/more"
          className="flex-1 flex flex-col items-center mt-1"
          activeClassName="text-theme-600"
          highlightActive
        >
          <MoreHorizontal />
          <span className="mt-1">Mer</span>
        </ActiveLink>
      </nav>
    </div>
  )
}

export function MobileNavbar() {
  return (
    <Router component={React.Fragment} primary={false}>
      <CalendarNav path="/calendar/*" />
      <MobileStatisticsNavbar path="/statistics/*" />
      <MobileSearchNavbar path="/search/*" />
    </Router>
  )
}

const measure = e => e.offsetHeight

export function MobileOrDesktopNavbar() {
  let navRef = useMeasurement('--nav-height', measure)

  return (
    <div
      ref={navRef}
      className="modal-hidden md:modal-block fixed top-0 left-0 w-screen z-20 bg-white md:shadow"
    >
      <DesktopNavbar />
      <MobileNavbar />
    </div>
  )
}

export function NavSafeArea({ children }) {
  return <div className="pt-nav-height pb-14 md:pb-0">{children}</div>
}

import React, { useState } from 'react'
import { Share } from 'react-feather'
import { isIos, isInStandaloneMode } from '../Utilities'

export function PWAInstallHint() {
  let [show, setShow] = useState(!localStorage.getItem('pwa-alert-dismissed'))

  show = show && isIos() && !isInStandaloneMode()

  return show ? (
    <div className="fixed z-30 bottom-0 left-0 right-0 flex justify-center">
      <div
        onClick={() => {
          setShow(false)
          localStorage.setItem('pwa-alert-dismissed', true)
        }}
        className="mb-8 mx-4 rounded-lg bg-gray-200 text-gray-800 text-center shadow-popover p-4"
      >
        <p>
          Installera Maxpulse som app genom att klicka på{' '}
          <Share className="inline relative" style={{ bottom: 2 }} size={18} />{' '}
          och sedan "Lägg till på hemskärmen".
        </p>
      </div>
    </div>
  ) : null
}

import React from 'react'
import { formatSeconds, distributeSamplesToZones, percent } from '../Utilities'
import { useHoverToggle } from '../Hooks'
import { gql } from '@apollo/client'

export const formatDistance = distance =>
  distance ? Math.round(distance / 100) / 10 + ' km' : '-'

HeartRateZoneSummary.fragments = {
  recording: gql`
    fragment HeartRateZoneSummary_recording on WorkoutRecording {
      duration
      distance
      sport {
        name
      }
      samples {
        heart_rate
        distance
      }
      heart_rate_zones {
        id
        intensity {
          id
          alias
          color
          level
        }
        lower_limit
        upper_limit
      }
    }
  `,
}

export function HeartRateZoneSummary({ recording }) {
  let [showPercent, toggleProps] = useHoverToggle()

  let { samples, heart_rate_zones } = recording

  // We'll format the heart rate as elapsed_time => heart_rate
  // to make it easier to copy to other sample arrays.
  let keyedHeartRate = samples.heart_rate.reduce((prev, [x, y]) => {
    prev[x] = y
    return prev
  }, {})

  // Duration already has the heart rate.
  let duration = samples.heart_rate.map(([x, y]) => ({
    duration: x,
    heart_rate: y,
  }))

  // Distance also needs the corresponding heart rate for each sample.
  let distance = samples.distance
    .map(([x, y]) => ({
      distance: y * 1000,
      heart_rate: keyedHeartRate[x],
    }))
    .filter(a => a.heart_rate)

  // Now let's distribute the samples to their appropriate zones.
  // One of the zones we'll get back has intensity set to null -
  // the fallback zone which we format appropriately here.
  let zones = distributeSamplesToZones(heart_rate_zones, {
    duration: { samples: duration, total: recording.duration },
    distance: { samples: distance, total: recording.distance },
  })
    .filter(a => a.duration || a.distance)
    .map(a =>
      a.intensity
        ? a
        : {
            ...a,
            intensity: { color: '#eee', alias: 'Utanför zoner', level: 99 },
          },
    )
    .sort((a, b) => a.intensity.level > b.intensity.level)

  return (
    <div>
      <table className="w-full text-sm text-gray-800" {...toggleProps}>
        <thead>
          <tr>
            <th className="py-1 px-2 text-left font-semibold">
              {recording.sport?.name ?? 'Ingen sport'}
            </th>
            <th className="py-1 px-2 text-right font-semibold w-1/4">
              {formatSeconds(recording.duration)}
            </th>
            {recording.distance ? (
              <th className="py-1 px-2 text-right font-semibold w-1/4">
                {formatDistance(recording.distance)}
              </th>
            ) : null}
          </tr>
        </thead>
        <tbody>
          {zones.map(zone => {
            return (
              <tr key={zone.id} className="border-t">
                <td className="py-1 px-2 text-left">
                  <div className="flex items-center">
                    <span
                      className="w-2 h-2 rounded-full mr-2"
                      style={{ backgroundColor: zone.intensity.color }}
                    />
                    <span>{zone.intensity.alias}</span>
                  </div>
                </td>
                <td className="py-1 px-2 text-right">
                  {showPercent
                    ? percent(zone.duration, recording.duration)
                    : formatSeconds(zone.duration)}
                </td>
                {recording.distance ? (
                  <td className="py-1 px-2 text-right">
                    {showPercent
                      ? percent(zone.distance, recording.distance)
                      : formatDistance(zone.distance)}
                  </td>
                ) : null}
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}

import React, { useState, useCallback } from 'react'
import { ClickPopover } from './Popover'

export function Dropdown({ content, trigger }) {
  let [show, setShow] = useState(false)
  let hide = useCallback(() => {
    setShow(false)
  }, [])

  return (
    <ClickPopover
      placement="bottom-end"
      visible={show}
      modifiers={[
        {
          name: 'offset',
          options: {
            offset: [0, 5],
          },
        },
      ]}
      onClickOutside={hide}
      trigger={trigger(() => setShow(x => !x))}
    >
      {content(hide)}
    </ClickPopover>
  )
}

import { Copy } from 'react-feather'
import React from 'react'
import { format, differenceInCalendarDays, parseISO, addDays } from 'date-fns'
import { DropArea, Draggable } from './DragDrop'
import { PERIOD_WORKOUT } from './Calendar/PeriodQueries'
import { gql, useMutation } from '@apollo/client'
import { optimisticId } from '../Utilities'
import { addWorkoutToCache } from '../Utilities/GraphQLUtilities'

export function DraggableWeek({ start, programTitle, workouts }) {
  return (
    <Draggable
      render={props => (
        <div
          className="hidden md:block cursor-move relative top-1.5 rounded hover:bg-gray-200 active:bg-gray-300 p-1"
          {...props}
        >
          <Copy size={18} />
        </div>
      )}
      preview={props => (
        <div
          className="cursor-default flex shadow rounded p-4 bg-gray-800 text-white"
          style={{ ...props.style, width: 'auto', height: 'auto' }}
        >
          <div className="flex ml-4 mr-4">
            <Copy className="" size={48} />
          </div>
          <div className="mr-2">
            <p>Kopiera vecka {format(start, 'II')}</p>
            <p className="text-lg">{programTitle}</p>
          </div>
        </div>
      )}
      data={{ type: 'week', workouts, weekStart: start }}
    />
  )
}

const CREATE_WORKOUT = gql`
  mutation CreateWorkout($input: CreateWorkoutInput!) {
    createWorkout(input: $input) {
      workout {
        id
        date
        position
        planned
        ...Period_workout
      }
      repositionedWorkouts {
        id
        position
      }
    }
  }
  ${PERIOD_WORKOUT}
`

export function useCopyWeek() {
  let [createWorkout] = useMutation(CREATE_WORKOUT)

  return (workouts, fromStart, toStart, area) =>
    workouts.forEach(workout => {
      let diff = differenceInCalendarDays(parseISO(workout.date), fromStart)
      let newDate = format(addDays(toStart, diff), 'yyyy-MM-dd')

      createWorkout({
        variables: {
          input: {
            date: newDate,
            planned: workout.planned,
            position: workout.position ?? 2000000,
            comment: workout.comment,
            shape: workout.shape,
            rest_day: workout.rest_day,
            analysis: workout.analysis,
            program_id: area.type === 'program' ? area.id : undefined,
            user_id:
              area.type === 'user' && area.id !== 'me' ? area.id : undefined,

            stamina_activities: workout.stamina_activities.map(x => ({
              distance: x.distance,
              duration: x.duration,
              sport_id: x.sport?.id,
              intensity_id: x.intensity?.id,
            })),
            biathlon_activities: workout.biathlon_activities.map(x => ({
              duration: x.duration,
              prone_score: x.prone_score,
              prone_max_score: x.prone_max_score,
              standing_score: x.standing_score,
              standing_max_score: x.standing_max_score,
              biathlon_type_id: x.biathlon_type?.id,
            })),
            strength_activities: workout.strength_activities.map(x => ({
              duration: x.duration,
              strength_type_id: x.strength_type?.id,
            })),
            explosivity_activities: workout.explosivity_activities.map(x => ({
              count: x.count,
              explosivity_type_id: x.explosivity_type?.id,
            })),
          },
        },
        optimisticResponse: {
          createWorkout: {
            workout: {
              id: optimisticId(),
              date: newDate,
              planned: workout.planned,
              position: workout.position ?? 2000000,
              shape: workout.shape,
              comment: workout.comment,
              coach_comment: null,
              rest_day: workout.rest_day,
              analysis: workout.analysis,
              program: area.type === 'program' ? { id: area.id } : null,
              user: area.type === 'user' ? { idOrMe: area.id } : null,
              writeable: false,
              coach_comment_is_writeable: false,
              stamina_activities: workout.stamina_activities,
              biathlon_activities: workout.biathlon_activities,
              strength_activities: workout.strength_activities,
              explosivity_activities: workout.explosivity_activities,
              workout_recordings: [],

              __typename: 'Workout',
            },
            repositionedWorkouts: null,
            __typename: 'CreateWorkoutOutput',
          },
        },
        update(cache, { data: { createWorkout } }) {
          addWorkoutToCache(cache, createWorkout.workout, area)
        },
      })
    })
}

export function WeekDropArea({
  start,
  area,
  writeable,
  userPlannedWriteable,
  children,
}) {
  let copyWeek = useCopyWeek()

  return (
    <DropArea
      accept={writeable || userPlannedWriteable ? ['week'] : []}
      onDrop={({ workouts, weekStart }) => {
        let fromStart = weekStart
        let toStart = start

        if (writeable) {
          copyWeek(workouts, fromStart, toStart, area)
        } else if (userPlannedWriteable) {
          // Only copy the planned workouts since only the "planned" part of the calendar is writeable.
          copyWeek(
            workouts.filter(x => x.planned),
            fromStart,
            toStart,
            area,
          )
        }
      }}
      render={({ ref, isOver }) => (
        <div ref={ref} className="relative">
          {isOver ? (
            <div className="absolute inset-0 bg-gray-300 opacity-50 -m-1.5 border-2 border-dashed border-gray-500" />
          ) : null}
          {children}
        </div>
      )}
    />
  )
}

import React from 'react'

export function Area({ children, narrow = false }) {
  return (
    <div
      // Setting min-w-0 makes sure the area won't expand to more than the available width
      // when a child is too wide, eg. a wide table in statistics. It doesn't matter
      // that the table has overflow-x-scroll, the min width has to be set here anyway.
      className="md:max-w-6xl md:mx-2 md:shadow bg-white flex-1 flex flex-col min-w-0"
      style={{ flexBasis: narrow ? 300 : 600 }}
    >
      {children}
    </div>
  )
}

export function AreaHeader({ children }) {
  return (
    <div className="hidden md:flex flex-wrap sticky top-nav-height z-10 bg-white-blur shadow md:shadow-none md:bg-white md:border-b-2 border-theme-600 px-4 py-1 items-center">
      {children}
    </div>
  )
}

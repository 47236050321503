import {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react'
import { cssVariable } from './Utilities'
import { navigate, useLocation } from '@reach/router'
import { parse, stringify } from 'query-string'

export function usePrevious(value, initial = null) {
  const ref = useRef(initial)

  useEffect(() => {
    ref.current = value
  }, [value])

  return ref.current
}

export function useTheme() {
  // TODO Grab theme from context.
  return { color: shade => '' + cssVariable(`--theme-${shade}`) }
}

export function useHoverToggle() {
  let [show, setShow] = useState(false)
  let alreadyHandledMouseEnter = useRef(false)

  const onClick = () => {
    if (alreadyHandledMouseEnter.current) return
    setShow(!show)
  }

  const onMouseEnter = () => {
    setShow(true)

    // Disable click handler for 500ms since touch devices simulate
    // a click event after the mouseenter, but we don't want the
    // toggle to happen twice.
    alreadyHandledMouseEnter.current = true
    setTimeout(() => (alreadyHandledMouseEnter.current = false), 500)
  }

  const onMouseLeave = () => {
    setShow(false)
  }

  return [show, { onClick, onMouseEnter, onMouseLeave }]
}

function updateCSSVariable(variable, value) {
  document.documentElement.style.setProperty(variable, value + 'px')
}

/**
 * Measure a dom element and set its value to a CSS variable.
 *
 * @param {string} variable The CSS variable to update.
 * @param {function} measure The measure function.
 */
export function useMeasurement(variable, measure) {
  let ref = useRef()

  useLayoutEffect(() => {
    updateCSSVariable(variable, measure(ref.current))
  })

  useEffect(() => {
    const listener = () => updateCSSVariable(variable, measure(ref.current))
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, [variable, measure])

  return ref
}

function navigateTo(url, query = {}) {
  let search = ''

  if (Object.keys(query).filter(k => query[k] !== undefined).length > 0) {
    search = `?${stringify(query)}`
  }

  navigate(url + search)
}

// Navigate to an url, preserving old query params
// and setting new ones.
export function useNavigateQuery() {
  let location = useLocation()

  let navigateQuery = useCallback(
    (url, query = null) => {
      let oldQuery = parse(location.search)
      if (typeof url === 'object') {
        // Using only a query object.
        navigateTo(location.pathname, { ...oldQuery, ...url })
      } else if (typeof url === 'function') {
        // Using only a query function.
        navigateTo(location.pathname, url(oldQuery))
      } else if (typeof query === 'object') {
        // Using an url and a query object.
        navigateTo(url, { ...oldQuery, ...query })
      } else if (typeof query === 'function') {
        // Using an url and a query function.
        navigateTo(url, query(oldQuery))
      } else if (query === true) {
        // Using an url preserving query params.
        navigateTo(url, oldQuery)
      } else {
        // Using an url only.
        navigateTo(url)
      }
    },
    [location.pathname, location.search],
  )

  return navigateQuery
}

import React from 'react'
import { formatSeconds } from '../../../Utilities'
import { StatisticsTable } from '../StatisticsTable'

let formatters = {
  duration(value) {
    return value ? formatSeconds(value) : '-'
  },
  prone_percent(value) {
    return value ? Math.round(value * 100) + '%' : '-'
  },
  standing_percent(value) {
    return value ? Math.round(value * 100) + '%' : '-'
  },
  total_percent(value) {
    return value ? Math.round(value * 100) + '%' : '-'
  },
  prone_standing_shots({ prone_shots, standing_shots }) {
    if (!prone_shots && !standing_shots) {
      return '-'
    }

    return (
      format('prone_shots', prone_shots) +
      '/' +
      format('standing_shots', standing_shots)
    )
  },
  prone_standing_percent({ prone_percent, standing_percent }) {
    if (!prone_percent && !standing_percent) {
      return '-'
    }

    return (
      format('prone_percent', prone_percent) +
      '/' +
      format('standing_percent', standing_percent)
    )
  },
}
function format(field, value) {
  if (formatters[field]) {
    return formatters[field](value)
  }

  return value || '-'
}

let values = {
  prone_standing_shots(item) {
    return {
      prone_shots: item.prone_shots,
      standing_shots: item.standing_shots,
    }
  },
  prone_standing_percent(item) {
    return {
      prone_percent: item.prone_percent,
      standing_percent: item.standing_percent,
    }
  },
}

/**
 * Get the value out of a given row, planned or done. Often returns the
 * value directly accessed from the row, but allows for optionally returning
 * something else, like in the case for the merged prone/standing statistics
 * where the field is "prone_standing_shots" or "prone_standing_percent" but
 * the value should be an object containing stats for both prone and standing.
 *
 * @param {string} field
 * @param {*} item
 * @returns
 */
function getValue(field, item) {
  if (values[field]) {
    return values[field](item)
  }

  return item[field]
}

function biathlonTotal(rows, { field, mode }) {
  let total
  if (field === 'total_percent') {
    let shots = rows.reduce(
      (prev, curr) =>
        prev + curr.prone_shots_for_percent + curr.standing_shots_for_percent,
      0,
    )
    let hits = rows.reduce(
      (prev, curr) =>
        prev + curr.prone_hits_for_percent + curr.standing_hits_for_percent,
      0,
    )
    total = shots ? hits / shots : 0
  } else if (field === 'prone_percent') {
    let shots = rows.reduce(
      (prev, curr) => prev + curr.prone_shots_for_percent + 0,
      0,
    )
    let hits = rows.reduce(
      (prev, curr) => prev + curr.prone_hits_for_percent + 0,
      0,
    )
    total = shots ? hits / shots : 0
  } else if (field === 'standing_percent') {
    let shots = rows.reduce(
      (prev, curr) => prev + curr.standing_shots_for_percent,
      0,
    )
    let hits = rows.reduce(
      (prev, curr) => prev + curr.standing_hits_for_percent,
      0,
    )
    total = shots ? hits / shots : 0
  } else if (field === 'prone_standing_shots') {
    let prone_shots = rows.reduce((prev, curr) => prev + curr.prone_shots, 0)
    let standing_shots = rows.reduce(
      (prev, curr) => prev + curr.standing_shots,
      0,
    )
    total = { prone_shots, standing_shots }
  } else if (field === 'prone_standing_percent') {
    let prone_shots = rows.reduce(
      (prev, curr) => prev + curr.prone_shots_for_percent,
      0,
    )
    let standing_shots = rows.reduce(
      (prev, curr) => prev + curr.standing_shots_for_percent,
      0,
    )
    let prone_hits = rows.reduce(
      (prev, curr) => prev + curr.prone_hits_for_percent,
      0,
    )
    let standing_hits = rows.reduce(
      (prev, curr) => prev + curr.standing_hits_for_percent,
      0,
    )
    let prone_percent = prone_shots ? prone_hits / prone_shots : 0
    let standing_percent = standing_shots ? standing_hits / standing_shots : 0
    total = { prone_percent, standing_percent }
  } else {
    total = rows.reduce((prev, curr) => prev + curr[field], 0)
  }

  return total
}

export function BiathlonTable({
  field,
  title,
  rows,
  biathlon_types,
  intensities,
  onRowClick,
  tableId,
  showPlanned,
  mainMode,
}) {
  // Map of Biathlon combination type id => intensity id. Used for the color of the intensity.
  // TODO This is kind of a hack, should rely on something better than ids. They differ in dev and prod.
  let intensityMap = {
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    14: 5,
  }

  let combinationIds = biathlon_types
    .filter(x => x.category === 'combination')
    .map(x => parseInt(x.id, 10))

  let columns = [
    {
      title: 'Totalt',
      value(row, { mode, field }) {
        return format(field, getValue(field, row.biathlon[mode]))
      },
      total(rows, { mode, field }) {
        let total = biathlonTotal(
          rows.map(row => row.biathlon[mode]),
          { mode, field },
        )

        return format(field, total)
      },
    },
  ]

  columns.push(
    ...biathlon_types.map(biathlon_type => ({
      title: biathlon_type.alias,
      tooltip: biathlon_type.name,
      center: true,
      color: intensities.find(
        x => x.id === intensityMap[biathlon_type.id]?.toString(),
      )?.color,
      value(row, { mode, field }) {
        let type = row.biathlon[mode].biathlon_types.find(
          x => biathlon_type.id === x.id?.toString(),
        )

        return format(field, type ? getValue(field, type) : 0)
      },
      total(rows, { mode, field }) {
        rows = rows
          .flatMap(row => row.biathlon[mode].biathlon_types)
          .filter(x => x.id?.toString() === biathlon_type.id)

        let total = biathlonTotal(rows, { mode, field })

        return format(field, total)
      },
    })),
  )

  // A summary of all biathlon types of category combination.
  columns.push({
    title: 'Komb',
    tooltip: 'Kombination',
    center: true,
    value(row, { mode, field }) {
      let items = row.biathlon[mode].biathlon_types.filter(x =>
        combinationIds.includes(x.id),
      )

      let total = biathlonTotal(items, { mode, field })

      return format(field, total)
    },
    total(rows, { mode, field }) {
      let items = rows
        .flatMap(row => row.biathlon[mode].biathlon_types)
        .filter(x => combinationIds.includes(x.id))

      let total = biathlonTotal(items, { mode, field })

      return format(field, total)
    },
  })

  return (
    <StatisticsTable
      id={tableId}
      columns={columns}
      title={title}
      rows={rows}
      onRowClick={onRowClick}
      showPlanned={showPlanned}
      mainMode={mainMode}
      field={field}
    />
  )
}

import React, { useState, useLayoutEffect } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Components/Modal'
import { X, Info, Trash } from 'react-feather'
import { Select } from '../Components/Form'
import { useQuery, gql, useMutation } from '@apollo/client'
import MultiSelect from 'react-select'
import {
  AREA_SWITCHER_QUERY,
  AREA_SWITCHER_PROGRAM,
} from '../Components/AreaSwitcher'
import produce from 'immer'
import { parse, stringify } from 'query-string'
import { getAreas } from '../Utilities'

const PROGRAM_PAGE_PROGRAM = gql`
  fragment ProgramPage_program on Program {
    name
    memberships {
      id
      user {
        id
        first_name
        last_name
        email
      }
    }
  }
`

function useDraftProgram(mode, id) {
  let [program, setProgram] = useState({
    name: '',
    memberships: [],
    loaded: false,
  })

  let { data } = useQuery(
    gql`
      query ProgramQuery($id: ID!) {
        program(id: $id) {
          id
          ...ProgramPage_program
          group {
            id
            name
            memberships(role: "athlete") {
              id
              user {
                id
                first_name
                last_name
                email
              }
            }
          }
        }
      }
      ${PROGRAM_PAGE_PROGRAM}
    `,
    {
      variables: {
        id,
      },
      skip: mode !== 'edit',
    },
  )

  // Since the initial data might come in later, when editing
  // we'll set our program here.
  useLayoutEffect(() => {
    if (data) {
      setProgram(p => ({
        ...p,
        ...data.program,
        loaded: true,
      }))
    }
  }, [data])

  // If the program draft still is loading we'll return
  // null as the program.
  if (mode === 'edit' && !program.loaded) {
    program = null
  }

  if (program && program.loaded) {
    program.originalName = data.program.name
  }

  return [program, setProgram]
}

export function ProgramPage({ location, navigate, mode, id }) {
  let query = parse(location.search)
  let { areas } = getAreas(query)

  let [createProgram, { loading: createProgramLoading }] = useMutation(gql`
    mutation CreateProgram($input: CreateProgramInput!) {
      createProgram(input: $input) {
        program {
          ...AreaSwitcher_program
        }
      }
    }
    ${AREA_SWITCHER_PROGRAM}
  `)
  let [updateProgram, { loading: updateProgramLoading }] = useMutation(gql`
    mutation UpdateProgram($id: ID!, $input: UpdateProgramInput!) {
      updateProgram(id: $id, input: $input) {
        program {
          ...AreaSwitcher_program
          ...ProgramPage_program
        }
      }
    }
    ${PROGRAM_PAGE_PROGRAM}
    ${AREA_SWITCHER_PROGRAM}
  `)
  let [deleteProgram, { loading: deleteProgramLoading }] = useMutation(gql`
    mutation DeleteProgram($id: ID!) {
      deleteProgram(id: $id) {
        deletedProgramId
      }
    }
  `)

  let [program, setProgram] = useDraftProgram(mode, id)

  let { data } = useQuery(
    gql`
      {
        me {
          id
          memberships {
            id
            roles {
              id
              name
            }
            group {
              id
              name
              memberships(role: "athlete") {
                id
                user {
                  id
                  first_name
                  last_name
                  email
                }
              }
            }
          }
        }
      }
    `,
    { skip: mode !== 'new' },
  )

  let groups =
    data?.me.memberships
      .filter(x => x.roles.some(y => y.name === 'coach'))
      .map(x => x.group) ?? []

  function back() {
    navigate(`../..${location.search}`)
  }

  function save() {
    if (mode === 'new') {
      createProgram({
        variables: {
          input: {
            name: program.name,
            group_id: program.group.id,
            membership_ids: program.memberships.map(x => x.id),
          },
        },
        update(cache, { data: { createProgram } }) {
          // We'll add the newly created program to the area switcher cache.
          const query = cache.readQuery({ query: AREA_SWITCHER_QUERY })

          let index = query.me.memberships.findIndex(
            x => x.group.id === program.group.id,
          )

          let data = produce(query, q => {
            q.me.memberships[index].group.programs.push(createProgram.program)
          })

          cache.writeQuery({ query: AREA_SWITCHER_QUERY, data })
        },
      }).then(() => back())
    } else {
      updateProgram({
        variables: {
          id: program.id,
          input: {
            name: program.name,
            membership_ids: program.memberships.map(x => x.id),
          },
        },
      }).then(() => back())
    }
  }

  function confirmRemove() {
    if (
      window.confirm(
        'Radera träningsprogram? Alla träningspass som ligger i programmet kommer att försvinna.',
      )
    ) {
      remove()
    }
  }

  function remove() {
    deleteProgram({
      variables: {
        id: program.id,
      },
      update(cache, { data: { deleteProgram } }) {
        // We'll remove the program from the area switcher cache.
        const query = cache.readQuery({ query: AREA_SWITCHER_QUERY })

        let membershipIndex = query.me.memberships.findIndex(
          x => x.group.id === program.group.id,
        )
        let programIndex = query.me.memberships[
          membershipIndex
        ].group.programs.findIndex(x => x.id === deleteProgram.deletedProgramId)

        let data = produce(query, q => {
          q.me.memberships[membershipIndex].group.programs.splice(
            programIndex,
            1,
          )
        })

        cache.writeQuery({ query: AREA_SWITCHER_QUERY, data })
      },
    }).then(() => {
      // Go back, and close areas of the removed program.
      // TODO Remove area from remembered navigation if present.
      navigate(
        `../..?${stringify({
          ...query,
          areas: query.areas
            ? JSON.stringify(
                areas.filter(x => !(x.type === 'program' && x.id === id)),
              )
            : undefined,
        })}`,
      )
    })
  }

  let disabled =
    createProgramLoading || updateProgramLoading || deleteProgramLoading

  return (
    <Modal width="md:max-w-lg">
      <ModalHeader
        title={
          mode === 'new'
            ? 'Nytt träningsprogram'
            : program?.originalName ?? '...'
        }
        left={
          <button
            disabled={disabled}
            onClick={back}
            className="flex md:hidden items-center touching:opacity-25"
          >
            Avbryt
          </button>
        }
        right={
          <>
            <button
              disabled={disabled}
              onClick={back}
              className="ml-auto hidden md:flex items-center hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full"
            >
              <X />
            </button>
            <button
              disabled={!program?.name || !program?.group || disabled}
              onClick={save}
              className="flex md:hidden items-center touching:opacity-25"
            >
              Spara
            </button>
          </>
        }
      />
      <ModalBody>
        {program ? (
          <div className="p-4">
            <div className="mb-4">
              <label>
                <span className="block mb-1">Namn</span>
                <input
                  value={program.name}
                  onChange={e => {
                    let name = e.target.value
                    setProgram(p => ({
                      ...p,
                      name,
                    }))
                  }}
                  className="w-full form-input text-sm py-1.5 px-2"
                />
              </label>
              <p className="mt-2 text-gray-800 text-sm">
                Namnet på träningsprogrammet kan vara t.ex "Skidträning 14-16
                år", "Löpgrupp C" eller namnet på en enskild aktiv.
              </p>
            </div>

            {mode === 'new' ? (
              <div className="mb-4">
                <label>
                  <span className="block mb-1">Grupp</span>
                  <Select
                    label="Välj grupp"
                    items={groups}
                    value={program.group?.id}
                    getLabel={x => x.name}
                    getValue={x => x.id}
                    onChange={x => {
                      setProgram(p => ({
                        ...p,
                        group: groups.find(g => g.id === x),
                        memberships: [],
                      }))
                    }}
                  />
                </label>
                <p className="mt-2 text-gray-800 text-sm">
                  Välj vilken grupp det här programmet ska höra till. Alla
                  tränare i gruppen kan redigera programmet.
                </p>
              </div>
            ) : (
              <div className="mb-4">
                <p className="mb-1">Grupp</p>
                <p className="text-sm">{program.group.name}</p>
              </div>
            )}

            <div className="mb-4">
              <label>
                <span className="block mb-1">Aktiva</span>
                <MultiSelect
                  isDisabled={!program.group}
                  className="text-sm"
                  isMulti
                  closeMenuOnSelect={false}
                  placeholder="Välj aktiva"
                  noOptionsMessage={() => 'Inga fler aktiva'}
                  options={program.group?.memberships ?? []}
                  value={program.memberships}
                  getOptionLabel={x =>
                    `${x.user.first_name} ${x.user.last_name}`
                  }
                  getOptionValue={x => x.id}
                  onChange={x => {
                    setProgram(p => ({
                      ...p,
                      memberships: x ?? [],
                    }))
                  }}
                />
              </label>
              <p className="mt-2 text-gray-800 text-sm">
                Här lägger du till alla aktiva som ska ta del av
                träningsprogramet. Vill du göra ett individuellt träningsprogram
                lägger du bara in en aktiv.
              </p>
            </div>

            <p className="mt-2 text-gray-800 text-sm flex bg-gray-200 p-2 rounded">
              <Info className="mr-2" />
              Ett träningsprogram är inte tidsbegränsat - du kan använda samma
              program så länge du vill.
            </p>
            {mode === 'edit' ? (
              <button
                disabled={disabled}
                className="mt-6 mb-2 flex md:hidden items-center w-full text-white bg-red-600 rounded px-3 py-2 hover:bg-red-700 active:bg-red-800 disabled:bg-red-500"
                onClick={confirmRemove}
              >
                <Trash size={18} className="mr-2" />
                <span>Ta bort träningsprogram</span>
              </button>
            ) : null}
          </div>
        ) : null}
      </ModalBody>
      <ModalFooter>
        {mode === 'edit' ? (
          <button
            disabled={disabled}
            onClick={confirmRemove}
            className="disabled:cursor-not-allowed text-white bg-red-600 rounded px-3 py-2 hover:bg-red-700 active:bg-red-800 disabled:bg-red-400"
          >
            Ta bort
          </button>
        ) : null}
        <button
          disabled={disabled}
          onClick={back}
          className="ml-auto bg-gray-200 rounded px-3 py-2 hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-100"
        >
          Avbryt
        </button>
        <button
          disabled={!program?.name || !program?.group || disabled}
          onClick={save}
          className="disabled:cursor-not-allowed ml-4 text-white bg-theme-600 rounded px-3 py-2 hover:bg-theme-700 active:bg-theme-800 disabled:bg-theme-400"
        >
          {mode === 'new' ? 'Skapa träningsprogram' : 'Spara'}
        </button>
      </ModalFooter>
    </Modal>
  )
}

import React, { useLayoutEffect, useEffect } from 'react'
import { X } from 'react-feather'
import { Area, AreaHeader } from '../Components/Area'
import { gql, useMutation } from '@apollo/client'
import { parse } from 'query-string'
import Spinner from '../Components/Spinner'
import { Link } from '@reach/router'

export function ConnectToPolarPage({ navigate, location }) {
  useLayoutEffect(() => {
    window.scrollTo(window.scrollX, 0)
  }, [])

  let [connectToPolar, { data, loading }] = useMutation(gql`
    mutation ConnectToPolar($code: String!) {
      connectToPolar(code: $code) {
        user {
          id
          connected_to_polar
        }
        errors {
          key
          message
        }
      }
    }
  `)

  let query = parse(location.search)

  let { code, error } = query
  let errors = data?.connectToPolar.errors
  let connected = data?.connectToPolar.user?.connected_to_polar

  useEffect(() => {
    if (code) {
      // We'll send the code to the server at once.
      connectToPolar({ variables: { code } }).then(({ data }) => {
        if (data.connectToPolar.errors) return
      })
    }
  }, [code, connectToPolar, navigate])

  // Errors
  // invalid_request: The request is missing a required parameter, includes an invalid parameter value, includes a parameter more than once, or is otherwise malformed.
  // unauthorized_client: The client is not authorized to request an authorization code using this method.
  // access_denied: The user or authorization server denied the request.
  // unsupported_response_type: The authorization server does not support obtaining an authorization code using this method.
  // invalid_scope: The requested scope is invalid, unknown, or malformed.
  // server_error: The authorization server encountered an unexpected condition that prevented it from fulfilling the request.
  // temporarily_unavailable: The authorization server is currently unable to handle the request due to a temporary overloading or maintenance of the server.

  return (
    <>
      <div className="md:hidden fixed bg-white top-0 left-0 right-0 z-20 px-4 h-12 flex items-center justify-center border-b">
        <div className="flex-1"></div>
        <h1 className="font-semibold">Anslut till Polar</h1>
        <div className="flex-1"></div>
      </div>
      <div className="mt-12 md:mt-4 md:mb-4 flex justify-center md:px-3 flex-col md:flex-row md:flex-wrap">
        <Area>
          <AreaHeader>
            <p>Anslut till Polar</p>
            <button className="ml-auto invisible hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full flex items-center">
              <X />
            </button>
          </AreaHeader>
          <div className="p-4">
            {error || errors ? (
              <div className="my-8 flex flex-col items-center justify-center">
                <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
                  {error ? <p>{error}</p> : null}
                  {errors
                    ? errors.map((x, i) => <p key={i}>{x.message}</p>)
                    : null}
                </div>
                <Link className="text-theme-600" to="/settings">
                  Tillbaka
                </Link>
              </div>
            ) : loading ? (
              <div className="my-8 flex flex-col items-center justify-center">
                <Spinner dark className="text-3xl mb-4" />
                <p>Ansluter ditt konto till Polar...</p>
              </div>
            ) : connected ? (
              <div className="my-8 flex flex-col items-center justify-center">
                <p className="mb-4">
                  Du har anslutits till Polar! Dina pulsfiler blir tillgängliga
                  i Maxpulse när du synkroniserar din klocka.
                </p>
                <Link className="text-theme-600" to="/settings">
                  Tillbaka
                </Link>
              </div>
            ) : null}
          </div>
        </Area>
      </div>
    </>
  )
}

import React from 'react'
import { Chart } from '../../Chart'
import { useTheme } from '../../../Hooks'
import { downloadTable, formatSeconds } from '../../../Utilities'
import { useStatisticsCategories } from '../../../Api/useStatisticsCategories'
import useSWR from 'swr'
import Spinner from '../../Spinner'
import { StaminaTable } from './StaminaTable'
import { stringify } from 'query-string'
import { PrimaryButton } from '../../Buttons'

function useStatisticsSeasons(area, { start, end }) {
  let user_id = area.type === 'user' && area.id !== 'me' ? area.id : undefined
  let program_id = area.type === 'program' ? area.id : undefined

  let variables = stringify({
    user_id,
    program_id,
    start_type: start.type,
    start_value: start.value,
    end_type: end.type,
    end_value: end.value,
  })

  let { data: seasons } = useSWR(`/statistics/stamina?${variables}`)

  return { seasons }
}

export function AllSeasons({
  field,
  start,
  end,
  showPlanned,
  mainMode,
  area,
  onShowSeason,
  areaTitle,
  periodTitle,
}) {
  let { intensities, sports } = useStatisticsCategories()

  // TODO sports.id or intensities.id could be null, this means the activity
  // should be considered "unspecified".
  let { seasons } = useStatisticsSeasons(area, { start, end })

  let ready = intensities && sports && seasons

  const { color } = useTheme()

  let tableId = `table-${area.key}`

  function exportTable() {
    downloadTable(tableId, `${areaTitle} - Kondition - ${periodTitle}`)
  }

  if (!ready) {
    return (
      <div className="flex items-center justify-center my-12">
        <Spinner dark />
      </div>
    )
  }

  return (
    <>
      <div className="mb-8">
        <div className="mx-4 mb-4 border-b pb-2 flex items-center">
          <div>
            <h3 className="text-lg">Sammanfattning</h3>
            <p className="text-gray-800 text-sm">
              Klicka på en säsong för att se mer detaljerad statistik.
            </p>
          </div>
          <div className="ml-auto">
            <PrimaryButton className="ml-2" onClick={exportTable}>
              Exportera
            </PrimaryButton>
          </div>
        </div>

        <div className="overflow-x-auto">
          {/* float-left and min-w-full fixes missing padding on the right side when overflowing. */}
          <div className="px-4 float-left min-w-full">
            <StaminaTable
              field={field}
              tableId={tableId}
              title="Säsong"
              rows={seasons.slice().reverse()}
              sports={sports}
              intensities={intensities}
              onRowClick={onShowSeason}
              showPlanned={showPlanned}
              mainMode={mainMode}
            />
          </div>
        </div>
      </div>

      <div className="mb-8">
        <div className="mx-4 mb-4 border-b pb-2">
          <h3 className="text-lg">Timmar per säsong</h3>
        </div>

        <Chart
          title="Timmar per säsong"
          className="mx-4"
          type="bar"
          data={{
            labels: seasons.map(season => season.name),
            datasets: [
              ...(showPlanned
                ? [
                    {
                      label: 'Planerat',
                      backgroundColor: '#333',
                      data: seasons.map(
                        season => season.stamina.planned.duration,
                      ),
                    },
                  ]
                : []),
              {
                label: 'Utfört',
                backgroundColor: color('600'),
                data: seasons.map(season => season.stamina[mainMode].duration),
              },
            ],
          }}
          options={{
            tooltips: {
              callbacks: {
                label: function (item, data) {
                  return (
                    data.datasets[item.datasetIndex].label +
                    ': ' +
                    formatSeconds(item.yLabel)
                  )
                },
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    callback: function (value, index, values) {
                      return formatSeconds(value)
                    },
                    stepSize: 3600 * 100,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </div>

      <div>
        <h3 className="text-lg mx-4">Fördelning av sport och intensitet</h3>
        <p className="mx-4 mb-4 text-gray-800 text-sm border-b pb-2">
          Fördelning för alla säsonger tillsammans.
        </p>

        <div className="mx-2 flex flex-col md:flex-row justify-between flex-wrap">
          <div className="mx-2 mb-4 flex-1">
            <p className="mb-4">Intensitetsfördelning</p>
            <Chart
              height={200}
              title="Tid per intensitet"
              type="doughnut"
              data={{
                labels: intensities.map(x => x.alias),
                datasets: [
                  {
                    label: 'Utfört',
                    data: intensities.map(intensity =>
                      seasons.reduce(
                        (prev, curr) =>
                          prev +
                          (curr.stamina[mainMode].intensities.find(
                            x => x.id?.toString() === intensity.id,
                          )?.duration ?? 0),
                        0,
                      ),
                    ),
                    backgroundColor: intensities.map(x => x.color),
                  },
                  ...(showPlanned
                    ? [
                        {
                          label: 'Planerat',
                          data: intensities.map(intensity =>
                            seasons.reduce(
                              (prev, curr) =>
                                prev +
                                (curr.stamina.planned.intensities.find(
                                  x => x.id?.toString() === intensity.id,
                                )?.duration ?? 0),
                              0,
                            ),
                          ),
                          backgroundColor: intensities.map(x => x.color + '80'),
                        },
                      ]
                    : []),
                ],
              }}
              options={{
                legend: {
                  position: 'right',
                },
                tooltips: {
                  mode: 'nearest',
                  position: 'average',
                  intersect: true,
                  callbacks: {
                    title: (items, data) => {
                      return (
                        data.datasets[items[0].datasetIndex].label +
                        ': ' +
                        data.labels[items[0].index]
                      )
                    },
                    label: (item, data) => {
                      let seconds =
                        data.datasets[item.datasetIndex].data[item.index]

                      let totalSeconds = data.datasets[
                        item.datasetIndex
                      ].data.reduce((prev, curr) => prev + curr, 0)

                      let percent =
                        Math.floor((seconds / totalSeconds) * 1000) / 10

                      return `${formatSeconds(seconds)}, ${percent}%`
                    },
                  },
                },
              }}
            />
          </div>
          <div className="mx-2 mb-4 flex-1">
            <p className="mb-4">Sportfördelning</p>
            <Chart
              height={200}
              title="Tid per sport"
              type="pie"
              data={{
                labels: sports.map(x => x.alias),
                datasets: [
                  {
                    data: sports.map(sport =>
                      seasons.reduce(
                        (prev, curr) =>
                          prev +
                          (curr.stamina[mainMode].sports.find(
                            x => x.id?.toString() === sport.id,
                          )?.duration ?? 0),
                        0,
                      ),
                    ),
                    // backgroundColor: sports.map(x => x.color),
                  },
                ],
              }}
              options={{
                legend: {
                  position: 'right',
                },
                tooltips: {
                  mode: 'nearest',
                  position: 'average',
                  intersect: true,
                  callbacks: {
                    title: (items, data) => {
                      return data.labels[items[0].index]
                    },
                    label: (item, data) => {
                      let seconds =
                        data.datasets[item.datasetIndex].data[item.index]

                      let totalSeconds = data.datasets[
                        item.datasetIndex
                      ].data.reduce((prev, curr) => prev + curr, 0)

                      let percent =
                        Math.floor((seconds / totalSeconds) * 1000) / 10

                      return `${formatSeconds(seconds)}, ${percent}%`
                    },
                  },
                },
              }}
            />
          </div>
        </div>
      </div>
      {/* Some breathing room. */}
      <div className="pb-12"></div>
    </>
  )
}

import { gql, useQuery } from '@apollo/client'

export function useStatisticsCategories({ skip } = {}) {
  let { data, loading } = useQuery(
    gql`
      {
        intensities {
          id
          name
          alias
          color
        }
        sports {
          id
          name
          alias
        }
        biathlon_types {
          id
          name
          alias
          category
        }
        strength_types {
          id
          name
          alias
        }
      }
    `,
    { skip },
  )

  return { ...data, loading }
}

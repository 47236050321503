import React, { useState, useEffect } from 'react'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Link } from '@reach/router'
import Spinner from '../Components/Spinner'
import { GreenButton } from '../Components/Buttons'
import { useAuth } from '../Utilities/Auth'

export function AcceptInvitePage({ navigate, id, token, newUser }) {
  let [firstName, setFirstName] = useState('')
  let [lastName, setLastName] = useState('')
  let [email, setEmail] = useState('')
  let [password, setPassword] = useState('')
  let [passwordConfirmation, setPasswordConfirmation] = useState('')

  let { setLoggedIn } = useAuth()

  let [acceptInvite, { loading: acceptInviteLoading, data: acceptInviteData }] =
    useMutation(gql`
      mutation AcceptInvite($input: AcceptGroupInviteInput!) {
        acceptGroupInvite(input: $input) {
          errors {
            key
            message
          }
        }
      }
    `)

  let { data, loading } = useQuery(
    gql`
      query InviteQuery($id: ID!, $token: String!) {
        me {
          id
          first_name
          last_name
          email
        }
        group_invite(id: $id, token: $token) {
          id
          group_name
          receiver_email
          roles {
            id
            name
          }
        }
      }
    `,
    {
      variables: { id, token },
      // This query doesn't require auth so we shouldn't redirect if
      // not logged in.
      context: { requireAuth: false },
    },
  )

  // Set the initial email from the invite.
  useEffect(() => {
    if (data?.group_invite?.receiver_email) {
      setEmail(data.group_invite.receiver_email)
    }
  }, [data])

  if (loading || !data?.group_invite) {
    return (
      <div className="flex items-center justify-center md:mt-24 md:mx-auto bg-white rounded md:shadow md:max-w-md p-4">
        {loading ? (
          <Spinner dark />
        ) : (
          <p className="text-center text-gray-800">
            Din inbjudan är ogiltig.
            <br />
            Den kan ha gått ut eller tagits bort.
          </p>
        )}
      </div>
    )
  }

  let invite = data.group_invite

  function callback({ data }, login) {
    if (data.acceptGroupInvite.errors) return

    // If we accepted an invite by logging in or register, a cookie has
    // now been set to we need to update the application state so
    // reflect the fact that we're now authenticated.
    if (login) {
      setLoggedIn()
    }

    navigate('/calendar')
  }

  function handleAccept() {
    // Already signed in, accept as current user.
    acceptInvite({
      variables: {
        input: {
          mode: 'auth',
          token_id: id,
          token,
        },
      },
    }).then(res => callback(res, false))
  }

  function handleLogin(e) {
    e.preventDefault()

    acceptInvite({
      variables: {
        input: {
          mode: 'login',
          token_id: id,
          token,
          email,
          password,
        },
      },
    }).then(res => callback(res, true))
  }

  function handleRegister(e) {
    e.preventDefault()

    acceptInvite({
      variables: {
        input: {
          mode: 'register',
          token_id: id,
          token,
          email,
          password,
          password_confirmation: passwordConfirmation,
          first_name: firstName,
          last_name: lastName,
        },
      },
    }).then(res => callback(res, true))
  }

  let info = (
    <div className="mb-2 space-y-2 text-gray-800 text-sm">
      {invite.roles.find(x => x.name === 'athlete') ? (
        <p>
          Du är inbjuden som <span className="font-bold">aktiv</span>. Genom att
          gå med i denna träningsgrupp kommer tränarna i gruppen kunna se din
          träning och skapa träningsprogram åt dig.
        </p>
      ) : null}
      {invite.roles.find(x => x.name === 'coach') ? (
        <p>
          Du är inbjuden som <span className="font-bold">tränare</span>. Genom
          att gå med i denna träningsgrupp kommer du kunna se och planera
          träning åt gruppens aktiva.
        </p>
      ) : null}
    </div>
  )

  return (
    <>
      <div className="md:mt-24 md:mx-auto bg-white rounded md:shadow md:max-w-md overflow-hidden">
        <h1 className="text-lg _text-center leading-relaxed bg-gray-100 border-b p-4">
          Gå med i{' '}
          <span className="font-semibold italic">{invite.group_name}</span> på
          Maxpulse
        </h1>
        <div className="p-4">
          {acceptInviteData?.acceptGroupInvite.errors ? (
            <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
              {acceptInviteData.acceptGroupInvite.errors.map((x, i) => (
                <p key={i}>{x.message}</p>
              ))}
            </div>
          ) : null}
          {data.me ? (
            <div>
              <p className="text-lg">
                Du är inloggad som {data.me.first_name} {data.me.last_name}.
              </p>
              <p className="text-sm text-gray-800 mb-2">({data.me.email})</p>
              {info}
              {/* <p className="mb-4">Gruppavtal?</p> */}
              <GreenButton
                loading={acceptInviteLoading}
                onClick={handleAccept}
                className="block w-full mt-4"
              >
                Gå med!
              </GreenButton>
            </div>
          ) : newUser ? (
            <form onSubmit={handleRegister}>
              <p className="text-lg mb-2">
                Registrera dig nedan för att gå med.
              </p>
              {info}
              <p className="mb-4 text-gray-800 text-sm">
                Har du redan ett konto?{' '}
                <Link
                  className="text-blue-500 underline"
                  to={`/invites/${id}/${token}/existing`}
                  disabled={acceptInviteLoading}
                >
                  Logga in här
                </Link>
              </p>
              <div className="flex space-x-4 mb-4">
                <label className="block flex-1">
                  <span className="text-gray-800 font-semibold">Förnamn</span>
                  <input
                    className="form-input mt-1 block w-full"
                    placeholder="John"
                    value={firstName}
                    onChange={e => setFirstName(e.target.value)}
                    required
                    autoFocus
                  />
                </label>
                <label className="block flex-1">
                  <span className="text-gray-800 font-semibold">Efternamn</span>
                  <input
                    className="form-input mt-1 block w-full"
                    placeholder="Smith"
                    value={lastName}
                    onChange={e => setLastName(e.target.value)}
                    required
                  />
                </label>
              </div>
              <label className="block mb-4">
                <span className="text-gray-800 font-semibold">
                  E-postadress
                </span>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  className="form-input mt-1 block w-full"
                  placeholder="john.smith@example.com"
                />
              </label>
              <label className="block mb-4">
                <span className="text-gray-800 font-semibold">Lösenord</span>
                <input
                  type="password"
                  className="form-input mt-1 block w-full"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                />
              </label>
              <label className="block mb-4">
                <span className="text-gray-800 font-semibold">
                  Upprepa lösenord
                </span>
                <input
                  type="password"
                  className="form-input mt-1 block w-full"
                  value={passwordConfirmation}
                  onChange={e => setPasswordConfirmation(e.target.value)}
                  required
                />
              </label>
              {/* <p className="mb-4">Användaravtal?</p>
              <p className="mb-4">Gruppavtal?</p> */}
              <GreenButton
                loading={acceptInviteLoading}
                className="block w-full"
              >
                Skapa konto och gå med!
              </GreenButton>
            </form>
          ) : (
            <form onSubmit={handleLogin}>
              <p className="text-lg mb-2">Logga in nedan för att gå med.</p>
              {info}
              <p className="mb-4 text-gray-800 text-sm">
                Ny på Maxpulse?{' '}
                <Link
                  className="text-blue-500 underline"
                  to={`/invites/${id}/${token}/new`}
                  disabled={acceptInviteLoading}
                >
                  Skapa ett konto här
                </Link>
              </p>
              <label className="block mb-4">
                <span className="text-gray-800 font-semibold">
                  E-postadress
                </span>
                <input
                  type="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  className="form-input mt-1 block w-full"
                  placeholder="john.smith@example.com"
                  required
                  autoFocus
                />
              </label>
              <label className="block mb-4">
                <span className="text-gray-800 font-semibold">Lösenord</span>
                <input
                  type="password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                  required
                  className="form-input mt-1 block w-full"
                />
              </label>
              {/* <p className="mb-4">Gruppavtal?</p> */}
              <GreenButton
                loading={acceptInviteLoading}
                className="block w-full"
              >
                Logga in och gå med!
              </GreenButton>
            </form>
          )}
        </div>
      </div>
      {!newUser && !data.me ? (
        <div className="mt-4 mb-12 flex justify-center">
          <Link
            to="/password/reset"
            className="hover:underline text-theme-600 text-sm"
          >
            Glömt lösenordet?
          </Link>
        </div>
      ) : (
        <div className="mb-12" />
      )}
    </>
  )
}

import React, { useLayoutEffect, useRef } from 'react'
import { X, ChevronLeft } from 'react-feather'
import { Area, AreaHeader } from '../Components/Area'
import { useQuery, gql, useMutation } from '@apollo/client'
import { Link } from '@reach/router'
import { LoadingButton } from '../Components/Buttons'
import { translateRole } from '../Utilities'

GroupPage.fragments = {
  invites: gql`
    fragment GroupPage_invites on Group {
      invites {
        id
        receiver_email
        roles {
          id
          name
        }
      }
    }
  `,
}

export function GroupPage({ id, children, navigate }) {
  useLayoutEffect(() => {
    window.scrollTo(window.scrollX, 0)
  }, [])

  // TODO Keeps track of which invite we're deleting since useMutation
  // doesn't currently provide a way to get the variables it was called with.
  let deletingInviteId = useRef()
  let [
    deleteInviteMutation,
    { loading: deleteInviteLoading },
  ] = useMutation(gql`
    mutation DeleteInvite($id: ID!) {
      deleteGroupInvite(id: $id) {
        deletedGroupInviteId
      }
    }
  `)

  let deletingMembershipId = useRef()
  let [
    deleteMembershipMutation,
    { loading: deleteMembershipLoading },
  ] = useMutation(gql`
    mutation DeleteMembership($id: ID!) {
      deleteMembership(id: $id) {
        deletedMembershipId
      }
    }
  `)

  let { data } = useQuery(
    gql`
      query GroupQuery($id: ID!) {
        group(id: $id) {
          id
          name
          my_membership {
            id
            roles {
              id
              name
              permissions {
                id
                name
              }
            }
          }
          coaches: memberships(role: "coach") {
            id
            user {
              id
              first_name
              last_name
              email
            }
            roles {
              id
              name
            }
          }
          owners: memberships(role: "owner") {
            id
            user {
              id
              first_name
              last_name
              email
            }
            roles {
              id
              name
            }
          }
          athletes: memberships(role: "athlete") {
            id
            user {
              id
              first_name
              last_name
              email
            }
            roles {
              id
              name
            }
          }
          ...GroupPage_invites
        }
      }
      ${GroupPage.fragments.invites}
    `,
    {
      variables: { id },
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
    },
  )

  function deleteInvite(invite) {
    deletingInviteId.current = invite.id
    deleteInviteMutation({
      variables: { id: invite.id },
      update(cache) {
        cache.evict({ id: cache.identify(invite) })
      },
    })
  }

  function deleteMembership(membership) {
    deletingMembershipId.current = membership.id
    deleteMembershipMutation({
      variables: { id: membership.id },
      update(cache, { data: { deleteMembership } }) {
        // By evicting the membership from the cache, apollo will
        // filter out all dangling references in arrays for us.
        cache.evict({ id: cache.identify(membership) })
      },
    })
  }

  // TODO Separate body for athlete and coach.
  function renderBody(group) {
    // The page will look a bit differently if we can manage the group or not.
    let isCoach = group.my_membership.roles.some(x =>
      x.permissions.some(y => y.name === 'manage_group'),
    )

    if (!isCoach) {
      let coaches = group.coaches

      return (
        <div>
          <h3 className="text-lg mb-2 px-4">Tränare ({coaches.length})</h3>
          <table className="w-full">
            <tbody>
              {coaches.map(membership => (
                <tr
                  key={membership.id}
                  className="odd:bg-gray-100 border-t last:border-b group"
                >
                  <td className="py-1 px-4">
                    {membership.user.first_name} {membership.user.last_name}
                  </td>
                  <td className="py-1 px-4">{membership.user.email}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )
    }

    let { coaches, owners, athletes } = group
    // let coaches =
    //   group.memberships?.filter(x => x.roles.some(r => r.name === 'coach')) ||
    //   []
    // let athletes =
    //   group.memberships?.filter(x => x.roles.some(r => r.name === 'athlete')) ||
    //   []

    return (
      <div className="flex md:space-x-8 flex-col md:flex-row">
        <div className="flex-1">
          <h2 className="text-xl mb-4 px-4">Medlemmar</h2>

          <div className="mb-8">
            <h3 className="text-lg mb-2 px-4">Ägare ({owners.length})</h3>
            <table className="w-full">
              <tbody>
                {owners.map(membership => (
                  <tr
                    key={membership.id}
                    className="odd:bg-gray-100 border-t last:border-b group"
                  >
                    <td className="py-1 px-4">
                      {membership.user.first_name} {membership.user.last_name}
                    </td>
                    <td className="py-1 px-4">{membership.user.email}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mb-8">
            <h3 className="text-lg mb-2 px-4">Tränare ({coaches.length})</h3>
            <table className="w-full">
              <tbody>
                {coaches.map(membership => (
                  <tr
                    key={membership.id}
                    className="odd:bg-gray-100 border-t last:border-b group"
                  >
                    <td className="py-1 px-4">
                      {membership.user.first_name} {membership.user.last_name}
                    </td>
                    <td className="py-1 px-4">{membership.user.email}</td>
                    <td className="py-1 px-4 text-right">
                      {/* Can't remove their own account or owner. */}
                      {membership.id !== group.my_membership.id &&
                      membership.roles.every(x => x.name !== 'owner') ? (
                        <LoadingButton
                          onClick={() => deleteMembership(membership)}
                          loading={
                            deleteMembershipLoading &&
                            deletingMembershipId.current === membership.id
                          }
                          className="invisible group-hover:visible rounded px-2 py-1 text-white text-xs bg-red-600 hover:bg-red-700 active:bg-red-800 disabled:bg-red-500"
                        >
                          Ta bort
                        </LoadingButton>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mb-4">
            <h3 className="text-lg mb-2 px-4">Aktiva ({athletes.length})</h3>
            <table className="w-full mb-2">
              <tbody>
                {athletes.map(membership => (
                  <tr
                    key={membership.id}
                    className="odd:bg-gray-100 border-t last:border-b group"
                  >
                    <td className="py-1 px-4">
                      {membership.user.first_name} {membership.user.last_name}
                    </td>
                    <td className="py-1 px-4">{membership.user.email}</td>
                    <td className="py-1 px-4 text-right">
                      {/* Can't remove their own account or owner. */}
                      {membership.id !== group.my_membership.id &&
                      membership.roles.every(x => x.name !== 'owner') ? (
                        <LoadingButton
                          onClick={() => deleteMembership(membership)}
                          loading={
                            deleteMembershipLoading &&
                            deletingMembershipId.current === membership.id
                          }
                          className="invisible group-hover:visible rounded px-2 py-1 text-white text-xs bg-red-600 hover:bg-red-700 active:bg-red-800 disabled:bg-red-500"
                        >
                          Ta bort
                        </LoadingButton>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <p className="text-gray-800 text-sm mb-2 px-4">
              När du tar bort en användare gäller det endast denna grupp -
              kontot finns kvar på Maxpulse.
            </p>
          </div>
        </div>
        <div className="flex-1">
          <div className="flex items-center mb-4 px-4">
            <h2 className="text-xl">Inbjudna användare</h2>
            <button
              // disabled={disabled}
              onClick={() => navigate('./invite')}
              className="ml-auto text-white bg-theme-600 rounded px-3 py-2 hover:bg-theme-700 active:bg-theme-800 disabled:bg-theme-500"
            >
              Bjud in användare
            </button>
          </div>

          <table className="w-full mb-2">
            <tbody>
              {group.invites.map(invite => (
                <tr
                  key={invite.id}
                  className="odd:bg-gray-100 border-t last:border-b group"
                >
                  <td className="py-1 px-4">{invite.receiver_email}</td>
                  <td className="py-1 px-4">
                    {invite.roles.map(x => translateRole(x.name)).join(', ')}
                  </td>
                  <td className="py-1 px-4 text-right">
                    <LoadingButton
                      onClick={() => deleteInvite(invite)}
                      loading={
                        deleteInviteLoading &&
                        deletingInviteId.current === invite.id
                      }
                      className="invisible group-hover:visible rounded px-2 py-1 text-white text-xs bg-red-600 hover:bg-red-700 active:bg-red-800 disabled:bg-red-500"
                    >
                      Ta bort
                    </LoadingButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <p className="text-gray-800 text-sm px-4">
            Här visas alla inbjudningar som ännu inte har besvarats.
          </p>
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="md:hidden fixed bg-white top-0 left-0 right-0 z-20 px-4 h-12 flex items-center justify-center border-b">
        <div className="flex-1">
          <button
            onClick={() => navigate('../')}
            className="flex md:hidden items-center active:opacity-25"
          >
            <ChevronLeft /> Grupper
          </button>
        </div>
        <h1 className="font-semibold">{data?.group.name}</h1>
        <div className="flex-1"></div>
      </div>

      <div className="mt-12 md:mt-4 md:mb-4 flex justify-center md:px-3 flex-col md:flex-row md:flex-wrap">
        <Area>
          <AreaHeader>
            <p>
              <Link className="text-theme-500 hover:underline" to="/groups">
                Grupper
              </Link>{' '}
              / {data?.group.name}
            </p>
            <button className="ml-auto invisible hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full flex items-center">
              <X />
            </button>
          </AreaHeader>
          <div className="py-4">
            {data?.group ? renderBody(data?.group) : null}
          </div>
        </Area>
      </div>
      {children}
    </>
  )
}

import React from 'react'
import classNames from 'classnames'

export function StatisticsTable({
  id,
  columns,
  title,
  rows,
  onRowClick,
  showPlanned,
  mainMode,
  field,
}) {
  return (
    <table id={id} className="min-w-full table-fixed text-sm highlight-columns">
      <thead>
        <tr className="border-b">
          <th className="w-20 text-left py-1 px-2">{title}</th>
          {columns.map((column, i) => (
            <th
              key={i}
              className={classNames('w-20', {
                'text-center': column.center,
                'text-right': !column.center,
                'py-1 px-2': !column.color,
                'p-0': column.color,
              })}
              title={column.tooltip}
            >
              {column.color ? (
                <div
                  className="mx-1 my-2 py-.5 rounded"
                  style={{ backgroundColor: column.color }}
                >
                  {column.title}
                </div>
              ) : (
                column.title
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (
          <React.Fragment key={row.name}>
            <Row
              mainMode={mainMode}
              row={row}
              field={field}
              onRowClick={onRowClick}
              columns={columns}
            />
            {showPlanned ? (
              <Row
                planned
                mainMode={mainMode}
                row={row}
                field={field}
                onRowClick={onRowClick}
                columns={columns}
              />
            ) : null}
          </React.Fragment>
        ))}
      </tbody>
      <tfoot>
        <FooterRow
          mainMode={mainMode}
          rows={rows}
          field={field}
          columns={columns}
        />
        {showPlanned ? (
          <FooterRow
            planned
            mainMode={mainMode}
            rows={rows}
            field={field}
            columns={columns}
          />
        ) : null}
      </tfoot>
    </table>
  )
}

function Row({ onRowClick, row, planned, mainMode, field, columns }) {
  let mode = planned ? 'planned' : mainMode

  return (
    <tr
      key={row.name}
      className={classNames('border-b', {
        'bg-theme-100': row.current && !planned,
        'bg-gray-700 hover:bg-gray-800 text-white': planned,
        'hover:bg-gray-200': !planned,
        'cursor-pointer': onRowClick,
      })}
      onClick={onRowClick ? () => onRowClick(row) : null}
    >
      <td className="py-1.5 px-2 text-left">{row.name}</td>
      {columns.map((column, i) => (
        <td
          key={i}
          className={classNames('py-1.5 px-2', {
            'text-center': column.center,
            'text-right': !column.center,
          })}
        >
          {column.value(row, { mode, field })}
        </td>
      ))}
    </tr>
  )
}

function FooterRow({ rows, planned, mainMode, field, columns }) {
  let mode = planned ? 'planned' : mainMode

  return (
    <tr
      className={classNames({
        'bg-gray-200': !planned,
        'bg-gray-700 text-white': planned,
      })}
    >
      <td className="py-1 px-2 font-bold">
        {planned ? 'Totalt planerat' : 'Totalt'}
      </td>
      {columns.map((column, i) => (
        <td
          key={i}
          className={classNames('py-1.5 px-2', {
            'text-center': column.center,
            'text-right': !column.center,
          })}
        >
          {column.total(rows, { mode, field })}
        </td>
      ))}
    </tr>
  )
}

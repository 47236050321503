import React, { useRef, useLayoutEffect } from 'react'
import ReactDOM from 'react-dom'
import { useMeasurement } from '../Hooks'
import classNames from 'classnames'

function onSmallScreen() {
  return window.innerWidth <= 768
}

export function Modal({
  children,
  visible = true,
  onClose,
  center = false,
  simple = false,
  width = 'md:max-w-5xl',
}) {
  const bodyRef = useRef()
  const scrollTopRef = useRef(null)

  function handleOverlayClick(e) {
    if (e.target === bodyRef.current && onClose) {
      e.preventDefault()
      e.stopPropagation()
      onClose()
    }
  }

  function pushScrollY() {
    if (onSmallScreen()) {
      scrollTopRef.current = window.scrollY
      window.scrollTo(window.screenX, 0)
    }
  }

  function popScrollY() {
    if (scrollTopRef.current !== null) {
      window.scrollTo(window.scrollX, scrollTopRef.current)
      scrollTopRef.current = null
    }
  }

  useLayoutEffect(() => {
    if (visible) {
      pushScrollY()
      document.body.classList.add('modal-open')
      document.body.classList.add('md:overflow-y-hidden')
    } else {
      document.body.classList.remove('modal-open')
      document.body.classList.remove('md:overflow-y-hidden')
      popScrollY()
    }
    return () => {
      document.body.classList.remove('modal-open')
      document.body.classList.remove('md:overflow-y-hidden')
      popScrollY()
    }
  }, [visible])

  let content = simple ? (
    children
  ) : (
    <div
      className={`mb-16 md:mb-0 ${width} md:max-h-full md:mx-auto md:shadow-modal flex flex-col`}
    >
      {children}
    </div>
  )

  return visible
    ? ReactDOM.createPortal(
        <React.Fragment>
          <div className="hidden md:block md:fixed inset-0 z-20 bg-black opacity-25" />
          <div
            ref={bodyRef}
            onClick={handleOverlayClick}
            className={classNames('md:fixed inset-0 z-20 md:px-4 md:py-vh-5', {
              'flex flex-col justify-center items-center': center,
            })}
            aria-modal
            aria-hidden
            role="dialog"
          >
            {content}
          </div>
        </React.Fragment>,
        document.body,
      )
    : null
}

const measure = e => e.offsetHeight

export function ModalHeader({ left = null, title = null, right = null }) {
  let ref = useMeasurement('--modal-header-height', measure)

  return (
    <div
      ref={ref}
      className="border-b bg-white fixed md:static top-0 left-0 right-0 z-20 h-12 flex p-4 flex-shrink-0"
    >
      <div className="flex-1 flex items-center">{left}</div>

      <h2 className="flex items-center font-semibold">{title}</h2>

      <div className="flex-1 flex items-center justify-end">{right}</div>
    </div>
  )
}

export function ModalFooter({ children }) {
  return (
    <div className="border-t bg-white flex-shrink-0 hidden md:flex px-4 py-3">
      {children}
    </div>
  )
}

export function ModalBody({ children }) {
  return (
    <div className="bg-white mt-12 md:mt-0 flex-1 md:overflow-y-auto">
      {children}
    </div>
  )
}

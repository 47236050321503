import { cookie } from '../Utilities'
import { resetAuth } from '../Utilities/Auth'

export let fetcher = async (url, options = {}) => {
  // Fetch csrf token from server it it's not set.
  if (!cookie('XSRF-TOKEN')) {
    await fetch(process.env.REACT_APP_ROOT_URL + '/sanctum/csrf-cookie', {
      credentials: 'include',
    })
  }

  options = {
    ...options,
    credentials: 'include',
  }

  // Don't set headers on GET requests. This makes sure that the headers
  // matches potential preload requests, and the csrf token is not
  // needed for GET anyway.
  if (options.method && options.method !== 'GET') {
    options.headers = {
      'X-XSRF-TOKEN': cookie('XSRF-TOKEN'),
      ...(options.headers ?? {}),
    }
  }

  let uri = process.env.REACT_APP_API_URL + url

  let response = await fetch(uri, options)

  // Try to grab a fresh csrf token if it's invalid.
  // This happens if we perform a POST request, and the cookie is
  // not sent along back, so we have to make an extra GET here
  // to get a new csrf token.
  if (response.status === 419) {
    await fetch(process.env.REACT_APP_ROOT_URL + '/sanctum/csrf-cookie', {
      credentials: 'include',
    })

    // Retry the request with the new token.
    options.headers['X-XSRF-TOKEN'] = cookie('XSRF-TOKEN')
    response = await fetch(uri, options)
  }

  // TODO Add a requireAuth flag to make the authentication requirement optional.
  let unauthenticated = response.status === 401

  if (unauthenticated) {
    // The session is invalid, probably expired. We'll make
    // sure the user has to log in again.
    await resetAuth()

    return null
  }

  return response.json()
}

import React, { useRef, useState } from 'react'
import classNames from 'classnames'
import Spinner from '../Components/Spinner'

export function SingleClick({ onClick: _onClick, render }) {
  let [clicked, setClicked] = useState(false)

  function onClick(e) {
    if (!clicked) {
      _onClick(e)
      setClicked(true)
    }
  }

  function onMouseLeave() {
    if (clicked) {
      setClicked(false)
    }
  }

  return render({ props: { onClick, onMouseLeave }, clicked })
}

export function LoadingButton({ children, loading, darkSpinner, ...rest }) {
  let nonLoadingWidth = useRef()

  return (
    <button
      ref={e => {
        if (!loading && e) nonLoadingWidth.current = e.offsetWidth
      }}
      style={{ width: loading ? nonLoadingWidth.current : undefined }}
      {...rest}
    >
      {loading ? <Spinner dark={darkSpinner} className="mx-auto" /> : children}
    </button>
  )
}

export function MobileButton({ className, ...props }) {
  return (
    <LoadingButton
      darkSpinner
      {...props}
      className={classNames(className, {
        'active:opacity-25': !props.disabled,
      })}
    />
  )
}

export function PrimaryButton({ className, ...props }) {
  return (
    <LoadingButton
      {...props}
      className={classNames(
        className,
        'rounded px-3 py-2 text-white bg-theme-600 hover:bg-theme-700 active:bg-theme-800 disabled:bg-theme-400 disabled:cursor-not-allowed',
      )}
    />
  )
}

export function GrayButton({ className, ...props }) {
  return (
    <LoadingButton
      {...props}
      className={classNames(
        className,
        'rounded px-3 py-2 bg-gray-200 hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-100',
      )}
    />
  )
}

export function RedButton({ className, ...props }) {
  return (
    <LoadingButton
      {...props}
      className={classNames(
        className,
        'rounded px-3 py-2 text-white bg-red-600 hover:bg-red-700 active:bg-red-800 disabled:bg-red-500',
      )}
    />
  )
}

export function GreenButton({ className, ...props }) {
  return (
    <LoadingButton
      {...props}
      className={classNames(
        className,
        'rounded px-3 py-2 text-white bg-green-600 hover:bg-green-700 active:bg-green-800 disabled:bg-green-400 disabled:cursor-not-allowed',
      )}
    />
  )
}

import React from 'react'
import { Chart } from '../../Chart'
import { useTheme } from '../../../Hooks'
import { useStatisticsCategories } from '../../../Api/useStatisticsCategories'
import useSWR from 'swr'
import Spinner from '../../Spinner'
import { BiathlonTable } from './BiathlonTable'
import { stringify } from 'query-string'
import { PrimaryButton } from '../../Buttons'
import { downloadTable } from '../../../Utilities'

function useStatisticsSeasons(area, { start, end }) {
  let user_id = area.type === 'user' && area.id !== 'me' ? area.id : undefined
  let program_id = area.type === 'program' ? area.id : undefined

  let variables = stringify({
    user_id,
    program_id,
    start_type: start.type,
    start_value: start.value,
    end_type: end.type,
    end_value: end.value,
  })

  let { data: seasons } = useSWR(`/statistics/biathlon?${variables}`)

  return { seasons }
}

export function AllSeasons({
  field,
  start,
  end,
  showPlanned,
  mainMode,
  area,
  onShowSeason,
  areaTitle,
  periodTitle,
}) {
  let { biathlon_types, intensities } = useStatisticsCategories()

  // TODO sports.id or intensities.id could be null, this means the activity
  // should be considered "unspecified".
  let { seasons } = useStatisticsSeasons(area, { start, end })

  let ready = biathlon_types && intensities && seasons

  const { color } = useTheme()

  let tableId = `table-${area.key}`

  function exportTable() {
    downloadTable(tableId, `${areaTitle} - Skytte - ${periodTitle}`)
  }

  if (!ready) {
    return (
      <div className="flex items-center justify-center my-12">
        <Spinner dark />
      </div>
    )
  }

  return (
    <>
      <div className="mb-8">
        <div className="mx-4 mb-4 border-b pb-2 flex items-center">
          <div>
            <h3 className="text-lg">Sammanfattning</h3>
            <p className="text-gray-800 text-sm">
              Klicka på en säsong för att se mer detaljerad statistik.
            </p>
          </div>
          <div className="ml-auto">
            <PrimaryButton className="ml-2" onClick={exportTable}>
              Exportera
            </PrimaryButton>
          </div>
        </div>

        <div className="overflow-x-auto">
          {/* float-left and min-w-full fixes missing padding on the right side when overflowing. */}
          <div className="px-4 float-left min-w-full">
            <BiathlonTable
              field={field}
              tableId={tableId}
              title="Säsong"
              rows={seasons.slice().reverse()}
              biathlon_types={biathlon_types}
              intensities={intensities}
              onRowClick={onShowSeason}
              showPlanned={showPlanned}
              mainMode={mainMode}
            />
          </div>
        </div>
      </div>

      <div className="mb-8">
        <div className="mx-4 mb-4 border-b pb-2">
          <h3 className="text-lg">Antal skott per säsong</h3>
          {/* <p className="text-gray-800 text-sm">
                Klicka på en stapel för att se mer detaljerad statistik.
              </p> */}
        </div>

        <Chart
          title="Antal skott per säsong"
          className="mx-4"
          type="bar"
          data={{
            labels: seasons.map(season => season.name),
            datasets: [
              {
                label: 'Antal skott',
                backgroundColor: color('600'),
                data: seasons.map(
                  season =>
                    season.biathlon[mainMode].prone_shots +
                    season.biathlon[mainMode].standing_shots,
                ),
              },
            ],
          }}
          options={{
            tooltips: {
              callbacks: {
                label: function (item) {
                  return item.yLabel
                },
              },
            },
            scales: {
              xAxes: [
                {
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    callback: function (value, index, values) {
                      return value
                    },
                    stepSize: 3600 * 100,
                    min: 0,
                  },
                },
              ],
            },
          }}
        />
      </div>

      {/* Some breathing room. */}
      <div className="pb-12"></div>
    </>
  )
}

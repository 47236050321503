import React from 'react'
import classNames from 'classnames'
import { Star } from 'react-feather'

export default function Rating({
  stars,
  value,
  onChange = () => {},
  disabled = false,
}) {
  return (
    <div
      className={classNames('flex Rating', { 'Rating--disabled': disabled })}
      style={{ flexDirection: 'row-reverse' }}
    >
      {Array(stars)
        .fill(null)
        .map((_, i) => stars - i)
        .map(star => (
          <button
            key={star}
            className={classNames(
              'flex items-center text-gray-400 px-1 Rating__star',
              {
                pointer: !disabled,
                'Rating__star--selected': star === value,
              },
            )}
            onClick={() => disabled || onChange(star)}
          >
            <Star />
          </button>
        ))}
    </div>
  )
}

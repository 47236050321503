import React from 'react'
import image from '../assets/error.gif'

export function ErrorPage() {
  return (
    <div className="md:max-w-lg md:mx-auto md:mt-24 p-4">
      <h2 className="text-2xl mb-4 mt-8">Maxpulse har kraschat :(</h2>
      <p className="mb-4">
        Vi har meddelats om problemet och försöker lösa det så snart som
        möjligt.
      </p>
      <p className="mb-4">
        Under tiden kan du prova med att{' '}
        <a href="/" className="font-bold text-theme-600 hover:underline">
          ladda om Maxpulse
        </a>{' '}
        eller <span className="font-bold">stänga och öppna Maxpulse igen</span>.
      </p>
      <img alt="Illustrative truck crash" className="w-full" src={image} />
    </div>
  )
}

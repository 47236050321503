import React, { useState, useEffect, useLayoutEffect } from 'react'
import { X } from 'react-feather'
import classNames from 'classnames'
import { Area, AreaHeader } from '../Components/Area'
import { stringify, parse } from 'query-string'
import { isTouch } from '../Utilities'
import { Workout } from '../Components/Workout'
import { useQuery, gql } from '@apollo/client'

export function SearchPage({ location, navigate, children }) {
  let query = parse(location.search)
  let { search } = query
  const [text, setText] = useState(search || '')

  // Synchronize search text if the search query param changes.
  useEffect(() => {
    setText(search || '')
  }, [search])

  useLayoutEffect(() => {
    window.scrollTo(window.scrollX, 0)
  }, [])

  let areas = [{ type: 'user', id: 'me', title: 'Min träning' }]

  let { data } = useQuery(gql`
    {
      me {
        id
        hashtags {
          hashtag
          count
        }
      }
    }
  `)

  let hashtags = data?.me.hashtags ?? []

  return (
    <>
      <div className="px-4 bg-gray-300 border-b border-gray-400">
        <div className="max-w-6xl mx-auto py-4 px-4">
          <div className="flex -ml-10 flex-col md:flex-row md:flex-wrap">
            <div className="flex-1 ml-10 mb-4">
              <p className="mb-2">Sök träningspass efter kommentar/hashtag</p>
              <form
                onSubmit={e => {
                  e.preventDefault()
                  navigate(
                    '?' + stringify({ ...query, search: text || undefined }),
                  )
                }}
                className="flex items-center mb-2"
              >
                <input
                  value={text}
                  onChange={e => setText(e.target.value)}
                  type="text"
                  autoFocus={!isTouch()}
                  placeholder="#tävling"
                  className="flex-1 rounded px-2 py-1 shadow mr-2"
                />
                <button
                  type="submit"
                  className="rounded shadow py-1 px-2 bg-theme-500 hover:bg-theme-600 active:bg-theme-700 text-white font-semibold"
                >
                  Sök
                </button>
              </form>
              <p className="text-sm text-gray-600">
                Sök efter valfri text i kommentaren, eller efter en av dina
                hashtags.
              </p>
            </div>
            <div className="flex-1 ml-10">
              <p>Mina hashtags</p>
              <ul className="flex flex-wrap text-theme-600">
                {hashtags.map(({ hashtag, count }) => (
                  <li key={hashtag} className="mr-3">
                    <button
                      className="flex items-center leading-none mb-1"
                      onClick={() => {
                        setText(hashtag)
                        navigate('?' + stringify({ ...query, search: hashtag }))
                      }}
                    >
                      <span className="mr-1">{hashtag}</span>
                      <span className="px-2 py-1 text-xs rounded bg-gray-400 text-gray-600">
                        {count}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
              <p className="text-sm text-gray-600">
                Här visas alla hashtags som du använt dig av i dina kommentarer.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex justify-center md:px-3 md:my-4 flex-col md:flex-row md:flex-wrap">
        {areas.map(area => (
          <SearchArea
            key={area.type + ':' + area.id}
            area={area}
            search={search}
            navigate={navigate}
            location={location}
          />
        ))}
      </div>
      {children}
    </>
  )
}

function SearchArea({ area, search, navigate, location }) {
  // Don't cache at all since updating workouts can
  // affect the search results. Since they are computed
  // on the server is doesn't seem right to try to
  // update the cache correctly - it's better to
  // simply refetch every search.
  let { data, loading } = useQuery(
    gql`
      query Workouts($user: ID!, $comment: String) {
        user(id: $user) {
          id
          workouts(comment: $comment) {
            id
            date
            planned
            comment
            ...Workout_workout
          }
        }
      }
      ${Workout.fragments.workout}
    `,
    {
      variables: {
        user: area.type === 'user' ? area.id : undefined,
        comment: search,
      },
      // TODO Apollo still returns 'loading' on a query with no
      // workouts in the response - but only when skip was true
      // the previous request.
      skip: !search,
      fetchPolicy: 'network-only',
    },
  )

  // console.log(search, data, loading)
  let workouts = loading || !data ? null : data.user.workouts

  const regex = new RegExp(`(${search})`, 'i')
  const lowerSearch = search ? search.toLowerCase() : ''

  return (
    <Area>
      <AreaHeader>
        <p>Min träning</p>
        <button className="ml-auto hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full flex items-center">
          <X />
        </button>
      </AreaHeader>
      <div>
        {!search ? (
          <p className="p-4">
            Sök eller klicka på en hashtag så visas matchande träningspass här.
          </p>
        ) : !workouts ? (
          <p className="p-4">Söker...</p>
        ) : workouts.length === 0 ? (
          <p className="p-4">
            Du har inga träningspass som matchar din sökning :(
          </p>
        ) : null}
        {workouts && workouts.length > 0 ? (
          <p className="px-4 py-3 bg-gray-200 border-b">
            {workouts.length} resultat
          </p>
        ) : null}
        {workouts ? (
          <>
            {workouts.map(workout => (
              <div
                key={workout.id}
                className="border-b last:border-b-0 odd:bg-gray-100 px-4 pb-2 pt-1 md:pt-2 flex flex-wrap md:flex-no-wrap items-center md:items-stretch"
              >
                <div className="flex-shrink-0 mr-4 mb-1">{workout.date}</div>
                <div className="flex-shrink-0 md:order-1 ml-auto mb-2 md:mb-0 p-1">
                  <Workout
                    workout={workout}
                    onClick={() => {
                      if (isTouch()) {
                        navigate(`${workout.id}${location.search}`)
                      } else {
                        navigate(`${workout.id}/edit${location.search}`)
                      }
                    }}
                  />
                </div>
                <p className="text-gray-800 text-sm mr-2">
                  {search && workout.comment
                    ? workout.comment.split(regex).map((words, i) => (
                        <span
                          key={i}
                          className={classNames({
                            'font-bold': words.toLowerCase() === lowerSearch,
                          })}
                        >
                          {words}
                        </span>
                      ))
                    : workout.comment}
                </p>
              </div>
            ))}
          </>
        ) : null}
      </div>
    </Area>
  )
}

export function MobileSearchNavbar() {
  return (
    <div className="md:hidden border-b">
      <div className="px-4 h-12 flex items-center justify-center">
        <h1 className="font-semibold">Min träning</h1>
      </div>
    </div>
  )
}

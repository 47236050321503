import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Components/Modal'
import { X, ChevronLeft } from 'react-feather'
import { useQuery, gql } from '@apollo/client'
import { WorkoutSummary } from '../Components/Workout'

WorkoutPage.fragments = {
  workout: gql`
    fragment WorkoutPage_workout on Workout {
      # TODO Fragment from WorkoutSummary
      id
      writeable
      coach_comment_is_writeable
      # We need the program id to know which cache to remove
      # it from if the workout is deleted.
      user {
        idOrMe
      }
      program {
        id
      }
      date
      planned
      comment
      coach_comment
      shape
      rest_day
      analysis
      workout_recordings {
        id
        date
        source
        average_heart_rate
        calories
        samples {
          heart_rate(max_points: 150)
        }
      }
      stamina_activities {
        id
        distance
        duration
        ascent
        sport {
          id
          name
          alias
        }
        intensity {
          id
          name
          alias
          color
          level
        }
      }
      biathlon_activities {
        id
        prone_score
        prone_max_score
        standing_score
        standing_max_score
        duration
        biathlon_type {
          id
          name
          alias
          category
        }
      }
      strength_activities {
        id
        duration
        strength_type {
          id
          name
          alias
        }
      }
      explosivity_activities {
        id
        count
        explosivity_type {
          id
          name
          alias
        }
      }
    }
  `,
}

export function WorkoutPage({ location, navigate, id }) {
  let { data: { workout } = {} } = useQuery(
    gql`
      query Workout($id: ID!) {
        workout(id: $id) {
          ...WorkoutPage_workout
        }
      }

      ${WorkoutPage.fragments.workout}
    `,
    {
      variables: {
        id,
      },
    },
  )

  let titleText = workout
    ? (workout.planned ? 'Planerat' : 'Utfört') + ' pass'
    : '...'

  function back() {
    navigate(`../${location.search}`)
  }

  function edit() {
    navigate(`./edit${location.search}`)
  }

  function complete() {
    navigate(`./completed${location.search}`)
  }

  return (
    <Modal onClose={back}>
      <ModalHeader
        title={titleText}
        left={
          <button
            onClick={back}
            className="flex md:hidden items-center active:opacity-25"
          >
            <ChevronLeft /> Kalender
          </button>
        }
        right={
          <>
            <button
              onClick={back}
              className="hidden md:flex items-center hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full"
            >
              <X />
            </button>
            {workout?.writeable || workout?.coach_comment_is_writeable ? (
              <button
                onClick={edit}
                className="flex md:hidden items-center active:opacity-25"
              >
                Ändra
              </button>
            ) : null}
          </>
        }
      />
      <ModalBody>
        {workout ? (
          <WorkoutSummary
            workout={workout}
            mobile={true}
            onComplete={workout.planned ? complete : null}
          />
        ) : null}
      </ModalBody>
      {workout ? (
        <ModalFooter>
          <div className="ml-auto"></div>
          {workout.writeable ? (
            <button
              className="hidden md:block ml-4 bg-gray-200 rounded px-3 py-2 hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-100"
              onClick={edit}
            >
              Ändra
            </button>
          ) : null}
          {workout.planned ? (
            <button
              className="ml-4 text-white bg-green-600 rounded px-3 py-2 hover:bg-green-700 active:bg-green-800 disabled:bg-gray-500"
              onClick={complete}
            >
              Utför träningspass
            </button>
          ) : null}
        </ModalFooter>
      ) : null}
    </Modal>
  )
}

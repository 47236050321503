import React, { useState, useLayoutEffect } from 'react'
import { stringify, parse } from 'query-string'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Components/Modal'
import { X } from 'react-feather'
import { WorkoutForm } from '../Components/WorkoutForm'
import { useQuery, useMutation, gql } from '@apollo/client'

import { isTouch } from '../Utilities'
import {
  addWorkoutToCache,
  removeWorkoutFromCache,
  addWorkoutRecordingToCache,
  removeWorkoutRecordingFromCache,
} from '../Utilities/GraphQLUtilities'
import {
  PERIOD_WORKOUT,
  PERIOD_WORKOUT_RECORDING,
} from '../Components/Calendar/PeriodQueries'
import {
  MobileButton,
  PrimaryButton,
  GrayButton,
  RedButton,
} from '../Components/Buttons'

EditWorkoutPage.fragments = {
  workout: gql`
    fragment EditWorkoutPage_workout on Workout {
      ...WorkoutForm_workout
      user {
        idOrMe
      }
      program {
        id
      }
      id
      date
      planned
      comment
      coach_comment
      shape
      writeable
      coach_comment_is_writeable
      rest_day
      analysis
      workout_recordings {
        id
        date
        source
        average_heart_rate
        calories
      }
      stamina_activities {
        id
        distance
        duration
        ascent
        sport {
          id
          name
          alias
        }
        intensity {
          id
          name
          alias
          color
          level
        }
      }
      biathlon_activities {
        id
        prone_score
        prone_max_score
        standing_score
        standing_max_score
        duration
        biathlon_type {
          id
          name
          alias
          category
          category_name
        }
      }
      strength_activities {
        id
        duration
        strength_type {
          id
          name
          alias
        }
      }
      explosivity_activities {
        id
        count
        explosivity_type {
          id
          name
          alias
        }
      }
    }
    ${WorkoutForm.fragments.workout}
  `,
  userHashtags: gql`
    fragment EditWorkoutPage_userHashtags on User {
      hashtags {
        hashtag
      }
    }
  `,
  programHashtags: gql`
    fragment EditWorkoutPage_programHashtags on Program {
      hashtags {
        hashtag
      }
    }
  `,
}

const CREATE_WORKOUT = gql`
  mutation CreateWorkout($input: CreateWorkoutInput!) {
    createWorkout(input: $input) {
      workout {
        id
        date
        comment
        shape
        planned
        ...Period_workout
        user {
          id
          hashtags {
            hashtag
            count
          }
        }
        program {
          id
          hashtags {
            hashtag
            count
          }
        }
        workout_recordings {
          id
          ...Period_workout_recording
        }
      }
    }
  }
  ${PERIOD_WORKOUT}
  ${PERIOD_WORKOUT_RECORDING}
`
const UPDATE_WORKOUT = gql`
  mutation UpdateWorkout($id: ID!, $input: UpdateWorkoutInput!) {
    updateWorkout(id: $id, input: $input) {
      workout {
        id
        date
        comment
        planned
        ...Period_workout
        user {
          id
          hashtags {
            hashtag
            count
          }
        }
        program {
          id
          hashtags {
            hashtag
            count
          }
        }
        workout_recordings {
          id
          ...Period_workout_recording
        }
      }
      detachedWorkoutRecordings {
        id
        ...Period_workout_recording
      }
    }
  }
  ${PERIOD_WORKOUT}
  ${PERIOD_WORKOUT_RECORDING}
`

export const DELETE_WORKOUT = gql`
  mutation DeleteWorkout($id: ID!) {
    deleteWorkout(id: $id) {
      deletedWorkoutId
      user {
        id
        hashtags {
          hashtag
          count
        }
      }
      program {
        id
        hashtags {
          hashtag
          count
        }
      }
    }
  }
`

export const UPDATE_WORKOUT_COACH_COMMENT = gql`
  mutation UpdatetWorkoutCoachComment($id: ID!, $coach_comment: String) {
    updateWorkoutCoachComment(id: $id, coach_comment: $coach_comment) {
      workout {
        id
        coach_comment
      }
    }
  }
`

const WORKOUT_HASHTAGS = gql`
  query Hashtags($id: ID!, $user: Boolean!, $program: Boolean!) {
    user(id: $id) @include(if: $user) {
      id
      ...EditWorkoutPage_userHashtags
    }
    program(id: $id) @include(if: $program) {
      id
      ...EditWorkoutPage_programHashtags
    }
  }
  ${EditWorkoutPage.fragments.userHashtags}
  ${EditWorkoutPage.fragments.programHashtags}
`

export const GLOBAL_DATA = gql`
  query GlobalData {
    sports {
      id
      name
      alias
      position
    }
    intensities {
      id
      name
      color
      alias
      level
    }
    biathlon_types {
      id
      name
      alias
      category
      category_name
      position
    }
    strength_types {
      id
      name
      alias
      position
    }
    explosivity_types {
      id
      name
      alias
      position
    }
  }
`

function useDraftWorkout(date, planned, id, mode, recordingId) {
  // This is the initial workout draft in case we're
  // creating a new workout.
  let [workout, setWorkout] = useState({
    date,
    comment: '',
    coach_comment: '',
    shape: null,
    planned,
    writeable: true,
    coach_comment_is_writeable: false,
    rest_day: false,
    analysis: false,
    workout_recordings: [],
    // Here we track all the workout recordings that should
    // be detached when saving.
    detached_workout_recordings: [],

    stamina_activities: [],
    biathlon_activities: [],
    strength_activities: [],
    explosivity_activities: [],
  })

  // If we're instead either editing or completing an existing
  // workout, we'll fetch it here and then set our draft
  // to the result later in useLayoutEffect.
  let { data } = useQuery(
    gql`
      query Workout($id: ID!) {
        workout(id: $id) {
          ...EditWorkoutPage_workout
        }
      }
      ${EditWorkoutPage.fragments.workout}
    `,
    {
      variables: {
        id,
      },
      skip: mode === 'new',
    },
  )

  // Here we load an external workout recording that can be attached
  // to a newly created workout.
  let { data: recordingData } = useQuery(
    gql`
      query WorkoutRecording($id: ID!) {
        workout_recording(id: $id) {
          # TODO Fragment, same as EditWorkoutPage_workout.workout_recordings
          id
          average_heart_rate
          calories
        }
      }
    `,
    {
      variables: {
        id: recordingId,
      },
      skip: !recordingId,
    },
  )

  // Since the initial data might come in later, when editing or
  // completing, we'll set our workout here.
  useLayoutEffect(() => {
    if (data) {
      setWorkout(w => ({
        ...w,
        ...data.workout,
        date: date ?? data.workout.date,
        planned: mode === 'completed' ? false : data.workout.planned,
        writeable: mode === 'completed' ? true : data.workout.writeable,
      }))
    }
  }, [data, mode, date])

  // When a potential workout recording comes in, we'll
  // add it to the workout's workout recordings.
  useLayoutEffect(() => {
    if (recordingData && recordingData.workout_recording) {
      setWorkout(w => ({
        ...w,
        workout_recordings: w.workout_recordings.concat(
          recordingData.workout_recording,
        ),
      }))
    }
  }, [recordingData])

  // If the workout draft still is loading we'll return
  // null as the workout. Same if we're requesting a workout
  // recording but it hasn't loaded yet.
  if ((mode !== 'new' && !data) || (recordingId && !recordingData)) {
    workout = null
  }

  // From here on the workout is either null, or fully loaded.

  // Add an empty activity if no one exists.
  if (workout) {
    if (workout.stamina_activities.length === 0) {
      workout = { ...workout, stamina_activities: [{}] }
    }
    if (workout.biathlon_activities.length === 0) {
      workout = { ...workout, biathlon_activities: [{}] }
    }
    if (workout.strength_activities.length === 0) {
      workout = { ...workout, strength_activities: [{}] }
    }
    if (workout.explosivity_activities.length === 0) {
      workout = { ...workout, explosivity_activities: [{}] }
    }
  }

  return [workout, setWorkout]
}

export function EditWorkoutPage({ id, location, navigate, mode }) {
  let query = parse(location.search)
  let planned = query.planned === 'true'
  let recordingId = mode === 'new' ? query.recording : null

  const [createWorkout, { loading: createWorkoutLoading }] =
    useMutation(CREATE_WORKOUT)
  const [updateWorkout, { loading: updateWorkoutLoading }] =
    useMutation(UPDATE_WORKOUT)

  let [deleteWorkout, { loading: deleteWorkoutLoading }] =
    useMutation(DELETE_WORKOUT)
  let [
    updateWorkoutCoachComment,
    { loading: updateCoachCommentLoading, data: updateCoachCommentData },
  ] = useMutation(UPDATE_WORKOUT_COACH_COMMENT)

  let [workout, setWorkoutState] = useDraftWorkout(
    query.on,
    planned,
    id,
    mode,
    recordingId,
  )

  // We'll allow click outside to close it the form is unmodified.
  let [modified, setModified] = useState(false)

  function setWorkout(...args) {
    setModified(true)
    setWorkoutState(...args)
  }

  // Neither user nor program means the current user.
  let areaId =
    workout?.program?.id ??
    // When updating a user workout we need 'me' as the ID
    // in order to update the period query cache correctly.
    workout?.user?.idOrMe ??
    query.program ??
    query.user ??
    'me'

  let areaType
  if (workout?.program) areaType = 'program'
  else if (workout?.user) areaType = 'user'
  else if (query.program) areaType = 'program'
  else if (query.user) areaType = 'user'
  else areaType = 'user'

  // If we're completing a workout it should be saved to the current
  // user and not to the program.
  if (mode === 'completed') {
    areaId = 'me'
    areaType = 'user'
  }

  // Load all the used hashtags for the current user or program.
  let { data } = useQuery(WORKOUT_HASHTAGS, {
    variables: {
      id: areaId,
      user: areaType === 'user',
      program: areaType === 'program',
    },
    skip: !workout,
  })

  let hashtags = (
    (areaType === 'program' ? data?.program.hashtags : data?.user.hashtags) ??
    []
  ).map(x => x.hashtag)

  // This is preloaded in the App component.
  let { data: globalData } = useQuery(GLOBAL_DATA)

  function close() {
    let url = mode === 'new' ? '../' : '../../'
    navigate(
      `${url}?${stringify({
        ...query,
        on: undefined,
        planned: undefined,
        program: undefined,
        user: undefined,
        recording: undefined,
      })}`,
    )
  }

  function complete() {
    navigate(`../completed${location.search}`)
  }

  function back() {
    if (!isTouch() || mode === 'completed') {
      return close()
    }

    let url = '../'
    navigate(
      `${url}?${stringify({
        ...query,
        on: undefined,
        planned: undefined,
        program: undefined,
        user: undefined,
        recording: undefined,
      })}`,
    )
  }

  function save() {
    if (mode === 'edit') {
      updateWorkout({
        variables: {
          id: workout.id,
          input: {
            date: workout.date,
            planned: workout.planned,
            comment: workout.comment,
            shape: workout.shape,
            rest_day: workout.rest_day,
            analysis: workout.analysis,
            workout_recording_ids: workout.workout_recordings
              .map(x => x.id)
              .filter(id => !workout.detached_workout_recordings.includes(id)),

            stamina_activities: workout.stamina_activities.map(x => ({
              distance: x.distance,
              duration: x.duration,
              ascent: x.ascent,
              sport_id: x.sport?.id,
              intensity_id: x.intensity?.id,
            })),
            biathlon_activities: workout.biathlon_activities.map(x => ({
              duration: x.duration,
              prone_score: x.prone_score,
              prone_max_score: x.prone_max_score,
              standing_score: x.standing_score,
              standing_max_score: x.standing_max_score,
              biathlon_type_id: x.biathlon_type?.id,
            })),
            strength_activities: workout.strength_activities.map(x => ({
              duration: x.duration,
              strength_type_id: x.strength_type?.id,
            })),
            explosivity_activities: workout.explosivity_activities.map(x => ({
              count: x.count,
              explosivity_type_id: x.explosivity_type?.id,
            })),
          },
        },
        update(cache, { data: { updateWorkout } }) {
          // If we detached any workout recordings from this workout, they
          // must be added to the period query cache in order to reappear
          // in the calendar.
          updateWorkout.detachedWorkoutRecordings.forEach(recording =>
            addWorkoutRecordingToCache(cache, recording),
          )
        },
      }).then(res => (isTouch() ? back() : close()))
    } else {
      createWorkout({
        variables: {
          input: {
            program_id: areaType === 'program' ? areaId : undefined,
            user_id:
              areaType === 'user' && areaId !== 'me' ? areaId : undefined,
            date: workout.date,
            planned: workout.planned,
            comment: workout.comment,
            shape: workout.shape,
            rest_day: workout.rest_day,
            analysis: workout.analysis,

            workout_recording_ids: workout.workout_recordings
              .map(x => x.id)
              .filter(id => !workout.detached_workout_recordings.includes(id)),
            stamina_activities: workout.stamina_activities.map(x => ({
              distance: x.distance,
              duration: x.duration,
              ascent: x.ascent,
              sport_id: x.sport?.id,
              intensity_id: x.intensity?.id,
            })),
            biathlon_activities: workout.biathlon_activities.map(x => ({
              duration: x.duration,
              prone_score: x.prone_score,
              prone_max_score: x.prone_max_score,
              standing_score: x.standing_score,
              standing_max_score: x.standing_max_score,
              biathlon_type_id: x.biathlon_type?.id,
            })),
            strength_activities: workout.strength_activities.map(x => ({
              duration: x.duration,
              strength_type_id: x.strength_type?.id,
            })),
            explosivity_activities: workout.explosivity_activities.map(x => ({
              count: x.count,
              explosivity_type_id: x.explosivity_type?.id,
            })),
          },
        },
        update(cache, { data: { createWorkout } }) {
          addWorkoutToCache(cache, createWorkout.workout, {
            type: areaType,
            id: areaId,
          })

          // If we attatched any workout recordings to this workout, they
          // must be removed from the period query cache in order to disappear
          // from the calendar.
          createWorkout.workout.workout_recordings.forEach(recording =>
            removeWorkoutRecordingFromCache(cache, recording),
          )
        },
      }).then(res => close())
    }
  }

  function remove() {
    deleteWorkout({
      variables: {
        id: workout.id,
      },
      update(cache, { data: { deleteWorkout } }) {
        if (deleteWorkout.deletedWorkoutId !== workout.id) return
        removeWorkoutFromCache(cache, workout, {
          type: areaType,
          id: areaId,
        })
      },
    }).then(res => close())
  }

  function updateCoachComment() {
    updateWorkoutCoachComment({
      variables: {
        id: workout.id,
        coach_comment: workout.coach_comment || null,
      },
    })
  }

  let title = ''
  if (workout) {
    if (workout.writeable) {
      title = `${mode === 'edit' ? 'Ändra' : 'Nytt'} ${
        workout.planned ? 'planerat' : 'utfört'
      } pass`
    } else {
      title = (workout.planned ? 'Planerat' : 'Utfört') + ' pass'
    }
  }

  let loading =
    createWorkoutLoading || updateWorkoutLoading || deleteWorkoutLoading

  let canComplete =
    mode === 'edit' &&
    workout?.planned &&
    (areaType === 'program' || (areaType === 'user' && areaId === 'me'))

  return (
    <Modal onClose={modified ? null : close}>
      <ModalHeader
        title={title}
        left={
          <MobileButton disabled={loading} onClick={back} className="md:hidden">
            {workout && workout.writeable ? 'Avbryt' : 'Stäng'}
          </MobileButton>
        }
        right={
          <>
            <button
              disabled={loading}
              onClick={close}
              className="ml-auto hidden md:flex items-center hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full"
            >
              <X />
            </button>
            {workout?.writeable ? (
              <MobileButton
                disabled={loading}
                loading={createWorkoutLoading || updateWorkoutLoading}
                onClick={save}
                className="md:hidden"
              >
                Spara
              </MobileButton>
            ) : null}
          </>
        }
      />
      <ModalBody>
        <div className="p-4">
          {workout && globalData ? (
            <WorkoutForm
              areaType={areaType}
              sports={globalData.sports}
              intensities={globalData.intensities}
              biathlon_types={globalData.biathlon_types}
              strength_types={globalData.strength_types}
              explosivity_types={globalData.explosivity_types}
              workout={workout}
              hashtags={hashtags}
              onChange={setWorkout}
              onComplete={canComplete ? complete : null}
              onDelete={mode === 'edit' ? remove : null}
              disabled={!workout.writeable}
              deleteWorkoutLoading={deleteWorkoutLoading}
              onUpdateCoachComment={updateCoachComment}
              updateCoachCommentLoading={updateCoachCommentLoading}
              updateCoachCommentData={updateCoachCommentData}
              loading={loading}
            />
          ) : null}
        </div>
      </ModalBody>
      <ModalFooter>
        {mode === 'edit' && workout && workout.writeable ? (
          <RedButton
            disabled={loading}
            loading={deleteWorkoutLoading}
            onClick={remove}
            className="mr-auto"
          >
            Ta bort
          </RedButton>
        ) : null}
        <GrayButton disabled={loading} onClick={back} className="ml-auto">
          {workout && workout.writeable ? 'Avbryt' : 'Stäng'}
        </GrayButton>
        {workout && workout.writeable ? (
          <PrimaryButton
            disabled={loading}
            loading={createWorkoutLoading || updateWorkoutLoading}
            onClick={save}
            className="ml-4"
          >
            Spara
          </PrimaryButton>
        ) : null}
      </ModalFooter>
    </Modal>
  )
}

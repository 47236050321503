import React, { useLayoutEffect } from 'react'
import { X, ChevronLeft } from 'react-feather'
import { Area, AreaHeader } from '../Components/Area'
import { Link } from '@reach/router'
import { translateRole } from '../Utilities'
import { useGroups } from '../Api/useGroups'

export function GroupsPage({ children, navigate }) {
  let { memberships = [] } = useGroups()

  useLayoutEffect(() => {
    window.scrollTo(window.scrollX, 0)
  }, [])

  return (
    <>
      <div className="md:hidden fixed bg-white top-0 left-0 right-0 z-20 px-4 h-12 flex items-center justify-center border-b">
        <div className="flex-1">
          <button
            onClick={() => navigate('/more')}
            className="flex md:hidden items-center active:opacity-25"
          >
            <ChevronLeft /> Mer
          </button>
        </div>
        <h1 className="font-semibold">Grupper</h1>
        <div className="flex-1"></div>
      </div>
      <div className="mt-12 md:mt-4 md:mb-4 flex justify-center md:px-3 flex-col md:flex-row md:flex-wrap">
        <Area>
          <AreaHeader>
            <p>Grupper</p>
            <button className="ml-auto invisible hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full flex items-center">
              <X />
            </button>
          </AreaHeader>
          <div className="md:px-2 md:py-2 flex">
            <ul className="md:space-y-1 flex-1">
              {memberships.map(membership => (
                <li key={membership.id}>
                  <Link
                    className="block md:hover:bg-gray-200 active:bg-gray-300 md:active:bg-gray-200 md:rounded p-2 border-b md:border-b-0"
                    to={`./${membership.group.id}`}
                  >
                    <p>{membership.group.name}</p>
                    <p className="text-gray-700 text-sm">
                      Du är{' '}
                      {membership.roles
                        .map(x => translateRole(x.name).toLowerCase())
                        .join(', ')}{' '}
                      i gruppen.
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
            {/* <div>
              <button
                // disabled={disabled}
                // onClick={save}
                className="text-white bg-theme-600 rounded px-3 py-2 hover:bg-theme-700 active:bg-theme-800 disabled:bg-theme-500"
              >
                Ny grupp
              </button>
            </div> */}
          </div>
        </Area>
      </div>
      {children}
    </>
  )
}

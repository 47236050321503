import { biathlonPeriod } from './MultiWeekPeriod'
import { Modes } from '../Utilities'
import { isWithinInterval } from 'date-fns'
const {
  season,
  startOfPeriod,
  endOfPeriod,
  startOfSeason,
  endOfSeason,
  addSeasons,
  subSeasons,
} = biathlonPeriod

export const SEASONS_PER_PAGE = 10

export const isCurrentPeriod = (date, mode = Modes.Period) =>
  isWithinInterval(new Date(), {
    start: startOfPeriod(date),
    end: endOfPeriod(date),
  }) && mode === Modes.Period

export const isCurrentSeason = (date, mode = Modes.Season) =>
  isWithinInterval(new Date(), {
    start: startOfSeason(date),
    end: endOfSeason(date),
  }) && mode === Modes.Season

export const isCurrentAllPage = (date, mode = Modes.All) =>
  isWithinInterval(new Date(), {
    start: startOfSeason(subSeasons(date, season(date) % SEASONS_PER_PAGE)),
    end: endOfSeason(
      addSeasons(
        date,
        SEASONS_PER_PAGE - 1 - (season(date) % SEASONS_PER_PAGE),
      ),
    ),
  }) && mode === Modes.All

export const isCurrent = (date, mode) =>
  (mode === Modes.All && isCurrentAllPage(date)) ||
  (mode === Modes.Season && isCurrentSeason(date)) ||
  (mode === Modes.Period && isCurrentPeriod(date))

export function shortSeason(date) {
  let number = season(date)

  return `${number.toString().slice(-2)}/${(number + 1).toString().slice(-2)}`
}

export function fullSeason(date) {
  let number = season(date)

  return `${number}/${number + 1}`
}

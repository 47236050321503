import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/link-error'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { cookie, debounce } from '../Utilities'
import { resetAuth } from '../Utilities/Auth'

// Apollo configuration.
const batchHttpLink = new BatchHttpLink({
  uri: process.env.REACT_APP_ROOT_URL + '/graphql',
  fetch: async (uri, options) => {
    options = { ...options, headers: { ...(options.headers ?? {}) } }

    // Fetch csrf token from server it it's not set.
    if (!cookie('XSRF-TOKEN')) {
      await fetch(process.env.REACT_APP_ROOT_URL + '/sanctum/csrf-cookie', {
        credentials: 'include',
      })
    }

    options.headers['X-XSRF-TOKEN'] = cookie('XSRF-TOKEN')

    let response = await fetch(uri, options)

    // Try to grab a fresh csrf token if it's invalid.
    if (response.status === 419) {
      await fetch(process.env.REACT_APP_ROOT_URL + '/sanctum/csrf-cookie', {
        credentials: 'include',
      })

      // Retry the request with the new token.
      options.headers['X-XSRF-TOKEN'] = cookie('XSRF-TOKEN')
      response = await fetch(uri, options)
    }

    return response
  },
  batchMax: 20,
  batchKey: operation => {
    // Don't batch mutations. We'll use a random number as the batch key.
    // There's a very slim chance of collision since we likely never
    // issue many mutations at the same time. Well we do when copying
    // a week of workouts.
    if (operation.query.definitions[0]?.operation === 'mutation') {
      return Math.random()
    }

    return 'query'
  },
})

let alertOnMaintenanceMode = debounce(
  () => alert('Maxpulse uppdateras och är snart tillbaka!'),
  1000,
)

// Remove access token if we receive a 401 Unauthenticated
// from the server.
const errorLink = onError(({ graphQLErrors, networkError }) => {
  // if (graphQLErrors) {
  //   graphQLErrors.map(({ message, locations, path }) =>
  //     console.log(
  //       `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
  //     ),
  //   )
  // }
  if (networkError) {
    if (networkError.statusCode === 401) {
      //
    } else if (networkError.statusCode === 503) {
      // Shows an alert if server is in maintenance mode.
      alertOnMaintenanceMode()
    }
  }
})

const authLink = new ApolloLink((operation, forward) => {
  let { requireAuth = true } = operation.getContext()

  operation.setContext({
    headers: {
      accept: 'application/json',
    },
    credentials: 'include',
  })

  return forward(operation).map(data => {
    let { response } = operation.getContext()

    if (response.headers && requireAuth && !response.__didCheckAuth) {
      // Hacky way to make sure we only check authentication once per response,
      // since requests are batched by the BatchHttpLink but we'll receive
      // the data here unbatched.
      response.__didCheckAuth = true

      let authenticated =
        response.headers.get('Maxpulse-Authenticated') === 'true'

      if (!authenticated) {
        // The the session has probably expired. We'll make
        // sure the user has to log in again.

        // Apollo does some funky stuff with resending certain queries
        // even though the components are no longer in the render tree.
        // Not sure what's going on but we'll accept it for now - it
        // works and only rarely happens (when the token is invalid).
        // mainStoreApi.getState().refreshApplication()

        // Here we log the user out, but stay on the same url so they can
        // log in again to the same place.
        resetAuth()
      }
    }

    return data
  })
})

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        workout(existingData, { args, toReference }) {
          return (
            existingData || toReference({ __typename: 'Workout', id: args.id })
          )
        },
        workout_recording(existingData, { args, toReference }) {
          return (
            existingData ||
            toReference({ __typename: 'WorkoutRecording', id: args.id })
          )
        },
        daily_note(existingData, { args, toReference }) {
          return (
            existingData ||
            toReference({ __typename: 'DailyNote', id: args.id })
          )
        },
        user(existingData, { args, toReference }) {
          // TODO args.id can be 'me', is that dangerous?
          return (
            existingData || toReference({ __typename: 'User', id: args.id })
          )
        },
        program(existingData, { args, toReference }) {
          return (
            existingData || toReference({ __typename: 'Program', id: args.id })
          )
        },
      },
    },
    WorkoutRecording: {
      fields: {
        samples: {
          merge(existing, incoming, { mergeObjects }) {
            return mergeObjects(existing, incoming)
          },
        },
      },
    },
  },
})

export const client = new ApolloClient({
  link: authLink.concat(errorLink).concat(batchHttpLink),
  cache,
})

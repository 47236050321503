import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useAuth } from '../Utilities/Auth'
import { GreenButton } from '../Components/Buttons'
import { Link } from '@reach/router'
import { CornerUpLeft } from 'react-feather'

export function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  let active = React.useRef(true)
  let { login } = useAuth()

  useEffect(() => () => (active.current = false), [])

  async function handleLogin(e) {
    e.preventDefault()
    if (loading) return

    setLoading(true)

    let response = await login(email, password)

    if (!active.current) return

    if (!response.ok) {
      return ReactDOM.unstable_batchedUpdates(() => {
        switch (response.status) {
          case 401:
            setError('Felaktigt användarnamn eller lösenord.')
            break
          case 429:
            setError('För många inloggningsförsök. Prova igen om en stund.')
            break
          default:
            setError('Kunde inte logga in.')
        }
        setLoading(false)
      })
    }
  }

  return (
    <div className="h-screen-ios-safe flex flex-col">
      <div>
        {/* <img
        className="mx-auto h-32 w-32 rounded-lg my-12 shadow-lg"
        src="/icons/icon-ios-60@3x.png"
        alt="Maxpulse logo"
      /> */}
        <form
          className="rounded bg-white md:shadow md:max-w-sm md:mx-auto md:mt-24 overflow-hidden"
          onSubmit={handleLogin}
        >
          <h1 className="text-lg _text-center leading-relaxed bg-gray-100 border-b p-4">
            Logga in på Maxpulse
          </h1>
          <div className="p-4">
            <label className="block mb-4">
              <span className="text-gray-800 font-semibold">E-postadress</span>
              <input
                type="email"
                className="form-input mt-1 block w-full"
                onChange={e => setEmail(e.target.value)}
                placeholder="john.smith@example.com"
                required
                autoFocus
              />
            </label>
            <label className="block mb-4">
              <span className="text-gray-800 font-semibold">Lösenord</span>
              <input
                type="password"
                className="form-input mt-1 block w-full"
                onChange={e => setPassword(e.target.value)}
                required
              />
            </label>
            {error && <p className="text-red-700 text-sm mb-4">{error}</p>}
            <GreenButton
              className="w-full mt-2"
              type="submit"
              loading={loading}
            >
              Logga in
            </GreenButton>
          </div>
        </form>
      </div>
      <div className="mt-4 mb-4 flex justify-center">
        <Link
          to="/password/reset"
          className="hover:underline text-theme-600 text-sm"
        >
          Glömt lösenordet?
        </Link>
      </div>
      <div className="mt-auto mb-12 flex justify-center">
        <a
          href={process.env.REACT_APP_ROOT_URL}
          className="hover:underline font-medium text-sm flex items-center"
        >
          <CornerUpLeft size={14} />
          <span className="ml-2">Tillbaka till startsidan</span>
        </a>
      </div>
    </div>
  )
}

import create from 'zustand'

const [useDragDropStore] = create((set, get) => {
  let scrollVelocity = 0
  let animationFrame = null
  let previousTimestamp = null

  // Prevent scroll on mobile when dragging.
  document.addEventListener(
    'touchmove',
    e => {
      if (get().draggingInitiated) {
        e.preventDefault()
      }
    },
    { passive: false },
  )

  // Scroll the window at given velocity with regards
  // to the frame rate.
  function scroll(timestamp) {
    if (previousTimestamp) {
      let delta = timestamp - previousTimestamp
      window.scrollBy(0, scrollVelocity * delta)
    }

    previousTimestamp = timestamp
    animationFrame = requestAnimationFrame(scroll)
  }

  const SCROLL_THRESHOLD = 100

  function handleMove(e) {
    let x, y
    if (e.touches) {
      x = e.touches[0].clientX
      y = e.touches[0].clientY
    } else {
      x = e.clientX
      y = e.clientY
    }

    // Scroll the window if needed.
    if (y < SCROLL_THRESHOLD) {
      scrollVelocity = -Math.pow((SCROLL_THRESHOLD - y) / 20, 1.2) / 10
      if (!animationFrame) {
        animationFrame = requestAnimationFrame(scroll)
      }
    } else if (window.innerHeight - y < SCROLL_THRESHOLD) {
      scrollVelocity =
        Math.pow((SCROLL_THRESHOLD - (window.innerHeight - y)) / 20, 1.2) / 10
      if (!animationFrame) {
        animationFrame = requestAnimationFrame(scroll)
      }
    } else {
      cancelAnimationFrame(animationFrame)
      animationFrame = null
      previousTimestamp = null
    }

    // Only start the drag if we have moved at least 2px in either direction.
    // This will prevent accidental dragging when the user is
    // actually trying to click the element.
    if (
      get().dragging ||
      Math.abs(x - get().x) > 1 ||
      Math.abs(y - get().y) > 1
    ) {
      set({ dragging: true, x, y })

      // We'll disable mouse interaction when dragging.
      document.getElementById('root').classList.add('pointer-events-none')
    }
  }

  function handleUp() {
    if (get().dragging) {
      get().dropListeners.forEach(listener => listener())
    }
    // Click events will fire since dragging is false again,
    // unless we delay it for a bit.
    setTimeout(() => {
      reset()
    })
    cancelAnimationFrame(animationFrame)
    if (get().mode === 'touch') {
      document.removeEventListener('touchmove', handleMove)
      document.removeEventListener('touchend', handleUp)
    } else {
      document.removeEventListener('mousemove', handleMove)
      document.removeEventListener('mouseup', handleUp)
    }

    document.getElementById('root').classList.remove('pointer-events-none')
  }

  function reset() {
    set({
      dragging: false,
      draggingInitiated: false,
      ref: null,
      data: null,
      x: null,
      y: null,
      rect: null,
      offsetX: null,
      offsetY: null,
    })
  }

  return {
    dragging: false,
    draggingInitiated: false,
    ref: null,
    data: null,
    x: null,
    y: null,
    rect: null,
    offsetX: null,
    offsetY: null,
    dropListeners: [],
    // Mode can be touch or mouse.
    mode: null,
    beginDrag({ ref, data, x, y, rect, mode }) {
      if (mode === 'touch') {
        document.addEventListener('touchmove', handleMove)
        document.addEventListener('touchend', handleUp)
      } else {
        document.addEventListener('mousemove', handleMove)
        document.addEventListener('mouseup', handleUp)
      }
      let offsetX = x - rect.left
      let offsetY = y - rect.top
      set({
        ref,
        data,
        rect,
        offsetX,
        offsetY,
        x,
        y,
        mode,
        draggingInitiated: true,
        dragging: false,
      })
    },
    isDragging(ref) {
      return get().ref === ref && get().dragging
    },
    addDropListener(listener) {
      set({ dropListeners: get().dropListeners.concat(listener) })

      return () =>
        set({ dropListeners: get().dropListeners.filter(x => x !== listener) })
    },
  }
})

export { useDragDropStore }

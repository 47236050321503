import React from 'react'
import { formatSeconds } from '../Utilities'
import { gql } from '@apollo/client'
import classNames from 'classnames'
import { Heart, Clock, Zap } from 'react-feather'
import { HoverPopover } from './Popover'
import { Draggable } from './DragDrop'
import { HeartRateChart } from './HeartRateChart'

WorkoutRecording.fragments = {
  workout_recording: gql`
    fragment WorkoutRecording_workout_recording on WorkoutRecording {
      id
      date
      duration
      calories
      average_heart_rate
      device
      sport {
        id
        name
        alias
      }
      samples {
        heart_rate(max_points: 150)
      }
    }
  `,
}

export function DraggableWorkoutRecording({ onClick, ...rest }) {
  return (
    <Draggable
      data={{
        type: 'workout_recording',
        workout_recording: rest.workout_recording,
      }}
      render={(props, { dragging }) => (
        <div {...props} style={{ opacity: dragging ? 0.5 : 1 }}>
          <WorkoutRecordingPopover
            popover={
              <WorkoutRecordingSummary
                workout_recording={rest.workout_recording}
              />
            }
          >
            <WorkoutRecording
              {...rest}
              onClick={dragging ? undefined : onClick}
            />
          </WorkoutRecordingPopover>
        </div>
      )}
      preview={props => (
        <div style={{ ...props.style, opacity: 0.7 }}>
          <WorkoutRecording {...rest} dragging={true} />
        </div>
      )}
    />
  )
}

export function WorkoutRecordingPopover({ children, popover }) {
  return (
    <HoverPopover
      placement="bottom-start"
      modifiers={[
        {
          name: 'flip',
          options: {
            fallbackPlacements: [
              'bottom-start',
              'top-start',
              'right-end',
              'left-end',
            ],
          },
        },
        {
          name: 'preventOverflow',
          options: { boundary: 'viewport' },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
      ]}
      trigger={children}
    >
      <div
        style={{ width: 400 }}
        className="bg-white rounded shadow-popover overflow-hidden"
      >
        {popover}
      </div>
    </HoverPopover>
  )
}

export function WorkoutRecording({
  workout_recording,
  onClick,
  dragging = false,
}) {
  let label = workout_recording.sport?.alias

  return (
    <button
      onClick={onClick}
      className={classNames(
        'w-full min-h-8 rounded leading-none flex flex-col items-stretch bg-gray-800 touching:bg-gray-700 text-gray-100 text-sm shadow-workout focus:outline-none',
        {
          'cursor-default': dragging,
        },
      )}
    >
      <div className="flex-1 flex items-center px-2 py-1.5">
        <Heart size={18} className="mr-1" />
        <span className="mr-1 font-semibold antialiased">{label}</span>
        <span className="antialiased">
          {formatSeconds(workout_recording.duration)}
        </span>
      </div>
    </button>
  )
}

export function WorkoutRecordingSummary({ workout_recording }) {
  return (
    <div className="text-sm">
      <p className="py-1.5 px-4 border-b-2 border-theme-600">
        <span className="mr-1 text-base">{workout_recording.date}</span>
        <span className="text-xs">Pulsfil från {workout_recording.device}</span>
      </p>
      <div className="divide-y">
        <div className="flex text-base leading-tight bg-gray-100 border-t border-gray-200 px-4 py-2 text-gray-800">
          <div className="flex flex-1 items-center justify-start">
            <span>{workout_recording.sport?.name ?? 'Ingen sport'} </span>
          </div>
        </div>

        {workout_recording.samples.heart_rate.length ? (
          <div className="p-2">
            <HeartRateChart recording={workout_recording} />
          </div>
        ) : null}

        <p className="py-2 px-4 text-gray-800">
          Klicka på pulsfilen eller dra den till ett träningspass för att
          bokföra den.
        </p>

        <div className="flex text-base leading-tight bg-gray-100 border-t border-gray-200 px-4 py-2 text-gray-800">
          <div className="flex flex-1 items-center justify-start">
            <Clock size={16} className="mr-2" />
            <span>{formatSeconds(workout_recording.duration)}</span>
          </div>
          <div className="flex flex-1 items-center justify-center">
            <Heart size={16} className="mr-2" />
            <span>{workout_recording.average_heart_rate ?? '-'}</span>
          </div>
          <div className="flex flex-1 items-center justify-end">
            <Zap size={16} className="mr-2" />
            <span>{workout_recording.calories ?? '-'}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from '../Components/Modal'
import { X } from 'react-feather'
import TextareaAutosize from 'react-textarea-autosize'
import { Select } from '../Components/Form'
import { useQuery, gql, useMutation } from '@apollo/client'
import { GrayButton, PrimaryButton, MobileButton } from '../Components/Buttons'
import { GroupPage } from './GroupPage'
import { translateRole } from '../Utilities'

export function InviteUsersPage({ navigate, location, id }) {
  let [role, setRole] = useState()
  let [emails, setEmails] = useState('')

  let { data } = useQuery(
    gql`
      query GroupQuery($id: ID!) {
        group(id: $id) {
          id
          name
        }
      }
    `,
    { variables: { id } },
  )

  let [
    inviteUsers,
    { loading: inviteUsersLoading, data: inviteUsersData },
  ] = useMutation(
    gql`
      mutation InviteUsers($input: InviteUsersToGroupInput!) {
        inviteUsersToGroup(input: $input) {
          group {
            id
            ...GroupPage_invites
          }
          errors {
            key
            message
          }
        }
      }
      ${GroupPage.fragments.invites}
    `,
  )

  function back() {
    navigate('../')
  }

  function save() {
    inviteUsers({
      variables: {
        input: {
          group_id: id,
          roles: [role],
          emails: emails
            .split(',')
            .map(x => x.trim())
            .filter(x => x),
        },
      },
    }).then(({ data }) => {
      if (data.inviteUsersToGroup.errors) return

      back()
    })
  }

  let disabled = inviteUsersLoading
  let preventSave = !role || !emails || disabled

  return (
    <Modal width="md:max-w-lg">
      <ModalHeader
        title="Bjud in användare"
        left={
          <button
            disabled={disabled}
            onClick={back}
            className="flex md:hidden items-center touching:opacity-25"
          >
            Avbryt
          </button>
        }
        right={
          <>
            <button
              disabled={disabled}
              onClick={back}
              className="ml-auto hidden md:flex items-center hover:bg-gray-200 active:bg-gray-300 p-2 rounded-full"
            >
              <X />
            </button>
            <MobileButton
              disabled={preventSave}
              loading={inviteUsersLoading}
              onClick={save}
              className="flex md:hidden items-center touching:opacity-25"
            >
              Bjud in
            </MobileButton>
          </>
        }
      />
      <ModalBody>
        <div className="p-4">
          {inviteUsersData?.inviteUsersToGroup.errors ? (
            <div className="p-2 mb-4 rounded bg-red-200 border border-red-300 text-sm">
              {inviteUsersData.inviteUsersToGroup.errors.map((x, i) => (
                <p key={i}>{x.message}</p>
              ))}
            </div>
          ) : null}
          <p className="mb-4 -text-gray-800 text-sm flex -bg-gray-200">
            Bjud in användare till {data?.group.name}. Varje användare får en
            inbjudningslänk via e-post och måste tacka ja för att bli medlem i
            träningsgruppen.
          </p>
          <div className="mb-4">
            <label>
              <span className="block mb-1">
                Skicka inbjudan till dessa e-postadresser
              </span>
              <TextareaAutosize
                className="form-textarea block w-full bg-gray-100 text-sm"
                placeholder="john.doe@example.com,jane.doe@example.com"
                minRows={5}
                value={emails}
                onChange={e => setEmails(e.target.value)}
              />
            </label>
            <p className="mt-2 text-gray-800 text-sm">
              Separera e-postadresserna med ett kommatecken.
            </p>
          </div>
          <div>
            <label>
              <span className="block mb-1">Bjud in som</span>
              <Select
                label="Välj roll"
                items={['athlete', 'coach']}
                value={role}
                getLabel={x => translateRole(x)}
                getValue={x => x}
                onChange={x => setRole(x)}
              />
            </label>
            <p className="mt-2 text-gray-800 text-sm">
              En tränare kan planera träning i träningsprogram och se träningen
              för alla aktiva i gruppen. En aktiv kan ta del av träningsprogram
              men kan inte skapa träningsprogram eller se någon annans träning.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <GrayButton disabled={disabled} onClick={back} className="ml-auto">
          Avbryt
        </GrayButton>
        {/* <button
          disabled={disabled}
          onClick={back}
          className="ml-auto bg-gray-200 rounded px-3 py-2 hover:bg-gray-300 active:bg-gray-400 disabled:bg-gray-100"
        >
          Avbryt
        </button> */}
        <PrimaryButton
          loading={inviteUsersLoading}
          disabled={preventSave}
          onClick={save}
          className="ml-4"
        >
          Bjud in
        </PrimaryButton>
      </ModalFooter>
    </Modal>
  )
}
